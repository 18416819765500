import React from 'react';
import cn from "classnames";
import st from "./Modal.module.css";

const Modal = ({ children, isOpened, close }) => {
    return (
        <div className={cn(st.generateModalContainerClosed, {[st.generateModalContainerOpened]: isOpened})}>
            <div className={cn(st.generateModalShadowClosed)} onClick={() => close(false)}></div>
            <div className={st.generateModal}>
                {children}
            </div>
        </div>

    );
};

export default Modal;