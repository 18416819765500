import React, {useEffect, useState} from 'react';
import cn from "classnames";
import st from "./ExportModal.module.css";
import Modal from "../Modal";
import {ExportTypes} from "../../../service/enum/ExportTypes";
import Generate from "../../../resources/Generate";
import {generateSQLFile} from "../../../service/utils/Export/PostgreSQL/ExportConverter";
import {generateJSONFile} from "../../../service/utils/Export/Json/ExportConverter";
import {useSelector} from "react-redux";
import {Translations} from "../../../translations/Translations";
import {exportToPNG} from "../../../service/utils/Export/Png/ExportPng";
import {exportToPDF} from "../../../service/utils/Export/Pdf/ExportPdf";
import {useSettings} from "../../../service/utils/hooks/useSettings";

const ExportModal = ({ tables, relations, projectId, generateModalIsOpened, setGenerateModalIsOpened, fieldErrorById }) => {
    let [tableDontGenerateById, setTableDontGenerateById] = useState({})
    const [generateTypes, setGenerateTypes] = useState({})
    const [isLoading, setIsLoading] = useState();

    const {t} = useSettings();
    
    const checkErrors = () => {
        for (const tableId of Object.keys(tables)) {
            if (fieldErrorById[tableId]?.tableError) {
                tableDontGenerateById[tableId] = fieldErrorById[tableId]?.tableError
            } else {
                for (const fieldId of Object.keys(tables[tableId].fields)) {
                    if (fieldErrorById[fieldId]?.fieldError) {
                        tableDontGenerateById[tableId] = fieldErrorById[fieldId]?.fieldError
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (generateModalIsOpened === true) {
            tableDontGenerateById = {}
            checkErrors();
            setTableDontGenerateById({...tableDontGenerateById})
            for (const key in ExportTypes) {
                generateTypes[key] = false;
            }
        }
    }, [generateModalIsOpened]);

    const switchTablesToNotGenerate = (tableId) => {
        if (tableDontGenerateById[tableId] && !hasErrorTable(tableId)) {
            delete tableDontGenerateById[tableId];
        } else if (!tableDontGenerateById[tableId]) {
            tableDontGenerateById[tableId] = true;
        }
        setTableDontGenerateById({...tableDontGenerateById})
    }

    const switchAllTablesDontGenerate = () => {
        if (Object.keys(tableDontGenerateById).length > 0) {
            tableDontGenerateById = {}
            checkErrors();
        } else {
            Object.keys(tables).forEach(tableId => {
                tableDontGenerateById[tableId] = true
            })
        }
        setTableDontGenerateById({...tableDontGenerateById})
    }

    const generateDiagrams = () => {
        if (!checkExportAvailable()) return
        setGenerateModalIsOpened(false)
        if (generateTypes["PostgreSQL"]) {
            generateSQLFile(t, tables, relations, "TestProject", tableDontGenerateById) // TODO передавать имя проекта
        }
        if (generateTypes["JSON"]) {
            generateJSONFile(projectId, t)
        }
    }

    const hasErrorTable = (tableId) => {
        return tableDontGenerateById[tableId] && tableDontGenerateById[tableId] !== true ? tableDontGenerateById[tableId] : false
    }
    
    const checkExportAvailable = () => {
        const onlyJson = generateTypes[ExportTypes.JSON] && !Object.keys(generateTypes).some(key => generateTypes[key] === true && key !== ExportTypes.JSON)
        
        return onlyJson || (Object.keys(tableDontGenerateById).length !== Object.keys(tables).length && Object.values(generateTypes).some(value => value === true))
    }
    
    return (
        <Modal isOpened={generateModalIsOpened} close={setGenerateModalIsOpened}>
            <div className={st.exportTitleBlock}>
                <span>{t?.exportTitle}</span>
            </div>
            <div className={st.modalBody}>
                <div className={st.actionBlock}>
                    <div className={st.actionBlockTitleBlock}>
                        <span>{t?.tables}</span>
                        <div
                            className={cn(st.selectButton, {[st.selectButtonOn]: Object.keys(tableDontGenerateById).length === 0})} onClick={switchAllTablesDontGenerate}>
                            <div className={st.selectButtonInner}></div>
                        </div>
                    </div>
                    <div className={st.itemsBlock}>

                        {Object.keys(tables).map((tableId, index) => (
                            <div key={index} className={st.tableItemBlock}>
                                <div className={st.tableInfoBlock}>
                                    <div className={st.colorMarker} style={{backgroundColor: `${tables[tableId].color}`}}></div>
                                    <span>{tables[tableId].name}</span>
                                </div>
                                <div
                                    className={cn(st.selectButton, {[st.selectButtonOn]: !tableDontGenerateById[tableId]}, {[st.selectButtonError]: hasErrorTable(tableId)})}
                                    onClick={() => switchTablesToNotGenerate(tableId)}>
                                    <div className={st.selectButtonInner}></div>
                                    {hasErrorTable(tableId) &&
                                        <span className={cn(st.tooltipText, {[st.tooltipTextDown]: index === 0})}>{hasErrorTable(tableId)}</span>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={st.divider} style={{height: "calc(100% - 40px)"}}></div>

                <div className={st.actionBlock}>
                    <span>{t?.exportTypes}</span>
                    <div className={st.itemsBlockRight}>
                        {Object.entries(ExportTypes).map(([key, value], index) => (
                            <div key={index}
                                 className={cn(st.typeItemBlock, {[st.exportTypeBlockOn]: generateTypes[key]})}
                                 onClick={() => setGenerateTypes({...generateTypes, [key]: !generateTypes[key]})}>
                                <div className={st.exportTypeBlock}>
                                    <span>{value}</span>
                                    <img src={require(`../../../resources/image/${value.toLowerCase()}.png`)}
                                         className={st.exportTypeImg}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={st.pdfNPngExportButtons}>
                        <div className={st.exportButton} onClick={() => exportToPNG(setIsLoading)}>
                            {isLoading !== "png" ?
                                (<img src={require(`../../../resources/image/png.png`)}
                                      className={st.exportTypeImg2}/>)
                                :
                                (
                                    <div className={st.spinnerContainer}>
                                        <div className={st.spinner}></div>
                                    </div>
                                )
                            }
                        </div>
                        <div className={st.exportButton} onClick={() => exportToPDF(setIsLoading)}>
                            {isLoading !== "pdf" ?
                                (<img src={require(`../../../resources/image/pdf.png`)}
                                     className={st.exportTypeImg2}/>)
                                :
                                (
                                    <div className={st.spinnerContainer}>
                                        <div className={st.spinner}></div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={st.modalFooter}>
            <div className={st.bigButton} onClick={() => setGenerateModalIsOpened(false)}>
                    <span>{t?.cancel}</span>
                </div>
                <div className={cn(st.bigButtonDisabled, {[st.bigButton]: checkExportAvailable()})} onClick={() => generateDiagrams()}>
                    <span>{t?.generate}</span>
                    <Generate/>
                </div>
            </div>
        </Modal>


    );
};

export default ExportModal;