import './App.css';
import {Redirect, Route, Switch} from "react-router-dom";
import {Editor} from "./pages/Editor/Editor";
import {Auth} from "./pages/Auth";
import {Register} from "./pages/Register/Register";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import VersionText from "./components/VersionText/VersionText";
import {Projects} from "./pages/Projects/Projects";
import {SettingsProvider} from "./service/utils/hooks/useSettings";

function App() {
  return (
    <div className="App">
      <SettingsProvider>
          <Switch>
            <Route path="/editor/:projectId"><Editor/></Route>
            <Route path="/projects"><Projects/></Route>
            <Redirect to="/projects"/>
          </Switch>
            <VersionText/>
        <NotificationContainer/>
      </SettingsProvider>
    </div>
  );
}

export default App;
