import React from 'react';

const Save = () => {
    return (
        <svg width={22} xmlns="http://www.w3.org/2000/svg" viewBox="1 0.9999832510948181 30.000017166137695 30.000017166137695">
                <path
                    d="m30.71 7.29-6-6A1 1 0 0 0 24 1H4a3 3 0 0 0-3 3v24a3 3 0 0 0 3 3h24a3 3 0 0 0 3-3V8a1 1 0 0 0-.29-.71ZM20 3v6h-8V3ZM8 29v-7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v7Zm21-1a1 1 0 0 1-1 1h-2v-7a3 3 0 0 0-3-3H9a3 3 0 0 0-3 3v7H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h6v6a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V3h1.59L29 8.41Z"
                    fill={'var(--icon-blue-color)'} opacity="1" data-original="#000000"></path>
        </svg>

    );
};

export default Save;