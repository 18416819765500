import React from 'react';

const Palette = ({selected}) => {
    return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24"/>
        <path
            d="M6.279 10.1511C5.08075 10.1511 4.10593 11.1177 4.10593 12.3058C4.10593 13.4939 5.08075 14.4605 6.279 14.4605C7.47725 14.4605 8.45208 13.4939 8.45208 12.3058C8.45208 11.1177 7.47725 10.1511 6.279 10.1511ZM6.279 13.2891C5.73224 13.2891 5.28736 12.848 5.28736 12.3058C5.28736 11.7636 5.73224 11.3225 6.279 11.3225C6.82577 11.3225 7.27065 11.7636 7.27065 12.3058C7.27065 12.848 6.82577 13.2891 6.279 13.2891ZM10.1272 15.2403C8.92894 15.2403 7.95411 16.2069 7.95411 17.395C7.95411 18.5831 8.92894 19.5497 10.1272 19.5497C11.3254 19.5497 12.3002 18.5831 12.3002 17.395C12.3002 16.2069 11.3254 15.2403 10.1272 15.2403ZM10.1272 18.3782C9.58039 18.3782 9.13555 17.9371 9.13555 17.395C9.13555 16.8528 9.58039 16.4117 10.1272 16.4117C10.674 16.4117 11.1188 16.8528 11.1188 17.395C11.1188 17.9371 10.674 18.3782 10.1272 18.3782Z"
            fill={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}/>
        <path
            d="M21.5414 15.2216C21.5376 15.2178 21.5336 15.214 21.5297 15.2103L19.4842 13.2963C20.6292 12.4378 21.1465 10.9182 20.6761 9.51247C18.4584 2.87832 9.9367 0.773609 4.859 5.73238C1.05606 9.44624 1.09155 15.4345 4.69973 19.1221C7.94121 22.4348 13.0488 22.9001 16.8185 20.4533C18.5839 19.3078 17.7723 16.5813 15.6598 16.5813C14.0821 16.5813 13.817 14.394 15.3073 14.0194L18.911 17.8064C18.9148 17.8103 18.9186 17.8142 18.9225 17.8181C19.6449 18.5361 20.8184 18.538 21.5427 17.8197C22.2669 17.1017 22.2669 15.9393 21.5414 15.2216ZM13.9088 17.0611C14.3834 17.5071 15.0053 17.7527 15.6599 17.7527C16.6033 17.7527 16.9591 18.9622 16.1716 19.4731C12.8722 21.6147 8.39113 21.2124 5.54766 18.3064C2.35844 15.047 2.37723 9.81633 5.7011 6.57444C10.1551 2.2303 17.6048 4.04775 19.5547 9.88119C19.8851 10.8685 19.4722 11.9414 18.6062 12.4749L15.325 9.40477C15.5237 8.45902 15.2485 7.462 14.5277 6.74725C13.6229 5.85008 10.7726 5.40291 9.53731 5.26986C9.16265 5.2294 8.84542 5.54345 8.88618 5.91549C9.02146 7.1451 9.46981 9.96496 10.3762 10.8637C11.0975 11.5789 12.1036 11.8512 13.0566 11.6542L14.4387 13.1066C12.967 13.8989 12.6681 15.8953 13.9088 17.0611ZM11.2116 10.0354C10.7664 9.59397 10.3687 7.91519 10.1587 6.53157C11.5541 6.73975 13.2472 7.13417 13.6923 7.57556C14.3777 8.25521 14.3779 9.35552 13.6923 10.0354C13.0084 10.7136 11.8985 10.7165 11.2116 10.0354ZM20.7074 16.9914C20.447 17.2495 20.0257 17.2507 19.7639 16.9956L14.1976 11.1464C14.4316 10.9738 14.6389 10.7683 14.8129 10.5363L20.712 16.0559C20.9695 16.3158 20.9681 16.7329 20.7074 16.9914Z" fill={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}/>
    </svg>
)
    ;
};

export default Palette;