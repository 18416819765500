import {createNotification, NotificationStatus} from "../../../Notification";
import {formatString} from "../../utils";

export const importJSONFile = (t, file, callback) => {
    const reader = new FileReader();
    reader.onload = function(e) {
        try {
            const content = JSON.parse(e.target.result
                .replaceAll("primary key", "primary_key")
                .replaceAll("not null", "not_null")
                .replaceAll(" ", "")
                .replaceAll("primary_key", "primary key")
                .replaceAll("not_null", "not null")
                .replaceAll("\n", ""));
            const tables = content?.tables ? content?.tables : {};
            if (!content?.tables) {
                createNotification(NotificationStatus.ERROR, formatString(t?.errors.attributeNotFound, "tables"), t?.errors.import)
                return
            }
            const areas = content?.areas ? content?.areas : {};
            const relations = content?.relations ? content?.relations : {};
            if (!content?.relations) {
                createNotification(NotificationStatus.ERROR, formatString(t?.errors.attributeNotFound, "relations"), t?.errors.import)
                return
            }
            createNotification(NotificationStatus.SUCCESS, t?.success.import)
            return callback({ tables, relations, areas, name: content?.name });

        } catch (e) {
            console.error(e)
            createNotification(NotificationStatus.ERROR, "", t?.errors.import)
        }
    };
    if (file) {
        reader.readAsText(file);
    }
};