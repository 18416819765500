import React, {useEffect, useState} from 'react';
import st from "./Projects.module.css"
import {Link} from "react-router-dom";
import cn from "classnames";
import CreateProjectModal from "../../components/Modals/CreateProjectModal/CreateProjectModal";
import Settings from "../../resources/Settings";
import Burger from "../../resources/Burger";
import SettingsModal from "../../components/Modals/SettingsModal/SettingsModal";
import {useSettings} from "../../service/utils/hooks/useSettings";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import Options from "../../resources/Options";


export const Projects = () => {

    const [projects, setProjects] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState("my");
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
    const [settingsModalIsOpened, setSettingsModalIsOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const reactHistory = useHistory();
    
    const {t} = useSettings()

    useEffect(() => {
        setIsLoading(true)
        setProjects([])
        setTimeout(() => {
            setProjects([
                {
                    id: 1,
                    name: "Project 1",
                    preview: "database-schema.png",
                    author: "Alastorial",
                    lastUpdated: "1 hour ago"
                },
                {
                    id: 2,
                    name: "Project 2",
                    author: "Alastorial",
                    preview: "database-schema2.png",
                    lastUpdated: "2 min ago"
                },
                // {
                //     id: 3,
                //     name: "Project 3",
                //     preview: "database-schema2.png"
                // },
                // {
                //     id: 4,
                //     name: "Project 4",
                //     preview: "database-schema.png"
                // },
                // {
                //     id: 5,
                //     name: "Project 5",
                //     preview: "database-schema.png"
                // },
            ])
            setIsLoading(false)
        }, 3000)
    }, [selectedGroup]);
    
    const openProjectOptions = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    
    return (
        <div className={st.bg}>
            <div className={st.container}>
                <div className={st.header}>
                    <div className={st.buttonsBlocks}>
                        <div className={st.homeButton} onClick={() => reactHistory.push('/projects')}>
                            <span>DBDS</span>
                        </div>
                    </div>
                    <div className={st.buttonsBlocks}>
                        <div className={st.buttonsBlock}>
                            <div className={st.button} onClick={() => setSettingsModalIsOpened(true)}>
                                <Settings/>
                            </div>
                        </div>
                        <div className={st.divider}></div>
                        <div className={st.buttonsBlock}>
                            <img className={st.userPhoto} src={require("../../resources/image/lise.jpg")}
                                 alt={"Avatar"}/>
                            <div className={st.button}>
                                <Burger/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={st.content}>
                    <div className={st.navigationBlock}>
                        <div className={cn(st.navigationGroup, {[st.navigationGroupSelected]: selectedGroup === "my"})}
                             onClick={() => setSelectedGroup("my")}>
                            <span>{t?.myProjects}</span>
                        </div>
                        <div
                            className={cn(st.navigationGroup, {[st.navigationGroupSelected]: selectedGroup === "shared"})}
                            onClick={() => setSelectedGroup("shared")}>
                            <span>{t?.sharedMe}</span>
                        </div>
                    </div>
                    <div className={st.projectsBlock}>
                        <div className={st.projectsHeader}>
                            <span>Projects</span>
                            <button className={st.blueButton} onClick={() => setIsOpenCreateModal(true)}>Create project</button>
                        </div>
                        {!isLoading ?
                            <div className={st.projects}>
                                {projects.map((project, index) => (
                                    <Link to={`/editor/${project.id}`} key={project.id}>
                                        <div key={project.id} className={st.project}
                                             style={{animationDelay: `${index * 0.2}s`}}>
                                            <img className={st.projectImg} alt={project.name} src={project.preview}/>
                                            <div className={st.projectInfoBlock}>

                                                <div className={st.projectInfo} onClick={openProjectOptions}>
                                                    <span>{project.name}</span>
                                                    <div className={st.tableOptionButton}><Options/></div>
                                                </div>
                                                <div className={st.projectInfo2}>
                                                    <span>{project.lastUpdated}</span>
                                                    <span>by {project.author}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            :
                            <div className={st.loaderBg}>
                            <div className={st.loader}>
                                    <div className={cn(st.inner, st.one)}></div>
                                    <div className={cn(st.inner, st.two)}></div>
                                    <div className={cn(st.inner, st.three)}></div>
                                </div>
                                <span>Loading...</span>
                            </div>
                        }


                    </div>
                </div>
            </div>
            <SettingsModal settingsModalIsOpened={settingsModalIsOpened} setSettingsModalIsOpened={setSettingsModalIsOpened} generalMode={true}/>
            <CreateProjectModal isOpenCreateModal={isOpenCreateModal} setIsOpenCreateModal={setIsOpenCreateModal}/>
        </div>
    );
};

