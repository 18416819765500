import React from 'react';
import st from "../pages/Editor/Editor.module.css";

const Comment = ({width = 24, height = 24, bgColor}) => {
    let color = "var(--icon-color)"
    
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={st.commentBlock}>
            <rect width={width + 8} height={width + 8} fill={bgColor}/>
            <path
                d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z"
                stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className={st.commentIcon}/>
            <path d="M7 7H17" stroke={color} strokeWidth="1.5" strokeLinecap="round" className={st.commentIcon}/>
            <path d="M7 10H17" stroke={color} strokeWidth="1.5" strokeLinecap="round" className={st.commentIcon}/>
            <path d="M7 13H17" stroke={color} strokeWidth="1.5" strokeLinecap="round" className={st.commentIcon}/>
        </svg>

    );
};

export default Comment;