import WorkBench from "../../components/WorkBench/WorkBench";
import st from "./Editor.module.css";
import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as Point} from "../../resources/icons/point3.svg";
import TableTopBorder from "../../components/TableParts/TableTopBorder";
import TableField from "../../components/TableParts/TableField";
import TableBottomBorder from "../../components/TableParts/TableBottomBorder";
import TableHeader from "../../components/TableParts/TableHeader";
import TableTopBorderSelect from "../../components/TableParts/TableTopBorderSelect";
import TableBorderSelect from "../../components/TableParts/TableBorderSelect";
import TableBottomBorderSelect from "../../components/TableParts/TableBottomBorderSelect";
import { v4 as uuidv4 } from 'uuid';
import TableBorderSelectWithPoint from "../../components/TableParts/TableBorderSelectWithPoint";
import DevelopmentConsole from "../../components/DevelopmentConsole/DevelopmentConsole";
import RelationTypeButton from "../../resources/svgButtons/RelationTypeButton";
import {FieldTypes} from "../../service/enum/FielsTypes";
import {RelationTypes} from "../../service/enum/RelationTypes";
import {SaveMode} from "../../service/enum/SaveMode";
import {createNotification, NotificationStatus} from "../../service/Notification";
import {Arrow, CreationArrow} from "./editorComponents/Arrow";
import {Positions} from "../../service/enum/Positions";
import {RelationError} from "../../service/enum/RelationError";
import {useDispatch, useSelector} from "react-redux";
import {
    EDITOR_AREAS_LOGS_ENABLED,
    EDITOR_PROJECT_SETTINGS_LOGS_ENABLED,
    EDITOR_RELATIONS_LOGS_ENABLED,
    EDITOR_TABLES_LOGS_ENABLED,
    MAX_AMOUNT_OF_TABLES,
    TABLE_WIDTH
} from "../../service/consts";
import {
    updateAreasRedux,
    updateRelationsRedux,
    updateTablesRedux
} from "../../redux/actions/appActions";
import {useVersionHistory} from "../../service/utils/hooks/History";
import {
    getOtherTableAndFieldFromRelation,
    getRandomColor,
    checkValidPostgresIdentifier,
    roundToStep, checkUniqAndValidPostgresIdentifier, formatString
} from "../../service/utils/utils";
import cn from "classnames";
import {Constraints} from "../../service/enum/Constraints";
import HeaderLeft from "../../components/HeaderLeft/HeaderLeft";
import HeaderRight from "../../components/HeaderRight/HeaderRight";
import {Translations} from "../../translations/Translations";
import {WorkBenchMode} from "../../service/enum/WorkBenchMode";
import {useHistory, useParams} from "react-router-dom/cjs/react-router-dom";
import Area from "../../components/Area/Area";
import {useSettings} from "../../service/utils/hooks/useSettings";

export const Editor = () => {

    const dispatch = useDispatch();
    const [isInitialRender, setIsInitialRender] = useState(true);
    const [lastMouseMoveTime, setLastMouseMoveTime] = useState(0);

    const [fieldErrorById, setFieldErrorById] = useState({})
    
    const step = 10;
    const [isInitialUpdate, setIsInitialUpdate] = useState(true)
    const [openedTableIds, setOpenedTableIds] = useState(new Set());
    
    let {history, setHistory, historyIndex, saveToHistory, manageHistoryByMode, updateHistory} = useVersionHistory();
    const [isLoading, setIsLoading] = useState(true)

    const [tableToCopy, setTableToCopy] = useState(null)

    const [tables, setTables] = useState({})
    const tablesRedux = useSelector((state) => state.tablesRedux);
    const [projectInfo, setProjectInfo] = useState({name: "TestProject"})
    
    const [relations, setRelations] = useState({})
    const relationsRedux = useSelector((state) => state.relationsRedux);

    const {settings, t} = useSettings();

    const [areas, setAreas] = useState({})
    const areasRedux = useSelector((state) => state.areasRedux);
    
    const checkStateChanges = (name, logMethod, stateValue, reduxValue, setStateMethod) => {
        logMethod(`[${name.toUpperCase()}_REDUX] Обновление ${name} из redux`)
        if (JSON.stringify(stateValue) !== JSON.stringify(reduxValue)) {
            logMethod("Обновление tables из вне")
            setStateMethod({...JSON.parse(JSON.stringify(reduxValue))})
        } else {
            logMethod("Данные идентичны")
        }
    }

    const checkReduxChanges = (name, logMethod, stateValue, reduxValue, reduxAction) => {
        logMethod(`[${name.toUpperCase()}] Обновление ${name}Redux в redux`)
        if (JSON.stringify(stateValue) !== JSON.stringify(reduxValue)) {
            logTables("Обновление tablesRedux")
            dispatch(reduxAction(stateValue))
        } else {
            logTables("Данные идентичны")
        }
    }
    
    
    useEffect(() => {
        checkStateChanges("tables", logTables, tables, tablesRedux, setTables)
    }, [tablesRedux]);

    useEffect(() => {
        if (!isDraggingTable) { // оптимизация, не обновлять redux при перетаскивании таблиц
            checkReduxChanges("tables", logTables, tables, tablesRedux, updateTablesRedux)
        }
    }, [tables]);
    
    useEffect(() => {
        checkStateChanges("relations", logRelations, relations, relationsRedux, setRelations)
    }, [relationsRedux]);

    useEffect(() => {
        checkReduxChanges("relations", logRelations, relations, relationsRedux, updateRelationsRedux)
    }, [relations]);
    
    useEffect(() => {
        checkStateChanges("areas", logAreas, areas, areasRedux, setAreas)
    }, [areasRedux]);

    useEffect(() => {
        checkReduxChanges("areas", logAreas, areas, areasRedux, updateAreasRedux)
    }, [areas]);
    
    
    const logTables = (message) => {
        if (EDITOR_TABLES_LOGS_ENABLED) {
            console.log("[EDITOR] " + message)
        }
    }

    const logRelations = (message) => {
        if (EDITOR_RELATIONS_LOGS_ENABLED) {
            console.log("[EDITOR] " + message)
        }
    }

    const logAreas = (message) => {
        if (EDITOR_AREAS_LOGS_ENABLED) {
            console.log("[EDITOR] " + message)
        }
    }
    
    const checkNamingErrors = () => {
        for (let tableId in tables) {
            fieldErrorById[tableId] = {tableError: checkUniqAndValidPostgresIdentifier(tables, tableId, t)};
            for (let fieldId in tables[tableId].fields) {
                fieldErrorById[fieldId] = {tableId: tableId, fieldError: checkValidPostgresIdentifier(tables[tableId].fields[fieldId], t)};
            }
        }
        setFieldErrorById({...fieldErrorById})
    }

    useEffect(() => {
        checkNamingErrors()
    }, [tables, settings])

    const [scale, setScale] = useState(1);
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 }); // позиция курсора относительно масштаба (чем больше масштаб по модулю, там больше позиция курсора (по сути, смещение по масштабу))
    const [isOpenedWorkBenchMenu, setIsOpenedWorkBenchMenu] = useState(true);
    const [selectedTableId, setSelectedTableId] = useState(null);
    const [selectedAreaId, setSelectedAreaId] = useState(null);
    const [selectedTablesId, setSelectedTablesId] = useState([]);
    const [selectedTableDelta, setSelectedTableDelta] = useState({});
    const [selectedField, setSelectedField] = useState(null);
    const [selectedRelationId, setSelectedRelationId] = useState(null);
    const [isDraggingTable, setIsDraggingTable] = useState(false);
    const [isResizingArea, setIsResizingArea] = useState(null);
    const [isDraggingArea, setIsDraggingArea] = useState(false);
    const [tableMoved, setTableMoved] = useState(false);
    const [areaMoved, setAreaMoved] = useState(false);
    const [areaResized, setAreaResized] = useState(false);
    const [canvas, setCanvas] = useState({
        isDragging: false,
        initialMouseCoords: { x: 0, y: 0 },
        movingMouseCoords: { x: 0, y: 0 },
        deltaX: 0,
        deltaY: 0
    });
    
    const [selectingArea, setSelectingArea] = useState({
        isSelecting: false,
        startMouseCoords: { x: 0, y: 0 },
        endMouseCoords: { x: 0, y: 0 }
    })
    

    // координаты мыши для вычисления позиции кончика стрелки при создании
    const [mouseCoords, setMouseCoords] = useState();
    
    const resizeCanvas = (tables, areas) => {
        setScale(1)
        setCursorPosition({ x: 0, y: 0 })
        setSelectedTableId(null)
        setSelectedRelationId(null)
        let canvasInitialPosition = {
            minX: null,
            minY: null
        }
        if (Object.keys(tables).length) {
            for (let tableId in tables) {
                if (canvasInitialPosition.minX === null) {
                    canvasInitialPosition.minX = tables[tableId].x
                } else if (tables[tableId].x < canvasInitialPosition.minX) {
                    canvasInitialPosition.minX = tables[tableId].x;
                }
                if (canvasInitialPosition.minY === null) {
                    canvasInitialPosition.minY = tables[tableId].y
                } else if (tables[tableId].y < canvasInitialPosition.minY) {
                    canvasInitialPosition.minY = tables[tableId].y;
                }
            }
        } else if (Object.keys(areas).length) {
            for (let areaId in areas) {
                if (canvasInitialPosition.minX === null) {
                    canvasInitialPosition.minX = areas[areaId].x1
                } else if (areas[areaId].x1 < canvasInitialPosition.minX) {
                    canvasInitialPosition.minX = areas[areaId].x1;
                }
                if (canvasInitialPosition.minY === null) {
                    canvasInitialPosition.minY = areas[areaId].y1
                } else if (areas[areaId].y1 < canvasInitialPosition.minY) {
                    canvasInitialPosition.minY = areas[areaId].y1;
                }
            }
        }
        setCanvas({...canvas, deltaX: -canvasInitialPosition.minX + 500, deltaY: -canvasInitialPosition.minY + 100})
    }

    const { projectId } = useParams();

    const reactHistory = useHistory();
    // START
    useEffect(() => {
        setProjectInfo("")
        setIsLoading(true)
        setTables({})
        setRelations({})
        setAreas({})
        // Будет заменено на вызов из бэка и сохранение в LocalStorage
        setTimeout(() => {
            let project = localStorage.getItem('project_' + projectId);
            let tables = {};
            let relations = {};
            let areas = {};
            if (project) {
                try {
                    project = JSON.parse(project);
                } catch (e) {
                    console.error(e)
                    createNotification(NotificationStatus.ERROR, "", "Error uploading data from localStorage")
                }
                setProjectInfo({
                    name: project.name ?? ""
                })
                if (project.tables) {
                    tables = project.tables
                    if (project.relations) {
                        relations = project.relations
                        setRelations(relations)
                    }
                    setTables(tables)
                }
                if (project.areas) {
                    areas = project.areas
                    setAreas(project.areas)
                }
                resizeCanvas(tables, areas)
                history.push(JSON.stringify({tables, relations, areas}))
                setHistory([...history])

                // Код сбрасывает FK (ушли от такого constraint)
                Object.entries(tables).forEach(([key, value]) => {
                    if (value.fields) {
                        Object.entries(value.fields).forEach(([key2, value2]) => {
                            tables[key].fields[key2] = {...value2, constraints: value2.constraints.filter(c => c !== Constraints.FOREIGN_KEY)}
                        });
                    }
                });
            } else { // если в localStorage нет проекта TODO удалить, когда данные будут начитываться с бэка
                if (projectId === "1") { // удалить, когда будет подключен бэк
                    // let settings = {theme: "light", language: "en"}
                    // document.documentElement.setAttribute('data-theme', settings.theme) // Если тема не указана, ставим по умолчанию светлую
                    // setProjectSettings(settings)
                    history.push(JSON.stringify({tables: {}, relations: {}, areas: {}}))
                    setHistory([...history])
                } else {
                    createNotification(NotificationStatus.ERROR, "", "Project not found")
                    reactHistory.push('/projects')
                }
            }
            setIsLoading(false)
            setTimeout(() => {
                setIsInitialUpdate(false)
            }, 1000) 
        }, 3000)
    }, [])
    
    const createObject = (data) => {
        if (workBenchMode === WorkBenchMode.TABLES) {
            createTable(440,70, data)
        } else if (workBenchMode === WorkBenchMode.AREAS) {
            createNewArea(440,70)
        }
    }

    const createTable = (workBenchX, workBenchY, data = null) => {
        if (Object.keys(tables).length === MAX_AMOUNT_OF_TABLES) {
            createNotification(NotificationStatus.WARNING, t?.errors.maxAmountOfTables, "")
            return
        }
        if (data) {
            createTableOnSample(workBenchX, workBenchY, data);
        } else {
            createNewTable(workBenchX, workBenchY);
        }
    }
    
    const createNewTable = (workBenchX, workBenchY) => {
        const tableId = uuidv4();
        tables[`${tableId}`] = {
            x: (workBenchX - canvas.deltaX - cursorPosition.x) / scale,
            y: (workBenchY - canvas.deltaY - cursorPosition.y) / scale,
            name: "new_table",
            color: getRandomColor(Object.keys(tables).length),
        }
        let fields = {}
        fields[uuidv4()] = {
            name: "id",
            type: FieldTypes.BIGINT,
            relations: [],
            constraints: [Constraints.PRIMARY_KEY],
            fkRelations: []
        }
        tables[`${tableId}`].fields = fields
        updateTables(tables, "createNewTable");
        setSelectedTableId(tableId);
    }
    
    const createNewArea = (workBenchX, workBenchY) => {
        const areaId = uuidv4();
        areas[`${areaId}`] = {
            x1: (workBenchX - canvas.deltaX - cursorPosition.x) / scale,
            y1: (workBenchY - canvas.deltaY - cursorPosition.y) / scale,
            x2: (workBenchX - canvas.deltaX - cursorPosition.x) / scale + 400,
            y2: (workBenchY - canvas.deltaY - cursorPosition.y) / scale + 250,
            name: "new_area",
            color: getRandomColor(Object.keys(areas).length),
        }
        updateAreas(areas, "createNewArea");
        setSelectedAreaId(areaId);
    }

    const createTableOnSample = (workBenchX, workBenchY, data) => {
        const copyTableId = uuidv4();
        let newTableData = JSON.parse(JSON.stringify(data));
        Object.entries(newTableData.fields).forEach(([key, value]) => {
            if (newTableData.fields[key].constraints.includes(Constraints.PRIMARY_KEY)) {
                newTableData.fields[uuidv4()] = {...value, relations: [], constraints: [Constraints.PRIMARY_KEY], fkRelations: []}
            } else {
                newTableData.fields[uuidv4()] = {...value, relations: [], constraints: [], fkRelations: []}
            }
            delete newTableData.fields[key];
        });
        tables[`${copyTableId}`] = {
            ...newTableData,
            x: (workBenchX - canvas.deltaX - cursorPosition.x) / scale,
            y: (workBenchY - canvas.deltaY - cursorPosition.y) / scale,
            color: getRandomColor(Object.keys(tables).length),
        }
        
        
        updateTables(tables, "createTableOnSample");
        setSelectedTableId(copyTableId);
    }

    const updateProjectName = (projectName, methodName) => {
        saveToLocalStorage(SaveMode.PROJECT_NAME, methodName, null, null, projectName)
    }
    
    const updateTables = (tables, methodName, needSaveToLocalStorage = true) => {
        setTables({...tables})
        if (needSaveToLocalStorage) {
            saveToLocalStorage(SaveMode.TABLES, methodName, tables)
        }
    }

    const updateAreas = (areas, methodName, needSaveToLocalStorage = true) => {
        setAreas({...areas})
        if (needSaveToLocalStorage) {
            saveToLocalStorage(SaveMode.AREAS, methodName, null, null, null, areas)
        }
    }

    const updateRelations = (relations, methodName) => {
        setRelations({...relations})
        saveToLocalStorage(SaveMode.RELATIONS, methodName, null, relations)
    }

    const updateTablesAndRelations = (tables, relations, methodName) => {
        setTables({...tables})
        setRelations({...relations})
        saveToLocalStorage(SaveMode.TABLES_AND_RELATIONS, methodName, tables, relations)
    }

    const updateAll = (tables, relations, projectName, areas, methodName) => {
        setTables({...tables})
        setRelations({...relations})
        setProjectInfo({...projectInfo, name: projectName})
        setAreas({...areas})
        saveToLocalStorage(SaveMode.ALL, methodName, tables, relations, projectName, areas)
    }

    // добавлена передача tables и relations для получения актуальных данных (redux может не успеть подгрузить актуальные данные)
    const saveToLocalStorage = (mode, methodName, newTables = null, newRelations = null, newProjectName = null, newAreas = null) => {
        const tablesToSave = newTables === null ? tables : newTables;
        const relationsToSave = newRelations === null ? relations : newRelations;
        const projectNameToSave = newProjectName === null ? projectInfo.name : newProjectName;
        const areasToSave = newAreas === null ? areas : newAreas;
        if (![SaveMode.PROJECT_NAME, SaveMode.PROJECT_SETTINGS].includes(mode)) {
            saveToHistory(tablesToSave, relationsToSave, areasToSave);
        }
        const dataToSave = {tables: tablesToSave, relations: relationsToSave, name: projectNameToSave, areas: areasToSave}
        localStorage.setItem("project_" + projectId, JSON.stringify(dataToSave));
        console.log(`Данные ${mode} сохранены в localStorage из ${methodName}`);
    };

    const updateFieldType = (tableId, fieldId, newType) => {
        tables[tableId].fields[fieldId].type = newType;
        // проверка, что типы данных у связанных полей такие же
        const fieldRelations = tables[tableId].fields[fieldId].relations;
        for (let i = 0; i < fieldRelations.length; i++) {
            const relation = relations[fieldRelations[i]]
            if (relation === undefined) {
                tables[tableId].fields[fieldId].relations = tables[tableId].fields[fieldId].relations.filter((r) => r !== fieldRelations[i]);
                continue;
            }
            const otherTableIdAndFieldId = getOtherTableAndFieldFromRelation(relation, tableId, fieldId);
            const hasRelationTypeError = newType !== tables[otherTableIdAndFieldId.tableId].fields[otherTableIdAndFieldId.fieldId].type
            manageRelationError(hasRelationTypeError, RelationError.DIFFERENT_TYPES_OF_RELATED_FIELDS, fieldRelations[i])
            if (hasRelationTypeError) {
                // createNotification(NotificationStatus.WARNING, `Связываемые поля имеют несовместимые типы данных (${newType}, ${tables[otherTableIdAndFieldId.tableId].fields[otherTableIdAndFieldId.fieldId].type})`, "Ошибка типа данных")
            }
        }
        
        updateTablesAndRelations(tables, relations, "changeFieldType")
    }
    
    const closeAllTables = () => {
        setOpenedTableIds(new Set())
    }

    const openAllTables = () => {
        setOpenedTableIds(new Set(Object.keys(tables)))
    }

    const unSelectLastSelectedTable = () => {
        setSelectedTableId(null);
    }
    const unSelectLastSelectedArea = () => {
        setSelectedAreaId(null);
    }


    
    const getTableHeight = (tableId) => {
        return Object.keys(tables[tableId].fields).length * 20 + 56;
    }

    const checkSelectedTables = () => {
        let startCoordsX = selectingArea.startMouseCoords.x < selectingArea.endMouseCoords.x ? selectingArea.startMouseCoords.x : selectingArea.endMouseCoords.x;
        let startCoordsY = selectingArea.startMouseCoords.y < selectingArea.endMouseCoords.y ? selectingArea.startMouseCoords.y : selectingArea.endMouseCoords.y;
        let endCoordsX = selectingArea.startMouseCoords.x < selectingArea.endMouseCoords.x ? selectingArea.endMouseCoords.x : selectingArea.startMouseCoords.x;
        let endCoordsY = selectingArea.startMouseCoords.y < selectingArea.endMouseCoords.y ? selectingArea.endMouseCoords.y : selectingArea.startMouseCoords.y;

        let selectedTables = []
        
        for (let tableId in tables) {
            if (tables[tableId].x >= startCoordsX && tables[tableId].y >= startCoordsY - 8 && 
                tables[tableId].x + TABLE_WIDTH <= endCoordsX && tables[tableId].y + getTableHeight(tableId) <= endCoordsY) {
                selectedTables.push(tableId)
            }
        }
        if (selectedTables.length > 0 && selectedTableId) { // может случиться дублирование, поэтому позже используем set
            selectedTables.push(selectedTableId)
        }
        
        setSelectedTablesId([...new Set(selectedTables)])
    };
    
    const clickFieldRelationPoint = (e, fieldId, position) => {
        e.stopPropagation()
        setSelectedRelationId(null)
        setSelectedField({
            fieldId: fieldId,
            position: position
        })

        const startDeltaX = position === Positions.RIGHT ? TABLE_WIDTH : 0;

        const fieldStep = 20;
        let fieldIndex = Object.keys(tables[selectedTableId].fields).findIndex((findFieldId) => findFieldId === fieldId);
        const startDeltaY = 54 + fieldStep * fieldIndex;
        
        setMouseCoords({ x: tables[selectedTableId].x + startDeltaX, y: tables[selectedTableId].y + startDeltaY})
    }
    
    const createRelation = (e, targetTableId, targetFieldId) => {
        if (targetTableId && selectedField && selectedField.fieldId !== targetFieldId) { // если поле не то же самое
            const relationId = uuidv4()
            let relationErrors = []
            if (!(tables[selectedTableId].fields[selectedField.fieldId].type === tables[targetTableId].fields[targetFieldId].type)) { // если типы полей не совпадают
                relationErrors.push(RelationError.DIFFERENT_TYPES_OF_RELATED_FIELDS)
                // createNotification(NotificationStatus.WARNING, `У связываемых полей несовместимые типы данных [${tables[selectedTableId].fields[selectedField.fieldId].type}, ${tables[targetTableId].fields[targetFieldId].type}]`, "Невозможная связь")
            }
            relations[relationId] = {
                table1: selectedTableId,
                table1Field: selectedField.fieldId,
                table2: targetTableId,
                table2Field: targetFieldId,
                relationType: RelationTypes.ONE_TO_ONE,
                relationErrors: relationErrors,
            }
            
            tables[selectedTableId].fields[selectedField.fieldId].relations.push(relationId)
            tables[targetTableId].fields[targetFieldId].relations.push(relationId)
            
            // добавляем constraint ForeignKey полю, с которым связали PK
            checkFKConstraints(selectedTableId, selectedField.fieldId)
            updateTablesAndRelations(tables, relations, "createRelation")
        }
    }
    
    // проверяет связи поля с другими полями и расставляет FK, если надо
    const checkFKConstraints = (selectedTableId, selectedFieldId) => {
        for (const relationId of tables[selectedTableId].fields[selectedFieldId].relations) {
            if (!relations[relationId]) continue;
            const otherTableIdAndFieldId = getOtherTableAndFieldFromRelation(relations[relationId], selectedTableId, selectedFieldId);
            const targetTableId = otherTableIdAndFieldId.tableId;
            const targetFieldId = otherTableIdAndFieldId.fieldId;
            const selectedFieldIsPK = hasConstraintsByGroups(selectedTableId, selectedFieldId, [[Constraints.PRIMARY_KEY], [Constraints.UNIQUE, Constraints.NOT_NULL]]);
            const targetFieldIsPK = hasConstraintsByGroups(targetTableId, targetFieldId, [[Constraints.PRIMARY_KEY], [Constraints.UNIQUE, Constraints.NOT_NULL]]);
            // Если оба поля не имеют вместе PrimaryKey
            if (!(selectedFieldIsPK && targetFieldIsPK)) {
                if (selectedFieldIsPK) { // если у первого поля есть PK, а у второго нет FK
                    pushFKFieldId(targetTableId, targetFieldId, selectedFieldId)
                } else if (targetFieldIsPK) { // наоборот
                    pushFKFieldId(selectedTableId, selectedFieldId, targetFieldId)
                }
            } else { // если оба поля имеют PK
                if (checkHasCompositeConstraint(targetTableId, Constraints.PRIMARY_KEY)) { // если у целевой таблицы составной PK, то ей ставим FK
                    pushFKFieldId(targetTableId, targetFieldId, selectedFieldId)
                } else if (checkHasCompositeConstraint(selectedTableId, Constraints.PRIMARY_KEY)) { // если у основной таблицы составной PK, то ей ставим FK
                    pushFKFieldId(selectedTableId, selectedFieldId, targetFieldId)
                }  else { // Иначе ставим FK у выбранной (откуда вели стрелку) (не проверяем у выбранной таблицы составной PK, тк в любом случае ставим FK)
                    pushFKFieldId(selectedTableId, selectedFieldId, targetFieldId)
                }
            }
        }
    }
    
    const checkHasCompositeConstraint = (tableId, constraint) => {
        let count = 0;
        for (const fieldId in tables[tableId].fields) {
            if (hasConstraint(tableId, fieldId, constraint)) {
                count++;
                if (count > 1) {
                    return true;
                }
            }
        }
        return false;
    }
    
    const setConstraint = (tableId, fieldId, constraint, needSave = false) => {
        if (!hasConstraint(tableId, fieldId, constraint)) {
            tables[tableId].fields[fieldId]?.constraints?.push(constraint);
        }
        if (needSave) {
            checkFKConstraints(tableId, fieldId);
            checkRelationsPossibilityForTable(tableId);
            updateTables(tables, `setConstraint (${constraint})`)
        }
    }

    const deleteConstraint = (tableId, fieldId, constraint) => {
        tables[tableId].fields[fieldId].constraints = tables[tableId].fields[fieldId]?.constraints?.filter(c => c !== constraint);
    }
    
    const switchConstraint = (tableId, fieldId, constraint) => {
        if (hasConstraint(tableId, fieldId, constraint)) {
            deleteConstraint(tableId, fieldId, constraint)
        } else {
            setConstraint(tableId, fieldId, constraint)
        }
        checkFKConstraints(tableId, fieldId);
        checkRelationsPossibilityForTable(tableId);
        updateTables(tables, "switchConstraint")
    }
    
    
    const hasConstraint = (tableId, fieldId, constraint) => {
        return tables[tableId].fields[fieldId]?.constraints?.includes(constraint);
    }

    const hasFKRelation = (tableId, fieldId, targetFieldId) => {
        return tables[tableId].fields[fieldId]?.fkRelations?.includes(targetFieldId);
    }

    // метод проверяет, что все переданные ограничения в списке присутствуют на поле
    const hasConstraints = (tableId, fieldId, constraints) => {
        for (const constraint of constraints) {
            if (!hasConstraint(tableId, fieldId, constraint)) {
                return false;
            } 
        }
        return true;
    }
    
    // метод принимает массив из групп ограничений и проверяеь, что хотя бы одна группа ограничений присутствует на поле
    const hasConstraintsByGroups = (tableId, fieldId, constraintGroups) => {
      for (const constraintGroup of constraintGroups) {
          if (hasConstraints(tableId, fieldId, constraintGroup)) {
              return true;
          }
      }
      return false;
    }
    
    const pushFKFieldId = (tableId, fieldId, targetFieldId) => {
        if (!tables[tableId].fields[fieldId]?.fkRelations) {
            tables[tableId].fields[fieldId].fkRelations = [targetFieldId];
        } else {
            tables[tableId].fields[fieldId].fkRelations.push(targetFieldId);
        }
    }

    const deleteFKFieldId = (tableId, fieldId, targetFieldId) => {
        tables[tableId].fields[fieldId].fkRelations = tables[tableId].fields[fieldId]?.fkRelations?.filter(c => c !== targetFieldId);
    }

    const selectArea = (e, areaId) => {
        unSelectLastSelectedTable();
        unSelectLastSelectedArea()
        if (e) { // было нажатие по области на канвасе
            e.stopPropagation()
            setIsDraggingArea(true);
            let deltaX1 = (e.clientX - getCurrentRefOffset(0)) / scale - areas[areaId].x1;
            let deltaX2 = (e.clientX - getCurrentRefOffset(0)) / scale - areas[areaId].x2;
            let deltaY1 = (e.clientY - getCurrentRefOffset(1)) / scale - areas[areaId].y1;
            let deltaY2 = (e.clientY - getCurrentRefOffset(1)) / scale - areas[areaId].y2;
            selectedTableDelta[areaId] = {x1: deltaX1, y1: deltaY1, x2: deltaX2, y2: deltaY2}
            setSelectedTableDelta({...selectedTableDelta})
        }
        setSelectedAreaId(areaId)
    }

    const startResizingArea = (mode) => {
        setIsResizingArea(mode)
    }

    // метод обрабатывает выбор/выделение/доп. выделение через ctrl и рассчитывает отклонение таблицы от курсора в случае перемещения
    const selectTable = (e, newSelectedTableId) => {
        unSelectLastSelectedTable();
        unSelectLastSelectedArea()
        if (e) { // было нажатие по таблице на канвасе
            e.stopPropagation();
            let selectedTables = selectedTablesId;
            if (e.ctrlKey) {
                if (selectedTables.length === 0 && selectedTableId) { // если нет выделенных таблиц, то выбранная таблица добавляется в выделенные
                    selectedTables.push(selectedTableId)
                }
                if (selectedTableId !== newSelectedTableId) { // если выбранная таблица не среди выделенных
                    if (selectedTables.indexOf(newSelectedTableId) === -1) { // если таблица не среди выделенных
                        selectedTables.push(newSelectedTableId)
                    } else {
                        selectedTables = selectedTables.filter(t => t !== newSelectedTableId);
                    }
                }
                setSelectedTablesId([...selectedTables])
            } else {
                if (selectedTablesId.indexOf(newSelectedTableId) === -1) { // если таблица не была выделена, то обнуляем выделение и выделяем новую
                    selectedTables = []
                    setSelectedTablesId(selectedTables)
                    setSelectedTableId(newSelectedTableId);
                }
            }
            setIsDraggingTable(true);
            if (selectedTables.length > 0) { // если выделено несколько, рассчитываем их смещение относительно курсора
                for (let selectedTable of selectedTables) {
                    let deltaX = (e.clientX - getCurrentRefOffset(0)) / scale - tables[selectedTable].x;
                    let deltaY = (e.clientY - getCurrentRefOffset(1)) / scale - tables[selectedTable].y;
                    selectedTableDelta[selectedTable] = {x: deltaX, y: deltaY}
                }
                setSelectedTableDelta({...selectedTableDelta})
            } else { // если выделена одна
                let deltaX = (e.clientX - getCurrentRefOffset(0)) / scale - tables[newSelectedTableId].x;
                let deltaY = (e.clientY - getCurrentRefOffset(1)) / scale - tables[newSelectedTableId].y;
                selectedTableDelta[newSelectedTableId] = {x: deltaX, y: deltaY}
                setSelectedTableDelta({...selectedTableDelta})
            }
        } else {
            setSelectedTableId(newSelectedTableId);
        }
    }

    const selectCanvas = (e) => {
        e.stopPropagation();
        if (e.button === 2) { // ПКМ
            setSelectedField(null)
            unSelectLastSelectedArea()
            unSelectLastSelectedTable();
            setSelectedRelationId(null)
            // Начальная и смещенная позиции находятся в одной точке в начале смещения канваса
            setCanvas({...canvas, isDragging: true, initialMouseCoords: {x: e.clientX, y: e.clientY}, movingMouseCoords: {x: e.clientX, y: e.clientY}})
        } else if (e.button === 0) { // ЛКМ
            setSelectedTablesId([])
            unSelectLastSelectedArea()
            unSelectLastSelectedTable();
            let x = (e.clientX - getCurrentRefOffset(0)) / scale;
            let y = (e.clientY - getCurrentRefOffset(1)) / scale;
            setSelectingArea({ ...selectingArea, isSelecting: true, startMouseCoords: {x: x, y: y}, endMouseCoords: {x: x, y: y}})
        }
    }

    const selectRelation = (e, relationId) => {
        setMouseCoords({x: (e.clientX - getCurrentRefOffset(0)) / scale, y: (e.clientY - getCurrentRefOffset(1)) / scale})
        setSelectedRelationId(relationId)
    }

    const moveTable = (e) => {
        if (selectedTablesId.length > 0 && isDraggingTable === true) {
            for (let selectedTableId of selectedTablesId) {
                tables[selectedTableId] = { ...tables[selectedTableId], x: roundToStep(step, (e.clientX - getCurrentRefOffset(0)) / scale - selectedTableDelta[selectedTableId].x), y: roundToStep(step, (e.clientY - getCurrentRefOffset(1)) / scale - selectedTableDelta[selectedTableId].y) } // прибавляем смещение относительно scale
            }
        } else {
            if (selectedTableId === null || isDraggingTable === false) return;
            // изменяем координаты смещенной таблицы
            tables[selectedTableId] = { ...tables[selectedTableId], x: roundToStep(step, (e.clientX - getCurrentRefOffset(0)) / scale - selectedTableDelta[selectedTableId].x), y: roundToStep(step, (e.clientY - getCurrentRefOffset(1)) / scale - selectedTableDelta[selectedTableId].y) } // прибавляем смещение относительно scale
        }
        setTables({ ...tables})
    }

    const moveArea = (e) => {
        if (selectedAreaId === null || isDraggingArea === false) return;
        areas[selectedAreaId] = { ...areas[selectedAreaId],
            x1: roundToStep(step, (e.clientX - getCurrentRefOffset(0)) / scale - selectedTableDelta[selectedAreaId].x1),
            x2: roundToStep(step, (e.clientX - getCurrentRefOffset(0)) / scale - selectedTableDelta[selectedAreaId].x2),
            y1: roundToStep(step, (e.clientY - getCurrentRefOffset(1)) / scale - selectedTableDelta[selectedAreaId].y1),
            y2: roundToStep(step, (e.clientY - getCurrentRefOffset(1)) / scale - selectedTableDelta[selectedAreaId].y2)

        } // прибавляем смещение относительно scale
    }
    
    const resizeArea = (e) => {
        if (isResizingArea === "leftTop") {
            areas[selectedAreaId] = {
                ...areas[selectedAreaId],
                x1: (e.clientX - getCurrentRefOffset(0)) / scale,
                y1: (e.clientY - getCurrentRefOffset(1)) / scale
            }
            if ((e.clientX - getCurrentRefOffset(0)) / scale + 100 >= areas[selectedAreaId].x2) {
                areas[selectedAreaId] = {
                    ...areas[selectedAreaId],
                    x1: areas[selectedAreaId].x2 - 100,
                }
            }
            if ((e.clientY - getCurrentRefOffset(1)) / scale + 100 >= areas[selectedAreaId].y2) {
                areas[selectedAreaId] = {
                    ...areas[selectedAreaId],
                    y1: areas[selectedAreaId].y2 - 100
                }
            }
        } else if (isResizingArea === "rightTop") {
            areas[selectedAreaId] = {
                ...areas[selectedAreaId],
                x2: (e.clientX - getCurrentRefOffset(0)) / scale,
                y1: (e.clientY - getCurrentRefOffset(1)) / scale
            }
            if ((e.clientX - getCurrentRefOffset(0)) / scale - 100 <= areas[selectedAreaId].x1) {
                areas[selectedAreaId] = {
                    ...areas[selectedAreaId],
                    x2: areas[selectedAreaId].x1 + 100,
                }
            }
            if ((e.clientY - getCurrentRefOffset(1)) / scale + 100 >= areas[selectedAreaId].y2) {
                areas[selectedAreaId] = {
                    ...areas[selectedAreaId],
                    y1: areas[selectedAreaId].y2 - 100
                }
            }
        } else if (isResizingArea === "leftBottom") {
            areas[selectedAreaId] = {
                ...areas[selectedAreaId],
                x1: (e.clientX - getCurrentRefOffset(0)) / scale,
                y2: (e.clientY - getCurrentRefOffset(1)) / scale
            }
            if ((e.clientX - getCurrentRefOffset(0)) / scale + 100 >= areas[selectedAreaId].x2) {
                areas[selectedAreaId] = {
                    ...areas[selectedAreaId],
                    x1: areas[selectedAreaId].x2 - 100,
                }
            }
            if ((e.clientY - getCurrentRefOffset(1)) / scale - 100 <= areas[selectedAreaId].y1) {
                areas[selectedAreaId] = {
                    ...areas[selectedAreaId],
                    y2: areas[selectedAreaId].y1 + 100
                }
            }
        } else if (isResizingArea === "rightBottom") {
            areas[selectedAreaId] = {
                ...areas[selectedAreaId],
                x2: (e.clientX - getCurrentRefOffset(0)) / scale,
                y2: (e.clientY - getCurrentRefOffset(1)) / scale
            }
            if ((e.clientX - getCurrentRefOffset(0)) / scale - 100 <= areas[selectedAreaId].x1) {
                areas[selectedAreaId] = {
                    ...areas[selectedAreaId],
                    x2: areas[selectedAreaId].x1 + 100,
                }
            }
            if ((e.clientY - getCurrentRefOffset(1)) / scale - 100 <= areas[selectedAreaId].y1) {
                areas[selectedAreaId] = {
                    ...areas[selectedAreaId],
                    y2: areas[selectedAreaId].y1 + 100
                }
            }
        }
        setAreas({...areas})
    }

    const moveCanvas = (e) => {
        if (canvas.isDragging) {
            // изменяем координаты смещенной позиции канваса
            setCanvas({
                ...canvas,
                movingMouseCoords: { x: e.clientX, y: e.clientY },
            });
        }
    };
    
    useEffect(() => {
        const handleWindowMouseMove = (e) => {
            const currentTime = Date.now();
            const isAvailableByTime = currentTime - lastMouseMoveTime > 8; // небольшое ограничение обновления кадров, чтобы не перегружать процессор (16 - примерно 60fps, 8 - 120fps)
            if (!isAvailableByTime) return;
            if (selectingArea.isSelecting) {
                setSelectingArea({...selectingArea, endMouseCoords: {x: (e.clientX - getCurrentRefOffset(0)) / scale, y: (e.clientY - getCurrentRefOffset(1)) / scale}})
                checkSelectedTables();
                setLastMouseMoveTime(currentTime);
            } else if (isResizingArea) {
                if (!areaResized)
                    setAreaResized(true)
                resizeArea(e);
                setLastMouseMoveTime(currentTime);
            }
            else if (selectedField) {
                setMouseCoords(    {x: (e.clientX - getCurrentRefOffset(0)) / scale, y: (e.clientY - getCurrentRefOffset(1)) / scale})
                setLastMouseMoveTime(currentTime);
            } else if (isDraggingTable){
                if (!tableMoved)
                    setTableMoved(true)
                moveTable(e);
                setLastMouseMoveTime(currentTime);
            } else if (isDraggingArea){
                if (!areaMoved)
                    setAreaMoved(true)
                moveArea(e);
                setLastMouseMoveTime(currentTime);
            }
            else if (canvas.isDragging) {
                moveCanvas(e);
                setLastMouseMoveTime(currentTime);
            }
        }
        
        window.addEventListener('mousemove', handleWindowMouseMove);
        return () => {
            window.removeEventListener(
                'mousemove',
                handleWindowMouseMove,
            );
        };
    }, [tables, areas, selectingArea, selectedField, scale, isDraggingTable, isResizingArea, tableMoved, canvas, cursorPosition, selectedTableId, selectedTablesId, selectedTableDelta, lastMouseMoveTime]);

    // Обработка отжатия ЛКМ/пкм
    useEffect(() => {
        const stopDragging = () => {
            if (selectingArea.isSelecting) {
                setSelectingArea({ ...selectingArea, isSelecting: false})
            }
            if (isDraggingTable) {
                setIsDraggingTable(false)
                if (tableMoved) {
                    setTables({...tables}) // для синхронизации редакса и useState, тк при перемещении таблиц отключена синхронизация (оптимизация)
                    saveToLocalStorage(SaveMode.TABLES, "stopDragging", tables)
                }
                setTableMoved(false)
                setSelectedRelationId(null)
            }
            if (isDraggingArea) {
                setIsDraggingArea(false)
                if (areaMoved) {
                    setAreas({...areas}) // для синхронизации редакса и useState, тк при перемещении таблиц отключена синхронизация (оптимизация)
                    saveToLocalStorage(SaveMode.AREAS, "stopDragging", null, null, null, areas)
                }
                setAreaMoved(false)
                setSelectedRelationId(null)
            }
            if (isResizingArea) {
                setIsResizingArea(null)
                if (areaResized) {
                    setAreas({...areas})
                    saveToLocalStorage(SaveMode.AREAS, "stopDragging", null, null, null, areas)
                }
                setAreaResized(false)
            }
            if (canvas.isDragging) {
                stopDraggingCanvas();
            }
            if (selectedField) {
                setSelectedField(null)
                setSelectedRelationId(null)
            }

        }

        window.addEventListener('mouseup', stopDragging);
        return () => {
            window.removeEventListener(
                'mouseup',
                stopDragging,
            );
        };
    }, [tables, relations, areas, history, historyIndex, selectedField, selectingArea, isDraggingTable, isResizingArea, areaResized, isDraggingArea, tableMoved, areaMoved, canvas]);

    const stopDraggingCanvas = () => {
        setCanvas({...canvas, isDragging: false,
            deltaX: canvas.deltaX + canvas.movingMouseCoords.x - canvas.initialMouseCoords.x, deltaY: canvas.deltaY + canvas.movingMouseCoords.y - canvas.initialMouseCoords.y, // прибавляем к делтам смещение
            initialMouseCoords: {x: 0, y: 0}, movingMouseCoords: {x: 0, y: 0} // обнуляем начальную и смещенную позиции
        })
        setSelectedRelationId(null)
    }
    
    const removeCTLWheel = (e) => {
        if (e.ctrlKey) {
            // Предотвращаем стандартное действие (зум)
            e.preventDefault();
        }
    }

    // Обработка нажатия клавиш
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (document.activeElement.tagName.toLowerCase() === 'input' || document.activeElement.tagName.toLowerCase() === 'textarea') {
                return;
            }
            if (event.keyCode === 46 || event.keyCode === 8) { // проверяем код клавиши delete/backspace (mac)
                if (selectedTablesId.length > 0) {
                    for (let tableId of selectedTablesId) {
                        deleteTable(event, tableId, true);
                    }
                    updateTablesAndRelations(tables, relations, "handleKeyDown deleteTable")
                    setSelectedTablesId([])
                }
                else if (selectedTableId && !selectedRelationId) {
                    deleteTable(event, selectedTableId);
                }
                else if (selectedAreaId && !selectedRelationId) {
                    deleteArea(event, selectedAreaId);
                } else if (selectedRelationId) {
                    deleteRelation(selectedRelationId, true, true)
                    setSelectedRelationId(null);
                }
            }
            if (event.ctrlKey) {
                if (event.key.toLowerCase() === 'z' || event.key.toLowerCase() === 'я') {
                    event.preventDefault();
                    manageHistoryByMode(event, "keydown", projectId);
                } else if ((event.key.toLowerCase() === 'c' || event.key.toLowerCase() === 'с') && selectedTableId) {
                    setTableToCopy(selectedTableId)
                } else if ((event.key.toLowerCase() === 'v' || event.key.toLowerCase() === 'м') && tableToCopy && tables[tableToCopy]) {
                    createTable(440, 70, {...tables[tableToCopy], name: tables[tableToCopy].name + "_copy"});
                } else if (event.key.toLowerCase() === 'a' || event.key.toLowerCase() === 'ф') {
                    event.preventDefault();
                    setSelectedTablesId(Object.keys(tables));
                }

            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [tables, relations, selectedTableId, selectedRelationId, selectedTablesId, selectedAreaId, areas, tableToCopy, projectId])
    
    // Отключение колесика + ctrl
    useEffect(() => {
        window.addEventListener('wheel', removeCTLWheel, { passive: false });
        return () => {
            window.removeEventListener(
                'wheel',
                removeCTLWheel,
            );
        };
    }, []);
    
    const handleWheel = (e) => {
        let scaleAdjust = 1;
        let deltaX = 0;
        let deltaY = 0;
        // масштабирование
        if ((e.ctrlKey || e.metaKey) && !canvas.isDragging && !e.shiftKey) {
            scaleAdjust = scaleAdjust - e.deltaY / (Math.abs(e.deltaY) < 20 ? 110 : 1000);
        } else {
            // перемещение
            if (!e.ctrlKey && !e.metaKey && !canvas.isDragging && !e.shiftKey) { // по вертикали
                deltaY = (Math.abs(e.deltaY) > 100 ? 100 * Math.sign(e.deltaY) : e.deltaY);
            }
            if (e.deltaX || e.shiftKey) { // по горизонтали
                deltaX = e.shiftKey ? (Math.abs(e.deltaY) > 100 ? 100 * Math.sign(e.deltaY) : e.deltaY) : (Math.abs(e.deltaX) > 100 ? 100 * Math.sign(e.deltaX) : e.deltaX)
            }
            setCanvas({...canvas, deltaY: canvas.deltaY - deltaY, deltaX: canvas.deltaX - deltaX})
        }

        const newScale = scale * scaleAdjust;
        if (newScale <= 0.2 && e.deltaY > 0) return // e.deltaY > 0 значит колесико вниз
        else if (newScale >= 10 && e.deltaY < 0) return;
        // Позиция канваса должна корректироваться, чтобы курсор оставался над той же точкой контента
        const newPos = {
            x: cursorPosition.x - (e.clientX - cursorPosition.x - canvas.deltaX) * (scaleAdjust - 1),
            y: cursorPosition.y - (e.clientY - cursorPosition.y - canvas.deltaY) * (scaleAdjust - 1)
        };
        // Обновление состояния
        setScale(newScale);
        setCursorPosition(newPos);
    };
    
    const handleMapMove = (e) => {
        if (e.touches.length < 3) {
            moveCanvas(e)
            // setCanvas({...canvas, deltaX: canvas.deltaX - e.deltaX, deltaY: canvas.deltaY - e.deltaY})
        }
    }

    // отключение контекстного меню
    const handleContextMenu = (event) => {
        event.preventDefault();
    };

    // текущее смещение относительно курсора (используется, при изменении масштаба + смещение канваса при перемещении - изначальные координаты канваса + имеющееся смещение канваса
    const getCurrentOffset = (coordinate) => {
        if (coordinate === 0)
            return cursorPosition.x + canvas.movingMouseCoords.x - canvas.initialMouseCoords.x + canvas.deltaX;
        else if (coordinate === 1)
            return cursorPosition.y + canvas.movingMouseCoords.y - canvas.initialMouseCoords.y + canvas.deltaY;
    }

    // вычисление смещения координат под мышкой относительно смещения канваса и смещения содержимого после масштабирования
    const getCurrentRefOffset = (coordinate) => {
        if (coordinate === 0)
            return cursorPosition.x + canvas.deltaX;
        else if (coordinate === 1)
            return cursorPosition.y + canvas.deltaY;
    }

    const deleteTable = (e, tableId, disableUpdateLocalStorage = false) => {
        if (selectedTableId === tableId) {
            setSelectedTableId(null)
        }
        e.stopPropagation()
        deleteRelations(tableId)
        delete tables[tableId];
        if (!disableUpdateLocalStorage) {
            updateTablesAndRelations(tables, relations, "deleteTable")
        }
    }

    const deleteArea = (e, areaId, disableUpdateLocalStorage = false) => {
        if (selectedAreaId === areaId) {
            setSelectedAreaId(null)
        }
        e.stopPropagation()
        delete areas[areaId];
        if (!disableUpdateLocalStorage) {
            updateAreas(areas, "deleteArea")
        }
    }
    
    const deleteField = (tableId, fieldId) => {
        tables[tableId].fields[fieldId].relations.map(relationId => {
            deleteRelation(relationId)
        })
        delete tables[tableId].fields[fieldId]
        updateTablesAndRelations(tables, relations, "deleteField")
    }

    const deleteRelations = (tableId) => {
        // получение всех связей таблички 
        const relationsToRemoveIds = [];
        for (let i = 0; i < Object.keys(tables[tableId].fields).length; i++) {
            const fieldId = Object.keys(tables[tableId].fields)[i];
            if (tables[tableId].fields[fieldId].relations && tables[tableId].fields[fieldId].relations.length) {
                relationsToRemoveIds.push(...tables[tableId].fields[fieldId].relations)
            }
        }

        // удаление всех связей
        for (let i = 0; i < relationsToRemoveIds.length; i++) {
            deleteRelation(relationsToRemoveIds[i])
        }
    }
    
    const selectRelationType = (relationType) => {
        relations[selectedRelationId].relationType = relationType;
        updateRelations(relations, "selectRelationType");
        setSelectedRelationId(null)
    }
    
    const deleteRelation = (relationId, needRelationUpdate = false, needTableUpdate = false) => {
        if (!relations[relationId]) return;
        const relationTableId1 = relations[relationId].table1;
        const relationTableFieldId1 = relations[relationId].table1Field;
        const relationTableId2 = relations[relationId].table2;
        const relationTableFieldId2 = relations[relationId].table2Field;
        // чистим связь в связанных таблицах
        tables[relationTableId1].fields[relationTableFieldId1].relations = tables[relationTableId1].fields[relationTableFieldId1].relations.filter(( relation ) => relation !== relationId);
        tables[relationTableId2].fields[relationTableFieldId2].relations = tables[relationTableId2].fields[relationTableFieldId2].relations.filter(( relation ) => relation !== relationId);

        // чистим FK
        
        if (hasFKRelation(relationTableId1, relationTableFieldId1, relationTableFieldId2)) {
            deleteFKFieldId(relationTableId1, relationTableFieldId1, relationTableFieldId2)
        }
        if (hasFKRelation(relationTableId2, relationTableFieldId2, relationTableFieldId1)) {
            deleteFKFieldId(relationTableId2, relationTableFieldId2, relationTableFieldId1);
        }
        
        delete relations[relationId];
        
        if (needRelationUpdate && needTableUpdate) {
            updateTablesAndRelations(tables, relations, "deleteRelation")
        }
        else if (needRelationUpdate) {
            updateRelations(relations, "deleteRelation")
        } 
        else if (needTableUpdate) {
            updateTables(tables, "deleteRelation")
        }
        
        if (selectedRelationId === relationId) {
            setSelectedRelationId(null)
        }
    }

    
    // метод для управления ошибками связей    
    const manageRelationError = (hasRelationError, relationError, relationId) => {
        if (relations[relationId]?.relationErrors === null) { // если связь не имеет ошибок (сделано для обратной совместимости, ранее не было данного поля)
            relations[relationId].relationErrors = [];
        } 
        if (hasRelationError && !relations[relationId]?.relationErrors?.includes(relationError)) {
            relations[relationId]?.relationErrors?.push(relationError)
        } else if (!hasRelationError && relations[relationId]?.relationErrors?.includes(relationError)) {
            relations[relationId].relationErrors = relations[relationId]?.relationErrors?.filter(relationErr => relationErr !== relationError)
        }
    }
    
    // метод сортировки словаря связей относительно кол-ва ошибок (нужен для отобажения ошибочных связей выше остальных)
    const getSortedRelationsByErrors = (relations) => {
        let array = Object.keys(relations).map(key => ({
            id: key,
            ...relations[key]
        }));

        array.sort((a, b) => {
            let lenErrA = a?.relationErrors?.length;
            let lenErrB = b?.relationErrors?.length;
            if (lenErrA === null || lenErrA === undefined) { // нужно для обратной совместимости
                lenErrA = 0;
            }
            if (lenErrB === null || lenErrB === undefined) {
                lenErrB = 0;
            }
            return (lenErrB === 0 ? -1 : lenErrB) - (lenErrA === 0 ? -1 : lenErrA);
        }).reverse();
        
        let sortedObj = {};
        array.forEach(item => {
            sortedObj[item.id] = item;
            delete sortedObj[item.id].id;
        });
        return sortedObj
    }
    
    const changeRelationType = (relation, relationType) => {
        relation.relationType = relationType
        manageRelationError(isRelationImpossible(relation), RelationError.IMPOSSIBLE_RELATION, selectedRelationId)
        selectRelationType(relationType)
    }

    // метод проверки связи между двумя таблицами на основе первичных ключей
    const isRelationImpossible = (relation) => {
        const relationType = relation.relationType;
        if (relationType === RelationTypes.MANY_TO_ONE &&
            hasConstraint(relation.table1, relation.table1Field, Constraints.PRIMARY_KEY)) {
            if (checkHasCompositeConstraint(relation.table1, Constraints.PRIMARY_KEY) && hasConstraintsByGroups(relation.table2, relation.table2Field, [[Constraints.PRIMARY_KEY], [Constraints.UNIQUE, Constraints.NOT_NULL]])) { // Если у таблицы 1 сост. первичный ключ и у второй поле явл. перв. ключом
                return false; 
            }
            createNotification(NotificationStatus.WARNING, formatString(t?.errors.impossibleRelation, relationType ?? "empty type", tables[relation.table1].name ?? "empty name", tables[relation.table2].name ?? "empty name"), t?.errors.relationTypeError ?? "asdf")
            return true;
        } else if (relationType === RelationTypes.ONE_TO_MANY && hasConstraint(relation.table2, relation.table2Field, Constraints.PRIMARY_KEY)) {
            if (checkHasCompositeConstraint(relation.table2, Constraints.PRIMARY_KEY) && hasConstraintsByGroups(relation.table1, relation.table1Field, [[Constraints.PRIMARY_KEY], [Constraints.UNIQUE, Constraints.NOT_NULL]])) { // Если у таблицы 2 сост. первичный ключ
                return false;
            }
            createNotification(NotificationStatus.WARNING, formatString(t?.errors.impossibleRelation, relationType ?? "empty type", tables[relation.table1].name ?? "empty name", tables[relation.table2].name ?? "empty name"), t?.errors.relationTypeError ?? "asdf")
            return true;
        }
        return false;
    }

    const switchPrimaryKey = (tableId, fieldId) => {
        
        if (hasConstraint(tableId, fieldId,Constraints.PRIMARY_KEY)) {
            deleteConstraint(tableId, fieldId, Constraints.PRIMARY_KEY);
        } else if (hasConstraint(tableId, fieldId, Constraints.UNIQUE)) {
            deleteConstraint(tableId, fieldId, Constraints.UNIQUE);
        } else {
            setConstraint(tableId, fieldId, Constraints.PRIMARY_KEY);
        }
        checkFKConstraints(tableId, fieldId);
        checkRelationsPossibilityForTable(tableId);
        updateTablesAndRelations(tables, relations, "switchPrimaryKey")
    }

    const switchPrimaryKeyAndUnique = (e, tableId, fieldId) => {
        const constraintToSet = hasConstraint(tableId, fieldId, Constraints.PRIMARY_KEY) ? Constraints.UNIQUE : Constraints.PRIMARY_KEY;
        const constraintToRemove = constraintToSet === Constraints.PRIMARY_KEY ? Constraints.UNIQUE : Constraints.PRIMARY_KEY;
        deleteConstraint(tableId, fieldId, constraintToRemove)
        setConstraint(tableId, fieldId, constraintToSet)
        checkFKConstraints(tableId, fieldId);
        checkRelationsPossibilityForTable(tableId);
        updateTables(tables, 'switchPrimaryKeyAndUnique')
    }
    
    // метод проверки корректности всех связей у таблицы
    const checkRelationsPossibilityForTable = (tableId) => {
        // проходимся по всем полям таблицы и по их связям
        for (const fieldId in tables[tableId].fields) {
            for (const relationId of tables[tableId].fields[fieldId].relations) {
                
                manageRelationError(isRelationImpossible(relations[relationId]), RelationError.IMPOSSIBLE_RELATION, relationId)
            }
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setTables({...tables})
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [tables]);

    const [workBenchMode, setWorkBenchMode] = useState(WorkBenchMode.TABLES);

    const switchWorkBenchMode = (newWorkBenchMode = null) => {
        if (newWorkBenchMode == null) {
            if (workBenchMode === WorkBenchMode.TABLES) {
                setWorkBenchMode(WorkBenchMode.AREAS)
            } else if (workBenchMode === WorkBenchMode.AREAS) {
                setWorkBenchMode(WorkBenchMode.TABLES)
            }
        } else {
            setWorkBenchMode(newWorkBenchMode)
        }
    }

    return (
        <div style={{width: "100%", height: "100vh", overflow: "hidden"}} tabIndex="0" className={st.container}>
            {!isLoading ?
                <>
                    <HeaderLeft projectInfo={projectInfo} setProjectInfo={setProjectInfo}
                                updateProjectName={updateProjectName}/>
                    <HeaderRight manageHistoryByMode={manageHistoryByMode} projectId={projectId}
                                 resizeCanvas={resizeCanvas}
                                 historyIndex={historyIndex} updateHistory={updateHistory} history={history}
                                 fieldErrorById={fieldErrorById} updateTablesAndRelations={updateTablesAndRelations}
                                 updateAll={updateAll}/>
                    <WorkBench createObject={createObject} selectTable={selectTable}
                               selectedTableId={selectedTableId} selectArea={selectArea} selectedAreaId={selectedAreaId} selectedTablesId={selectedTablesId}
                               updateTables={updateTables} updateAreas={updateAreas} deleteArea={deleteArea}
                               deleteTable={deleteTable} deleteField={deleteField} updateFieldType={updateFieldType}
                               openedTableIds={openedTableIds} setOpenedTableIds={setOpenedTableIds}
                               switchPrimaryKey={switchPrimaryKey}
                               switchConstraint={switchConstraint} isOpenedWorkBenchMenu={isOpenedWorkBenchMenu}
                               setIsOpenedWorkBenchMenu={setIsOpenedWorkBenchMenu}
                               switchPrimaryKeyAndUnique={switchPrimaryKeyAndUnique} setConstraint={setConstraint}
                               closeAllTables={closeAllTables} openAllTables={openAllTables}
                               fieldErrorById={fieldErrorById} workBenchMode={workBenchMode} switchWorkBenchMode={switchWorkBenchMode}
                    />
                    {<DevelopmentConsole saveToLocalStorage={saveToLocalStorage} projectId={projectId}
                                         setProjectInfo={setProjectInfo}/>
                    }

                    <div className={st.canvasBlock} id={"myCanvas"} onContextMenu={(e) => e.preventDefault()}>
                        <svg
                            width={window.innerWidth}
                            height={window.innerHeight}
                            onWheel={handleWheel}
                            // onTouchStart={selectCanvas}
                            // onTouchMove={handleMapMove}
                            // onTouchEnd={stopDraggingCanvas}
                        >
                            <defs>
                                <pattern id="backgroundPattern" patternUnits="userSpaceOnUse" width={step * scale}
                                         height={step * scale} x={getCurrentOffset(0)} y={getCurrentOffset(1)}>
                                    <Point width={step * scale} height={step * scale}/>
                                </pattern>
                            </defs>
                            <rect onMouseDown={selectCanvas} x={0} y={0} width={window.innerWidth}
                                  height={window.innerHeight} fill="url(#backgroundPattern)"/>
                            <g
                                transform={`translate(${getCurrentOffset(0)}, ${getCurrentOffset(1)}) scale(${scale})`}>
                                {Object.keys(areas).map((areaId) => (
                                    <Area area={areas[areaId]} selectedAreaId={selectedAreaId} selectArea={selectArea} areaId={areaId} isInitialRender={isInitialRender} startResizingArea={startResizingArea} switchWorkBenchMode={switchWorkBenchMode}/>
                                ))}
                                {Object.keys(getSortedRelationsByErrors(relations)).map((relationId) => (
                                    <Arrow key={relationId} relationId={relationId} relation={relations[relationId]}
                                           tables={tables} selectedRelationId={selectedRelationId}
                                           setSelectedRelationId={setSelectedRelationId} selectRelation={selectRelation}
                                           isInitialUpdate={isInitialUpdate}/>
                                ))}
                                {selectedField &&
                                    <CreationArrow selectedTableId={selectedTableId} tables={tables}
                                                   mouseCoords={mouseCoords}
                                                   selectedField={selectedField}/>
                                }
                                {Object.keys(tables).map((tableId) => (
                                    <svg onAnimationEnd={() => setIsInitialRender(false)}
                                         className={cn({[st.table]: isInitialRender})} key={tableId}
                                         x={roundToStep(step, tables[tableId].x)}
                                         y={roundToStep(step, tables[tableId].y) + step / 2} fill="none"
                                         xmlns="http://www.w3.org/2000/svg"
                                         onMouseDown={(e) => selectTable(e, tableId)}>
                                        {(selectedTableId === tableId || selectedTablesId.includes(tableId)) && (
                                            <>
                                                <TableTopBorderSelect
                                                    isGroupSelection={selectedTablesId.includes(tableId)}/>
                                                <TableBorderSelect x={4} y={6} height={38}
                                                                   isGroupSelection={selectedTablesId.includes(tableId)}/>
                                                <TableBorderSelect x={TABLE_WIDTH + 5} y={6} height={38}
                                                                   isGroupSelection={selectedTablesId.includes(tableId)}/>
                                            </>
                                        )}
                                        <TableTopBorder color={tables[tableId].color} switchWorkBenchMode={switchWorkBenchMode} tableId={tableId}/>
                                        <TableHeader text={tables[tableId].name} tableId={tableId}
                                                     fieldErrorById={fieldErrorById} switchWorkBenchMode={switchWorkBenchMode} comment={tables[tableId]?.comment ?? ""}/>
                                        <g>
                                            {Object.keys(tables[tableId]?.fields).map((fieldId, fieldIndex) => (
                                                <svg key={fieldId}>
                                                    <TableField deltaY={fieldIndex} key={fieldId}
                                                                field={tables[tableId].fields[fieldId]}
                                                                tableId={tableId}
                                                                fieldId={fieldId}
                                                                selectRelationField={createRelation}
                                                                isPrimaryKey={tables[tableId].fields[fieldId]?.constraints?.includes(Constraints.PRIMARY_KEY)}
                                                                isForeignKey={tables[tableId].fields[fieldId]?.fkRelations && tables[tableId].fields[fieldId]?.fkRelations.length > 0}
                                                                isUnique={tables[tableId].fields[fieldId]?.constraints?.includes(Constraints.UNIQUE)}
                                                                isNotNull={tables[tableId].fields[fieldId]?.constraints?.includes(Constraints.NOT_NULL)}
                                                                setOpenedTableIds={setOpenedTableIds}
                                                                fieldErrorById={fieldErrorById}
                                                                switchWorkBenchMode={switchWorkBenchMode}
                                                    />
                                                    {(selectedTableId === tableId || selectedTablesId.includes(tableId)) && (
                                                        <>
                                                            <TableBorderSelectWithPoint x={4} y={44 + 20 * fieldIndex}
                                                                                        height={fieldIndex === Object.keys(tables[tableId].fields).length - 1 ? 19 : 20}
                                                                                        selectFieldPoint={clickFieldRelationPoint}
                                                                                        fieldId={fieldId}
                                                                                        position={Positions.LEFT}
                                                                                        isGroupSelection={selectedTablesId.includes(tableId)}/>
                                                            <TableBorderSelectWithPoint x={TABLE_WIDTH + 5}
                                                                                        y={44 + 20 * fieldIndex}
                                                                                        height={20}
                                                                                        selectFieldPoint={clickFieldRelationPoint}
                                                                                        fieldId={fieldId}
                                                                                        position={Positions.RIGHT}
                                                                                        isGroupSelection={selectedTablesId.includes(tableId)}/>
                                                        </>
                                                    )}
                                                </svg>
                                            ))}
                                        </g>
                                        <TableBottomBorder deltaY={Object.keys(tables[tableId].fields).length}/>
                                        {(selectedTableId === tableId || selectedTablesId.includes(tableId)) && (
                                            <TableBottomBorderSelect x={3.5}
                                                                     y={42 + 20 * Object.keys(tables[tableId].fields).length}
                                                                     isGroupSelection={selectedTablesId.includes(tableId)}/>
                                        )}
                                    </svg>
                                ))}
                                {selectedRelationId &&
                                    <RelationTypeButton mouseCoords={mouseCoords}
                                                        selectedRelationId={selectedRelationId}
                                                        deleteRelation={deleteRelation}
                                                        relation={relations[selectedRelationId]}
                                                        changeRelationType={changeRelationType}/>
                                }
                                {selectingArea.isSelecting &&
                                    <rect
                                        x={selectingArea.endMouseCoords.x - selectingArea.startMouseCoords.x > 0 ? selectingArea.startMouseCoords.x : selectingArea.endMouseCoords.x}
                                        y={selectingArea.endMouseCoords.y - selectingArea.startMouseCoords.y > 0 ? selectingArea.startMouseCoords.y : selectingArea.endMouseCoords.y}
                                        width={Math.abs(selectingArea.endMouseCoords.x - selectingArea.startMouseCoords.x)}
                                        height={Math.abs(selectingArea.endMouseCoords.y - selectingArea.startMouseCoords.y)}
                                        fill={'blue'}
                                        fillOpacity="0.05"
                                        stroke={'rgba(0,93,231,0.55)'}
                                        strokeWidth={2 / scale}
                                        rx="4"
                                        ry="4"
                                        // strokeDasharray={`${10 / scale}, ${10 / scale}`}
                                    />
                                }

                            </g>
                        </svg>
                    </div>
                </>
                :
                <div className={st.loaderBg}>
                    <div className={st.loader}>
                        <div className={cn(st.inner, st.one)}></div>
                        <div className={cn(st.inner, st.two)}></div>
                        <div className={cn(st.inner, st.three)}></div>
                    </div>
                    <span>{projectInfo?.name} loading...</span>
                </div>
                    
            }
        </div>
    )
}

