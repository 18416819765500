import React from 'react';
import {TABLE_WIDTH} from "../../service/consts";
import {WorkBenchMode} from "../../service/enum/WorkBenchMode";

const TableBottomBorder = ({color, switchWorkBenchMode, tableId}) => {

    const processDoubleClick = () => {
        switchWorkBenchMode(WorkBenchMode.TABLES)
        setTimeout(() => {
            const table = document.getElementById(tableId + "optionButton")
            table.click()
            setTimeout(() => {
                const table = document.getElementById(tableId + "changeColorButton")
                table.click()
            }, [10])
        }, [10])
    }
    
    return (
        <svg width={TABLE_WIDTH} height="15.3" x="5" y={1} onDoubleClick={processDoubleClick}>
            <rect x="0" y="0" width={TABLE_WIDTH} height="10" rx="6" ry="6" fill={color} filter={"var(--color-brightness)"}/>
        </svg>
);
};

export default TableBottomBorder;