import React from 'react';

const RightArrow = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 14L20 9L15 4" stroke={'var(--icon-color)'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4 20V13C4 11.9391 4.42143 10.9217 5.17157 10.1716C5.92172 9.42143 6.93913 9 8 9H20" stroke={'var(--icon-color)'}
                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>


    );
};

export default RightArrow;