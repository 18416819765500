import React from 'react';

const Options = ({width = 28, height = 28, selected}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14" cy="14" r="2" fill={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}/>
            <circle cx="14" cy="6" r="2" fill={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}/>
            <circle cx="14" cy="22" r="2" fill={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}/>
        </svg>
    );
};

export default Options;