import {MAX_HISTORY} from "../../consts";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {updateAreasRedux, updateRelationsRedux, updateTablesRedux} from "../../../redux/actions/appActions";

export const useVersionHistory = () => {
    let [history, setHistory] = useState([]);
    let [historyIndex, setHistoryIndex] = useState(0);

    const dispatch = useDispatch();
    const setTables = (tables) => {
        dispatch(updateTablesRedux(tables))
    }
    const setRelations = (relations) => {
        dispatch(updateRelationsRedux(relations))
    }

    const setAreas = (areas) => {
        dispatch(updateAreasRedux(areas))
    }
    
    const saveToHistory = (tables, relations, areas) => {
        if (historyIndex < history.length - 1) { // если внесли изменения после отката версии через ctrl+z
            history = history.slice(0, historyIndex + 1)
        }
        history.push(JSON.stringify({tables: tables, relations: relations, areas: areas}))
        if (history.length > MAX_HISTORY) {
            history = history.reverse().slice(0, MAX_HISTORY).reverse()
        } else {
            historyIndex++
            setHistoryIndex(historyIndex)
        }
        setHistory([...history])
    }

    const manageHistoryByMode = (event, mode, projectId) => {
        let newHistoryIndex = historyIndex;
        if (mode === "keydown") {
            if (event.shiftKey && historyIndex < MAX_HISTORY - 1 && historyIndex < history.length - 1) {
                newHistoryIndex++;
            } else if (!event.shiftKey && historyIndex > 0) {
                newHistoryIndex--;
            } else return;
        } else if (mode === "button") {
            if (event === "left" && historyIndex > 0) {
                newHistoryIndex--;
            } else if (event === "right" && historyIndex < history.length - 1) {
                newHistoryIndex++;
            } else return;
        }
        updateHistory(newHistoryIndex, projectId);
    }

    const updateHistory = (newHistoryIndex, projectId) => {
        if (newHistoryIndex === historyIndex) return;
        setTables(JSON.parse(history[newHistoryIndex]).tables ?? {});
        setRelations(JSON.parse(history[newHistoryIndex]).relations ?? {});
        setAreas(JSON.parse(history[newHistoryIndex]).areas ?? {})
        setHistoryIndex(newHistoryIndex);
        const data = JSON.parse(localStorage.getItem("project_" + projectId))
        const dataToSave = {...data, name: data.name ?? "", tables: JSON.parse(history[newHistoryIndex]).tables ?? {}, relations: JSON.parse(history[newHistoryIndex]).relations ?? {}, areas: JSON.parse(history[newHistoryIndex]).areas ?? {}}
        localStorage.setItem("project_" + projectId, JSON.stringify(dataToSave));
        console.log(`Данные проекта обновлены из истории`);
    }
    
    return {history, setHistory, setHistoryIndex, historyIndex, saveToHistory, manageHistoryByMode, updateHistory}
}