import React from 'react';
import cn from "classnames";
import st from "./SettingsModal.module.css";
import Modal from "../Modal";
import {useSettings} from "../../../service/utils/hooks/useSettings";
import SettingProperty from "./SettingProperty/SettingProperty";

const SettingsModal = ({ settingsModalIsOpened, setSettingsModalIsOpened, generalMode }) => {
    const {allSettingsTemplate, generalSettingsTemplate, selectedSetting, setSelectedSetting, settingsBuffer, updateSettings, saveSettings, t} = useSettings();
    return (
        <Modal isOpened={settingsModalIsOpened} close={setSettingsModalIsOpened}>
            <div className={st.titleBlock}>
                <span>{t?.settings}</span>
            </div>
            <div className={st.modalBody}>
                <div className={st.settingsTreeBlock}>
                    {(generalMode ? generalSettingsTemplate : allSettingsTemplate).map((setting, index) => (
                        <div key={index + "SettingTitle"} className={cn(st.settingTitle, {[st.settingTitleSelected]: selectedSetting === setting.sysName})} onClick={() => setSelectedSetting(setting.sysName)}>
                            <span>{t?.[setting.sysName]}</span>
                        </div>
                    ))}
                </div>
                <div className={st.divider} style={{height: "calc(100% - 40px)"}}></div>
                <div className={st.settingsPropertiesTreeBlock}>
                    {(generalMode ? generalSettingsTemplate : allSettingsTemplate).map((settingsGroup, index) => (
                        <div key={index + "SettingBlock" + settingsGroup?.sysName} className={st.settingsProperties}> 
                            {settingsGroup?.sysName === selectedSetting &&
                             <>
                                 
                                {settingsGroup?.settings.map((setting, index2) => (
                                    <SettingProperty t={t} setting={setting} currentValue={settingsBuffer[setting.sysName]} onChange={updateSettings} key={index + "SettingBlock" + settingsGroup?.sysName + index2}/>
                                ))}
                             </>
                            }
                        </div>
                    ))}
                </div>
            </div>
            <div className={st.modalFooter}>
                <div className={st.closeButton} onClick={() => setSettingsModalIsOpened(false)}>
                    <span>{t?.cancel}</span>
                </div>
                <div className={cn(st.bigButton)} onClick={() => {setSettingsModalIsOpened(false); saveSettings()}}>
                    <span>{t?.save}</span>
                </div>
            </div>
        </Modal>


    );
};

export default SettingsModal;