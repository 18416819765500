const initialAreasState = {};

const areasReducer = (state = initialAreasState, action) => {
  switch (action.type) {
      case 'SET_AREAS':
          return { ...action.payload };
      default:
          return state;
  }
}

export default areasReducer; 