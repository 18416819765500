import React, {useEffect, useRef, useState} from 'react';
import cn from "classnames";
import st from "./ImportModal.module.css";
import Modal from "../Modal";
import {ExportTypes} from "../../../service/enum/ExportTypes";
import Download from "../../../resources/Download";
import {createNotification, NotificationStatus} from "../../../service/Notification";
import {importJSONFile} from "../../../service/utils/Import/Json/ImportConverter";
import {importSQLFile} from "../../../service/utils/Import/PostgreSQL/ImportConverter";
import {useSettings} from "../../../service/utils/hooks/useSettings";

const ImportModal = ({ updateAll, resizeCanvas, importModalIsOpened, setImportModalIsOpened }) => {

    const [isDragging, setIsDragging] = useState(false);
    const [importType, setImportType] = useState(null);
    const [importFile, setImportFile] = useState(null);
    const fileInputRef = useRef(null);

    const {t} = useSettings();
    
    // Обработчик для выбора файла через проводник
    const handleFileInputChange = (e) => {
        setImportFile(e.target.files[0]);
    };
    
    const importData = ({tables, relations, areas, name, settings}) => {
        updateAll(tables, relations, name, areas, "importDataJson")
        createNotification(NotificationStatus.SUCCESS, t?.success.import)
        resizeCanvas(tables, areas)
        setImportModalIsOpened(false)
    };
    
    
    const importDiagram = () => {
        switch (importType) {
            case ExportTypes.JSON:
                importJSONFile(t, importFile, importData)
                break;
            case ExportTypes.PostgreSQL:
                importSQLFile(t, importFile, importData)
                break;
            default:
                break;
        }
    }


    useEffect(() => {
        if (importModalIsOpened) {
            setImportType(null)
            setImportFile(null)
            setIsDragging(false)
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    }, [importModalIsOpened]);

    // Обработчик для drag-and-drop
    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        if (importType === null) return
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        // проверяем, что сам файл есть, что его расширение совпадает с выбранным типом
        if (file && importType != null && convertType(importType) === file.name.slice(file.name.lastIndexOf('.') + 1)) {
            setImportFile(file);
        }
    };
    
    const convertType = (type) => {
        switch (type?.toLowerCase()) {
            case "postgresql":
                return "sql";
            case null:
                return "";
            default:
                return type?.toLowerCase();
        }
    }
    
    const clearInputAndClose = (e) => {
        e.target.value = '';
        setImportModalIsOpened(false)
    }
    
    return (
        <Modal isOpened={importModalIsOpened} close={setImportModalIsOpened}>
            <div className={st.exportTitleBlock}>
                <span>{t?.importTitle}</span>
            </div>
            <div className={st.modalBody}>
                <div className={st.actionBlock}>
                    <div className={st.actionBlockTitleBlock}>
                        <span>{t?.chooseImportType}</span>
                    </div>
                    <div className={st.itemsBlock}>
                        {Object.entries(ExportTypes).map(([key, value], index) => (
                            <div key={index} className={cn(st.typeItemBlock, {[st.exportTypeBlockOn]: importType === key})} onClick={() => setImportType(key)}>
                                <div className={st.exportTypeBlock}>
                                    <span>{value}</span>
                                    <img src={require(`../../../resources/image/${value.toLowerCase()}.png`)}
                                         className={st.exportTypeImg}/>
                                    {value === "PostgreSQL" &&
                                        <div className={st.tagBlock}>
                                            <span>Test</span>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={st.divider} style={{height: "calc(100% - 40px)"}}></div>

                <div className={st.actionBlock}>
                    <span>{t?.yourFile}</span>
                    <div className={st.importFileBlock}
                         onDragOver={handleDragOver}
                         onMouseLeave={handleDragLeave}
                         onDragLeave={handleDragLeave}
                         onDrop={handleDrop}>
                        <input
                            type="file"
                            id="file-input"
                            ref={fileInputRef}
                            accept={"." + convertType(importType)}
                            disabled={importType === null}
                            style={{display: "none"}}
                            onChange={handleFileInputChange}
                        />
                        <label htmlFor="file-input" className={st.fileLoadLabel}>
                            <div className={cn(st.fileLoadArea, {[st.fileLoadAreaDisabled]: importType === null}, {[st.fileLoadAreaOn]: isDragging})}>
                                {importFile ? (
                                    <span>{importFile.name}</span>
                                ) : (
                                    <>
                                        {isDragging ? (
                                            <span>{t?.dropYourFile}</span>
                                        ) : (
                                            <span>{t?.moveYourFileOrClickHere}</span>
                                        )}
                                    </>
                                )}
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div className={st.modalFooter}>
                <div className={st.bigButton} onClick={(e) => clearInputAndClose(e)}>
                    <span>{t?.cancel}</span>
                </div>
                <div className={cn(st.bigButton, {[st.bigButtonDisabled]: importFile === null})} onClick={(e) => importDiagram(e)}>
                    <span>{t?.import}</span>
                    <Download width={18} color={"white"}/>
                </div>
            </div>
        </Modal>


    );
};

export default ImportModal;