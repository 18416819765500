import st from "./DevelopmentConsole.module.css";
import cn from "classnames";
import Arrow from "../../resources/Arrow";
import {useEffect, useRef, useState} from "react";
import Right from "../../resources/Right";
import Error from "../../resources/Error";
import {SaveMode} from "../../service/enum/SaveMode";
import {createNotification, NotificationStatus} from "../../service/Notification";
import {useDispatch, useSelector} from "react-redux";
import {updateRelationsRedux, updateTablesRedux} from "../../redux/actions/appActions";
import {generateJSONFile} from "../../service/utils/Export/Json/ExportConverter";
import {useSettings} from "../../service/utils/hooks/useSettings";

const DevelopmentConsole = ({ saveToLocalStorage, projectId, setProjectInfo}) => {

    const {t} = useSettings();
    
    const [isOpenedConsole, setIsOpenedConsole] = useState(false);
    const [consoleTablesData, setConsoleTablesData] = useState("");
    const [consoleRelationsData, setConsoleRelationsData] = useState("");
    const [cursorPosition, setCursorPosition] = useState(0);
    const [isTableJsonValid, setIsTableJsonValid] = useState(true);
    const [isRelationsJsonValid, setIsRelationsJsonValid] = useState(true);
    const tablesTextAreaRef = useRef(null);
    const relationsTextAreaRef = useRef(null);
    const switchConsole = () => {
        setIsOpenedConsole(!isOpenedConsole)
        setConsoleTablesData(formatJson(JSON.stringify(tables)))
        setConsoleRelationsData(formatJson(JSON.stringify(relations)))
        validateJson(JSON.stringify(tables), "tables")
        validateJson(JSON.stringify(relations), "relations")
    }

    const dispatch = useDispatch();
    const tables = useSelector((state) => state.tablesRedux);
    const relations = useSelector((state) => state.relationsRedux);
    const setTables = (tables) => {
        dispatch(updateTablesRedux(tables))
    }

    const setRelations = (relations) => {
        dispatch(updateRelationsRedux(relations))
    }

    useEffect(() => {
        setConsoleTablesData(formatJson(JSON.stringify(tables)))
        setConsoleRelationsData(formatJson(JSON.stringify(relations)))
    }, [tables, relations]);

    const formatData = () => {
        setConsoleTablesData(formatJson(consoleTablesData.replaceAll(" ", "").replaceAll("\n", "")))
        setConsoleRelationsData(formatJson(consoleRelationsData.replaceAll(" ", "").replaceAll("\n", "")))
    }
    
    const exportData = () => {
        const question = "Сохранить локально схему перед экспортом ?";
        // eslint-disable-next-line no-restricted-globals
        const needSave = confirm(question);
        if (needSave) {
            saveData()
        }
        generateJSONFile(projectId, t)
    }
    
    const importData = ({tables, relations, name}) => {
        setTables(tables)
        setRelations(relations)
        setProjectInfo({name: name})
        createNotification(NotificationStatus.SUCCESS, t?.success.import)
    };
    
    const saveData = () => {
        if (isTableJsonValid && isRelationsJsonValid) {
            setTables(JSON.parse(consoleTablesData.replaceAll(" ", " ")));
            setRelations(JSON.parse(consoleRelationsData.replaceAll(" ", " ")));
            saveToLocalStorage(SaveMode.ALL, "saveData (Dev Console)", JSON.parse(consoleTablesData.replaceAll(" ", " ")), JSON.parse(consoleRelationsData.replaceAll(" ", " ")))
        }
    }

    const changeData = (e, key) => {
        validateJson(e.target.value.replaceAll(" ", " "), key)
        let data;
        if (key === "tables") {
            data = consoleTablesData;
        } else {
            data = consoleRelationsData;
        }
        
        // Получаем текущую позицию курсора
        let newCharIndex = null;
        if (e.target.value.length < data.length) {
            setCursorPosition(key === "tables" ? tablesTextAreaRef.current.selectionStart : relationsTextAreaRef.current.selectionStart);
        } else {
            const consoleJson =  data.replaceAll(" ", "").replaceAll("\n", "");
            const newJson =  e.target.value.replaceAll(" ", "").replaceAll("\n", "");
            for (let i = 0; i < consoleJson.length; i++) {
                if (consoleJson[i] !== newJson[i]) {
                    newCharIndex = i
                    break
                }
            } 
        }
        // после изменения json идет переформирование строки с пробелами и переносами, поэтому передаем позицию курсора
        if (key === "tables") {
            setConsoleTablesData(formatJson(e.target.value.replaceAll(" ", "").replaceAll("\n", ""), newCharIndex))
        } else {
            setConsoleRelationsData(formatJson(e.target.value.replaceAll(" ", "").replaceAll("\n", ""), newCharIndex))
        }
        
    }
    
    useEffect(() => {
        tablesTextAreaRef.current.setSelectionRange(cursorPosition, cursorPosition);
        relationsTextAreaRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }, [consoleTablesData, consoleRelationsData, cursorPosition])
    
    const validateJson = (json, key) => {
      try {
          JSON.parse(json)
          key === "tables" ? setIsTableJsonValid(true) : setIsRelationsJsonValid(true)
          
      } catch (e) {
          key === "tables" ? setIsTableJsonValid(false) : setIsRelationsJsonValid(false)
      }
    }
    
    const formatJson = (json, newCharIndex = null) => {
        let formattedJson = "";
        let space = "   ";
        let level = 0
        for (let i = 0; i < json.length; i++) {
            if (json[i] === "{") {
                formattedJson += "\n" + space.repeat(level) + json[i] + "\n" + space.repeat(level + 1)
                level++
            } else if (json[i] === ",") {
                formattedJson += json[i] + "\n" + space.repeat(level)
            } else if (json[i] === "}") {
                formattedJson += "\n" + space.repeat(level - 1) + json[i]
                level--
            }
            else {
                formattedJson += json[i]
            }
            // сетим позицию курсора
            if (newCharIndex === i) {
                setCursorPosition(formattedJson.length)
            }
        }
        return formattedJson;
    }
    
    return (
        <div className={st.consoleBlock}>
            <div className={st.consoleHeader} onClick={switchConsole}>
                <Arrow isOpened={isOpenedConsole} color={'var(--text-regular-color)'}/>
                <span>Developer console</span>
            </div>
            <div className={cn(st.editorBlockClosed, {[st.editorBlockOpened]: isOpenedConsole})}>
                <span>Tables</span>
                <div className={st.tablesEditorBlock}>
                    <div className={st.indicatorBlock}>
                        {isTableJsonValid === true && isOpenedConsole &&
                            <Right/>
                        }
                        {isTableJsonValid === false && isOpenedConsole &&
                            <Error/>
                        }
                    </div>
                    <textarea ref={tablesTextAreaRef} spellCheck="false" className={st.editor} value={consoleTablesData} onChange={(e) => changeData(e, "tables")}/>
                </div>
                <span>Relations</span>
                <div className={st.tablesEditorBlock}>
                    <div className={st.indicatorBlock}>
                        {isRelationsJsonValid === true && isOpenedConsole &&
                            <Right/>
                        }
                        {isRelationsJsonValid === false && isOpenedConsole &&
                            <Error/>
                        }
                    </div>
                    <textarea ref={relationsTextAreaRef} spellCheck="false" className={st.editor} value={consoleRelationsData} style={{height: "30vh"}} onChange={(e) => changeData(e, "relations")}/>
                </div>
                <div className={st.buttonsBlock}>
                    <button className={st.closeButton} onClick={switchConsole}>Cancel</button>
                    <button className={st.closeButton} onClick={exportData}>Export</button>
                    <label className={st.importButtonLabel} htmlFor="file-loader">Import</label>
                    {/*<button className={st.closeButton} onClick={() => generateSQLFile(t, tables, relations, "TestProject")}>Make SQL</button>*/}
                    {/*<input*/}
                    {/*    id="file-loader"*/}
                    {/*    type="file"*/}
                    {/*    className={st.importButton}*/}
                    {/*    accept=".json"*/}
                    {/*    onChange={(event) => importJSONFile(t, event.target.files[0], importData)}*/}
                    {/*/>*/}
                    <button className={cn(st.saveButton, {[st.saveButtonDisabled]: !isTableJsonValid || !isRelationsJsonValid} )} onClick={saveData}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default DevelopmentConsole;
