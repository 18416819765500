import {createContext, useContext, useLayoutEffect, useState} from "react";
import {SettingsTypes} from "../../enum/SettingsTypes";
import {createNotification, NotificationStatus} from "../../Notification";
import {Translations} from "../../../translations/Translations";

const SettingsContext = createContext();

export const SettingsProvider  = ({ children }) => {

    const [settings, setSettings] = useState({});
    const [settingsBuffer, setSettingsBuffer] = useState({});
    const [t, setT] = useState({});

    useLayoutEffect(() => {
        try {
            const lsSettings = JSON.parse(localStorage.getItem('app-settings')) ?? {};
            for (let settingGroup of allSettingsTemplate) {
                for (let setting of settingGroup.settings) {
                    if (!lsSettings[setting.sysName]) {
                        lsSettings[setting.sysName] = findDefaultValue(setting);
                    }
                }
            }
            localStorage.setItem('app-settings', JSON.stringify(lsSettings));
            setT(Translations[lsSettings?.language] ? Translations[lsSettings?.language] : Translations.en);
            document.documentElement.setAttribute('app-theme', lsSettings?.theme);
            setSettings(lsSettings ? lsSettings : {});
            setSettingsBuffer(lsSettings)
        } catch (e) {
            createNotification(NotificationStatus.ERROR, "Error while loading settings")
            setSettings({})
        }
    }, [])
    
    const findDefaultValue = (setting) => {
        if (setting.type === SettingsTypes.SELECT) {
            let value = setting.options.find((o) => o.default);
            return value?.value ? value?.value : value?.sysName;
        }
    }
    
    const saveSettings = () => {
        for (let settingGroup of allSettingsTemplate) {
            for (let setting of settingGroup.settings) {
                if (!settingsBuffer[setting.sysName]) {
                    settingsBuffer[setting.sysName] = findDefaultValue(setting);
                }
            }
        }
        setSettings({...settingsBuffer});
        localStorage.setItem('app-settings', JSON.stringify(settingsBuffer));
        document.documentElement.setAttribute('app-theme', settingsBuffer?.theme);
        setT(Translations[settingsBuffer?.language] ? Translations[settingsBuffer?.language] : Translations.en);
    }
    const updateSettings = (settingName, settingValue) => {
        settingsBuffer[settingName] = settingValue;
        setSettingsBuffer({...settingsBuffer});
    }
    
    const [selectedSetting, setSelectedSetting] = useState("general")
    
    const allSettingsTemplate = [
        {
            sysName: "general",
            type: "app",
            id: 1,
            settings: [
                {
                    id: 10,
                    sysName: "theme",
                    disabled: false,
                    type: SettingsTypes.SELECT,
                    options: [
                        {
                            sysName: "light",
                            default: true
                        },
                        {
                            sysName: "dark",
                        }
                    ],
                },
                {
                    id: 11,
                    sysName: "language",
                    disabled: false,
                    type: SettingsTypes.SELECT,
                    options: [
                        {
                            sysName: "en",
                            default: true
                        },
                        {
                            sysName: "ru",
                        }
                    ],
                }
            ]
        },
        {
            sysName: "forDevelopers",
            id: 2,
            type: "editor",
            editorOnly: true,
            settings: [
                {
                    id: 20,
                    sysName: "autosaveTimeout",
                    type: SettingsTypes.SELECT,
                    disabled: false,
                    options: [
                        {
                            sysName: "1m",
                            value: "1"
                        },
                        {
                            sysName: "5m",
                            value: "5"
                        },
                        {
                            sysName: "15m",
                            value: "15",
                            default: true
                        },
                        {
                            sysName: "30m",
                            value: "30"
                        },
                        {
                            sysName: "1h",
                            value: "60"
                        }
                    ],
                }
            ]
        }
    ]
    
    const generalSettingsTemplate = allSettingsTemplate.filter((s) => !s?.editorOnly);

    return (
        <SettingsContext.Provider value={{
            allSettingsTemplate,
            generalSettingsTemplate,
            selectedSetting,
            setSelectedSetting,
            settingsBuffer,
            updateSettings,
            settings,
            saveSettings, 
            t
        }}>
            {children}
        </SettingsContext.Provider>
    );
}

export const useSettings = () => {
    return useContext(SettingsContext);
};