import html2canvas from "html2canvas";

export const exportToPNG = async (setIsLoading) => {
    setIsLoading("png")
    const canvasElement = document.getElementById('myCanvas'); // замените на ваш ID
    html2canvas(canvasElement, {
        scale: 4 // Увеличьте масштаб для повышения качества
    }).then(canvas => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png', 1.0); // Убедитесь, что качество 1.0 для максимального качества
        link.download = 'database-schema.png';
        link.click();
        setIsLoading("")
    });
};

