import st from "./Area.module.css";
import {version} from "../../service/consts";
import cn from "classnames";
import React from "react";
import {WorkBenchMode} from "../../service/enum/WorkBenchMode";

const Area = ({area, selectedAreaId, selectArea, areaId, isInitialRender, startResizingArea, switchWorkBenchMode}) => {

    const processDoubleClick = () => {
        switchWorkBenchMode(WorkBenchMode.AREAS)
        setTimeout(() => {
            const inputElement = document.getElementById(areaId + "areaName");
            inputElement.focus();
        }, 10)
    }
    
    return (
        <g key={areaId}>
            <rect
                className={cn({[st.area]: isInitialRender})}
                onMouseDown={(e) => selectArea(e, areaId)}
                key={areaId}
                x={area.x1}
                y={area.y1}
                width={Math.abs(area.x2 - area.x1)}
                height={Math.abs(area.y2 - area.y1)}
                fill={area.color}
                fillOpacity="var(--area-bg-color-opacity)"
                rx="6"
                ry="6"
                stroke={selectedAreaId === areaId ? "var(--selection-color)" : (area.color + "7E")}
                strokeWidth={2}
                strokeDasharray={selectedAreaId === areaId ? `${15}, ${10}` : ""}
            />
            <text
                onDoubleClick={processDoubleClick}
                x={area.x1 + 10} y={area.y1 + 15} dominantBaseline="middle" textAnchor="start"
                className={st.nonSelectable}
                style={{fontSize: 14, fill: "var(--text-regular-color)"}}>{area.name}</text>

            {selectedAreaId === areaId &&
                <>
                    {/* верхняя граница */}
                    <circle cx={area.x1 + 1} cy={area.y1 + 1} r="6"
                            fill={"var(--selection-color)"} className={st.fieldPoint}
                            onMouseDown={(e) => startResizingArea("leftTop")}/>
                    <circle
                        cx={area.x1 + Math.abs(area.x2 - area.x1) + 1}
                        cy={area.y1 + 1} r="6" fill={"var(--selection-color)"}
                        className={st.fieldPoint2} onMouseDown={(e) => startResizingArea("rightTop")}/>

                    {/* посередине по горизонтали */}
                    {/*<circle cx={area.x1 + 1} cy={area.y1 + Math.abs(area.y2 - area.y1) / 2} r="6" fill={"var(--selection-color)"} className={st.fieldPoint}/>*/}
                    {/*<circle cx={area.x1 + Math.abs(area.x2 - area.x1) + 1} cy={area.y1 + Math.abs(area.y2 - area.y1) / 2} r="6" fill={"var(--selection-color)"} className={st.fieldPoint}/>*/}

                    {/* нижняяграница */}
                    <circle cx={area.x1 + 1}
                            cy={area.y1 + Math.abs(area.y2 - area.y1) + 1}
                            r="6" fill={"var(--selection-color)"}
                            className={st.fieldPoint2} onMouseDown={(e) => startResizingArea("leftBottom")}/>
                    <circle
                        cx={area.x1 + Math.abs(area.x2 - area.x1) + 1}
                        cy={area.y1 + Math.abs(area.y2 - area.y1) + 1}
                        r="6" fill={"var(--selection-color)"} className={st.fieldPoint}
                        onMouseDown={(e) => startResizingArea("rightBottom")}/>
                </>

            }

        </g>

    )
}

export default Area;
