import {RESERVED_KEYWORDS_POSTGRESQL} from "./Export/PostgreSQL/ReservedKeyWordsPostgreSQL";

export const getRandomColor = (objLen) => {
    const contrast = objLen ? objLen % 3 : 0;
    let colorsCoif = [];
    for (let i = 0; i < 3; i++) {
        colorsCoif[i] = Math.floor(Math.random() * 255) ;
        if (contrast === i) colorsCoif[i] = 255;
    }
    return `#${colorsCoif[0].toString(16).padStart(2, '0')}${colorsCoif[1].toString(16).padStart(2, '0')}${colorsCoif[2].toString(16).padStart(2, '0')}`;
}

export const copyObject = (object) => {
  return JSON.parse(JSON.stringify(object));
}

export const roundToStep = (step, value) => {
    if (step === 0) return value;
    return step * Math.round(value / step);
}

// Возвращает из связей другую таблицу и поле
export const getOtherTableAndFieldFromRelation = (relation, tableId, fieldId) => {
    if (relation?.table1 === tableId && relation?.table1Field === fieldId) {
        return {tableId: relation?.table2, fieldId: relation?.table2Field}
    } else if (relation?.table2 === tableId && relation?.table2Field === fieldId) {
        return {tableId: relation?.table1, fieldId: relation?.table1Field}
    }
}

export const checkUniqAndValidPostgresIdentifier = (tables, checkedTableId, t) => {
    const table = tables[checkedTableId];
    for (let tableId in tables) {
        if (tables[tableId].name === table.name && tableId !== checkedTableId) return t?.errors.tableNameUnique;
    }
    return checkValidPostgresIdentifier(table, t)
}

export const checkValidPostgresIdentifier = (value, t) => {
    if (value.name === "") return t?.errors.noFieldName;
    if (value?.type === "") return t?.errors.noFieldType;
    // Проверка длины в байтах (макс. 63)
    const encoder = new TextEncoder();
    const bytes = encoder.encode(value.name);
    if (bytes.length > 63) return t?.errors.maxFieldNameLengthExceeded;
    // Проверка формата имени
    if (/^\d/.test(value.name)) return t?.errors.numberAtTheBeginning;
    if (!/^[a-zA-Zа-яА-Я_][a-zA-Zа-яА-Я0-9_]*$/.test(value.name)) return t?.errors.forbiddenCharacters;
    if (RESERVED_KEYWORDS_POSTGRESQL.has(value.name.toLowerCase())) return t?.errors.reservedName;
    return false;
}

export const formatString = (template, ...args) => {
    return template.replace(/\{(\d+)\}/g, (_, index) => args[index] || '');
}

