import React from 'react';
import cn from "classnames";
import st from "./../components/HeaderRight/HeaderRight.module.css";


const Burger = () => {
    return (
        <div className={st.burger_block}>
            <svg className={cn(st.burger, {[st.burger_opened]: false})} width="24" height="24"
                 viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line className={st.burgerFirstLine} x1="2" y1="5" x2="22" y2="5" stroke={'var(--icon-color)'} strokeWidth="2"
                      strokeLinecap="round"/>
                <line className={st.burgerSecondLine} x1="2" y1="12" x2="22" y2="12" stroke={'var(--icon-color)'} strokeWidth="2"
                      strokeLinecap="round"/>
                <line className={st.burgerThirdLine} x1="2" y1="19" x2="22" y2="19" stroke={'var(--icon-color)'} strokeWidth="2"
                      strokeLinecap="round"/>
            </svg>
        </div>

    );
};

export default Burger;