import React from 'react';
import {TABLE_WIDTH} from "../../service/consts";

const TableBottomBorder = ({deltaY}) => {
    return (
        <svg width={TABLE_WIDTH} height="10" x="5" y={43 + 20 * deltaY}>
            <rect x="0" y="-5" width={TABLE_WIDTH} height="10" fill="var(--background-menu-color)" style={{transition: 'all ease-in-out 300ms'}} rx="6" ry="6"/>
        </svg>
    );
};

export default TableBottomBorder;