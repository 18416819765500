import React, {useEffect, useState} from 'react';
import st from "../../pages/Editor/Editor.module.css";
import {TABLE_WIDTH} from "../../service/consts";
import {WorkBenchMode} from "../../service/enum/WorkBenchMode";
import Comment from "../../resources/Comment";

const TableHeader= ({text, tableId, fieldErrorById, switchWorkBenchMode, comment}) => {
    
    const [value, setValue] = useState("");

    const truncateText = (text, maxLength) => {
        // Если длина текста больше максимальной, обрезать и добавить "..."
        if (text.length > maxLength) {
            text = text.substring(0, maxLength) + '…';
        }
        setValue(text)
        // Возвращаем текст как есть, если он в пределах допустимой длины
    }

    const processDoubleClick = () => {
        switchWorkBenchMode(WorkBenchMode.TABLES)
        setTimeout(() => {
            const inputElement = document.getElementById(tableId);
            inputElement.focus();
        }, 10)
    }
    
    const processDoubleClickComment = (e) => {
        e.stopPropagation()
        switchWorkBenchMode(WorkBenchMode.TABLES)
        setTimeout(() => {
            const table = document.getElementById(tableId + "optionButton")
            table.click()
            setTimeout(() => {
                const textArea = document.getElementById(tableId + "tableCommentArea")
                textArea.focus()
            }, [10])
        }, [10])
    }
    
    useEffect(() => {
        truncateText(text, 24)
    }, [text])
    
    return (
        <svg width={TABLE_WIDTH} height="37" x="5" y="7" onDoubleClick={processDoubleClick}>
            <rect  width={TABLE_WIDTH} height="36" fill={fieldErrorById[tableId]?.tableError ? "var(--workbench-field-error-bg)" : "var(--white-blue-bg-color)"} />
            {comment &&
                <g x="0" y="0" style={{transform: "translate(calc(100% - 22px), 7px)"}} className={st.commentButton} onDoubleClick={processDoubleClickComment}>
                    <Comment width={16} height={16} bgColor={fieldErrorById[tableId]?.tableError ? "var(--workbench-field-error-bg)" : "var(--white-blue-bg-color)"}/>
                </g>
            }
            <text x="0" y="0" dominantBaseline="middle" textAnchor="middle" fill="var(--text-code-title-color)" className={st.nonSelectable} style={{transform: "translate(50%, 55%)", fontSize: 13, fontWeight: "Bold", textOverflow: "ellipsis"}}>{value}</text>
            <rect y="36" width={TABLE_WIDTH} height="1" fill="var(--table-divider-color)" />
        </svg>
    );
};

export default TableHeader;