import {combineReducers} from "redux";
import tablesReducer from "./tablesReducer";
import relationsReducer from "./relationsReducer";
import areasReducer from "./areasReducer";

const rootReducer = combineReducers({
    tablesRedux: tablesReducer, // состояние таблиц будет доступно как state.tables
    relationsRedux: relationsReducer,
    areasRedux: areasReducer
})

export default rootReducer;