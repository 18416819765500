import React, {useEffect, useState} from 'react';
import cn from "classnames";
import st from "./Select.module.css";
import Arrow from "../../resources/Arrow";

const Select = ({ currentValue, options, onChange, disabled, t }) => {
    const [isOpened, setIsOpened] = useState(false);
    let value = options.find((option) => option.sysName === currentValue)?.value; // если есть value
    return (
        <div className={cn(st.selectBlock, {[st.selectBlockOpened]: isOpened}, {[st.selectBlockDisabled]: disabled})} onClick={() => disabled ? null : setIsOpened(!isOpened)}>
            <span>{t?.[currentValue] ? t?.[currentValue] : currentValue}</span>
            <div className={st.selectArrow}>
                <Arrow isOpened={isOpened} color={"var(--icon-color)"}/>
            </div>
            {isOpened &&
                <div className={st.selectOptionsBlock}>
                    {options.map((option, index) => (
                        <div key={index} className={cn(st.selectOption, {[st.selectOptionSelected]: option.sysName === currentValue})} onClick={() => onChange(option?.value ? option.value : option.sysName)}>
                            <span>{t?.[option.sysName] ?? option.sysName}</span>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

export default Select;