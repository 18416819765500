import {createNotification, NotificationStatus} from "../../../Notification";
import {getOtherTableAndFieldFromRelation} from "../../utils";
import {hasFKRelation} from "../../RelationUtils";
import {RelationTypes} from "../../../enum/RelationTypes";

let tableLevel = {}
let checkedTables = {}
let excludeTables = {}
export const generateSQLFile = (t, tables, relations, projectName, excludeTablesNew = {}) => {
    tableLevel = {};
    checkedTables = {};
    excludeTables = excludeTablesNew;
    let level = 0;
    let projectToExport = "-- created by dbdesingstudio.ru at " + new Date() + "\n";
    for (const tableId in tables) {
        if (checkedTables[tableId] === undefined && !excludeTables[tableId]) {
            getRelationDelta(tables, relations, tableId, level);
        }
    }
    const sortedTabledByGenerationOrder = Object.keys(tableLevel).sort((a, b) => tableLevel[a] - tableLevel[b]);
    
    for (const tableId of sortedTabledByGenerationOrder) {
        projectToExport += getTableSQL(tables, relations, tableId);
    }

    const blob = new Blob([projectToExport], {type: "application/json"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${projectName}_PostgreSQL.sql`; // Название файла
    link.click(); // Инициирует скачивание
    URL.revokeObjectURL(url); // Очистка ресурса после загрузки
    createNotification(NotificationStatus.SUCCESS, t?.success.sqlExport)
}

// метод строит дерево через коэффициенты между полями
const getRelationDelta = (tables, relations, tableId, level) => {
    const table = tables[tableId];
    for (const fieldId in table.fields) {
        const field = table.fields[fieldId];
        if (field.relations.length === 0) {
            checkedTables = {...checkedTables, [tableId]: true}
            if (!excludeTables[tableId]) {
                tableLevel = {...tableLevel, [tableId]: level}
            }
            continue;
        }
        for (const relationId of field.relations) {
            const relation = relations[relationId];
            if (!relation) continue;
            const otherTableAndField = getOtherTableAndFieldFromRelation(relation, tableId, fieldId);
            checkedTables = {...checkedTables, [tableId]: true}
            if (!excludeTables[tableId]) {
                tableLevel = {...tableLevel, [tableId]: level}
            }
            if (checkedTables[otherTableAndField.tableId] === undefined) {
                let newLevel = level;
                if (hasFKRelation(tables, tableId, fieldId, otherTableAndField.fieldId)) { // если FK у текущей таблицы, то сначала надо создать связываемую таблицу
                    newLevel -= 1
                } else {
                    newLevel += 1
                }
                if (tableId !== otherTableAndField.tableId) {
                    getRelationDelta(tables, relations, otherTableAndField.tableId, newLevel)
                }
            }
        }
    }
}

const getTableSQL = (tables, relations, tableId) => {
    const table = tables[tableId];
    let tableSQL = '\nCREATE TABLE ' + table.name + ' (\n';
    let fieldsSQL = '';
    // let constraintsSQL = '';
    for (const fieldId in table.fields) {
        if (fieldsSQL) fieldsSQL += ',\n';
        const field = table.fields[fieldId];
        fieldsSQL += `    ${field.name} ${field.type.toUpperCase()}${(field.constraints.length) ? " " + field.constraints.map(constraint => constraint.toUpperCase()).join(" ") : ""}`;
        for (const relationId of field.relations) {
            const relation = relations[relationId];
            const otherTableAndField = getOtherTableAndFieldFromRelation(relation, tableId, fieldId);
            let otherTable = tables[otherTableAndField.tableId];
            let otherField = otherTable.fields[otherTableAndField.fieldId];
            // если текущее поле на стороне Many или связь OneToOne и поле имеет FK
            if (
                (relation.table1 === tableId && relation.relationType === RelationTypes.MANY_TO_ONE) ||
                (relation.table2 === tableId && relation.relationType === RelationTypes.ONE_TO_MANY) ||
                (relation.relationType === RelationTypes.ONE_TO_ONE && hasFKRelation(tables, tableId, fieldId, otherTableAndField.fieldId))
            ) {
                fieldsSQL += ` REFERENCES ${otherTable.name}(${otherField.name})`; 
            }
        }
    }
    tableSQL += fieldsSQL + '\n);';
    if (tables[tableId]?.comment) {
        tableSQL += `\n\nCOMMENT ON TABLE ${table.name} IS '${tables[tableId]?.comment}';\n`;
    }
    return tableSQL;
}