import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";

export const exportToPDF = async (setIsLoading) => {
    setIsLoading("pdf")
    // 1. Получаем SVG-элемент
    const svgElement = document.getElementById('myCanvas'); // замените на ваш ID

    // 2. Конвертируем SVG в canvas
    const canvas = await html2canvas(svgElement, {
        scale: 4, // Повышаем качество
        useCORS: true, // Для корректного отображения внешних ресурсов
        allowTaint: true,
    });

    // 3. Создаем PDF
    const pdf = new jsPDF('landscape'); // 'portrait' для вертикальной ориентации
    const imgData = canvas.toDataURL('image/png');

    // 4. Добавляем изображение в PDF
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);

    pdf.addImage(
        imgData,
        'PNG',
        0,
        0,
        imgWidth * ratio,
        imgHeight * ratio
    );

    // 5. Сохраняем PDF
    pdf.save('database-schema.pdf');
    setIsLoading("")
};