import React from 'react';

const SwitchIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 3L23 7L19 11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 13V11C5 9.93913 5.42143 8.92172 6.17157 8.17157C6.92172 7.42143 7.93913 7 9 7H23" stroke="white"
                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 25L5 21L9 17" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M23 15V17C23 18.0609 22.5786 19.0783 21.8284 19.8284C21.0783 20.5786 20.0609 21 19 21H5"
                  stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
};

export default SwitchIcon;