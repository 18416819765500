import React, {useEffect, useState} from 'react';
import cn from "classnames";
import st from "./SettingProperty.module.css";
import {SettingsTypes} from "../../../../service/enum/SettingsTypes";
import Select from "../../../Select/Select";

const SettingProperty = ({ setting, currentValue, onChange, t }) => {
    
    const changeProperty = (value) => {
        onChange(setting.sysName, value)
    }
    return (
        <div className={st.settingPropertyBlock}>
            {setting.type === SettingsTypes.SELECT &&
                <div className={st.selectBlock}>
                    <span>{t?.[setting.sysName]}</span>
                    <Select currentValue={setting.options.find(o => o?.value === currentValue)?.sysName ?? currentValue} options={setting.options} disabled={setting.disabled} onChange={changeProperty} t={t}/>
                </div>
                
            }
        </div>
    );
};

export default SettingProperty;