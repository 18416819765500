import React, {useEffect, useRef, useState} from 'react';
import st from "../../pages/Editor/Editor.module.css";
import st2 from "./TableParts.module.css";
import MiniKey from "../../resources/MiniKey";
import {TABLE_WIDTH} from "../../service/consts";
import {Constraints} from "../../service/enum/Constraints";
import MiniUnique from "../../resources/MiniUnique";
import cn from "classnames";
import {WorkBenchMode} from "../../service/enum/WorkBenchMode";

const TableField = ({deltaY, field, tableId, fieldId, selectRelationField, isPrimaryKey, isForeignKey, isUnique, isNotNull, setOpenedTableIds, fieldErrorById, switchWorkBenchMode}) => {
    const fieldNameTextRef = useRef();
    const fieldTypeTextRef = useRef();
    const padding = 10;
    const MAX_LENGTH = 20;

    const [value, setValue] = useState("");

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            text = text.substring(0, maxLength) + '…';
        }
        setValue(text)
    }
    
    const processDoubleClick = () => {
        switchWorkBenchMode(WorkBenchMode.TABLES)
        setTimeout(() => {
            setOpenedTableIds(openedTableIds => (new Set([...openedTableIds, tableId])))
            setTimeout(() => {
                const inputElement = document.getElementById(fieldId);
                inputElement.focus();
            }, 100)
        }, 100)
    }

    useEffect(() => {
        truncateText(field.name, MAX_LENGTH)
    }, [field.name])
    
    return (
        <svg onDoubleClick={processDoubleClick} width={TABLE_WIDTH} height="21" x="5" y={43 + 20 * deltaY}>
            <g className={st2.fieldGroup} onMouseUp={(e) => selectRelationField(e, tableId, fieldId)}>
                <rect x="0" y="1" width={TABLE_WIDTH} height="20" className={cn(st2.field, {[st2.fieldWithError]: fieldErrorById[fieldId]?.fieldError})}/>
                {isPrimaryKey && 
                    <g style={{transform: `translate(${padding}px, 22%)`}}>
                        <MiniKey color={'#3DA71D'}/>
                    </g>
                }
                {isUnique &&
                    <g style={{transform: `translate(${padding}px, 22%)`}}>
                        <MiniUnique color={isNotNull ? '#3DA71D' : '#3283cd'} style={{height: 20, width: 20}}/>
                    </g>
                }
                {isForeignKey &&
                    <g style={{transform: `translate(${(isPrimaryKey || isUnique) ? padding + 15 : padding}px, 22%)`}}>
                        <MiniKey color={'#3283cd'}/>
                    </g>
                }
                <text ref={fieldNameTextRef} x="0" y="0" dominantBaseline="middle" textAnchor="start" className={st.nonSelectable} style={{transform: `translate(${padding + ((isPrimaryKey || isUnique) && isForeignKey ? 30 : ((isPrimaryKey || isUnique || isForeignKey) ? 15 : 0))}px, 55%)`, fontSize: 11, fill: "var(--text-code-color)"}}>{value}</text>
                <text ref={fieldTypeTextRef}
                          x="0" y="0" dominantBaseline="middle" textAnchor="end" className={st.nonSelectable} style={{transform: `translate(calc(100% - ${padding}px), 55%)`, fontSize: 11, fill: "var(--text-code-color)"}}>{field.type}{field?.constraints.includes(Constraints.NOT_NULL) ? '!' : ''}</text>
            </g>
        </svg>
    );
};

export default TableField;