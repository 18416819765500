import st from "./WorkBench.module.css";
import {useEffect, useState} from "react";
import cn from "classnames";
import Options from "../../resources/Options";
import TableArrow from "../../resources/TableArrow";
import ArrowMenu from "../../resources/ArrowMenu";
import { v4 as uuidv4 } from 'uuid';
import Plus from "../../resources/Plus";
import {useDispatch, useSelector} from "react-redux";
import {updateAreasRedux, updateTablesRedux} from "../../redux/actions/appActions";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {WORKBENCH_LOGS_ENABLED} from "../../service/consts";
import TableDoubleArrow from "../../resources/TableDoubleArrow";
import {copyObject} from "../../service/utils/utils";
import {Translations} from "../../translations/Translations";
import {WorkBenchMode} from "../../service/enum/WorkBenchMode";
import Trash from "../../resources/Trash";
import {Chrome} from "@uiw/react-color";
import Palette from "../../resources/Palette";
import {Constraints} from "../../service/enum/Constraints";
import Unique from "../../resources/Unique";
import Key from "../../resources/Key";
import NN from "../../resources/NN";
import Wrench from "../../resources/Wrench";
import {FieldTypes} from "../../service/enum/FielsTypes";
import {useSettings} from "../../service/utils/hooks/useSettings";

const WorkBench = ({fieldErrorById, createObject, selectTable, selectedTableId, selectArea, selectedAreaId, selectedTablesId, updateTables, updateAreas, deleteArea, deleteTable, deleteField, updateFieldType, openedTableIds, setOpenedTableIds, switchPrimaryKey, switchConstraint, isOpenedWorkBenchMenu, setIsOpenedWorkBenchMenu, switchPrimaryKeyAndUnique, setConstraint, closeAllTables, openAllTables, workBenchMode, switchWorkBenchMode}) => {

    const dispatch = useDispatch();
    const tables = useSelector((state) => state.tablesRedux);
    const setTables = (tables) => {
        log("Изменение данных redux из workbench")
        dispatch(updateTablesRedux(tables))
    }

    const areas = useSelector((state) => state.areasRedux);
    const setAreas = (areas) => {
        log("Изменение данных redux из workbench")
        dispatch(updateAreasRedux(areas))
    }
    
    

    const {t} = useSettings();
    
    const log = (message) => {
        if (WORKBENCH_LOGS_ENABLED) {
            console.log("[WORKBENCH] " + message)
        }
    }
    
    const [tableMenu, setTableMenu] = useState({
        top: 0,
        left: 0,
        tableId: null,
        isChangingColor: false
    });
    const [fieldMenu, setFieldMenu] = useState({
        top: 0,
        left: 0,
        tableId: null,
        fieldId: null
    });
    const [areaMenu, setAreaMenu] = useState({
        top: 0,
        left: 0,
        areaId: null,
        isChangingColor: false
    });
    const [projectId, setProjectId] = useState(1)
    let [colors, setColors] = useState([]) // цвета в памяти браузера
    const [startName, setStartName] = useState("");
    const [startColor, setStartColor] = useState("");
    const [colorChanged, setColorChanged] = useState(false);

    const updateTableColor = (newColor, mode, oldColor = null) => {
        console.log(mode)
        if (selectedTableId) {
            if (mode === "changingColor") {
                if (!colorChanged) {
                    setStartColor(oldColor)
                    setColorChanged(true)
                }
                tables[selectedTableId].color = newColor;
                updateTables(tables, "updateTableColor", false)
            } else if (mode === "completeChanging") {
                if (colorChanged && startColor !== newColor) {
                    tables[selectedTableId].color = newColor;
                    updateTables(tables, "updateTableColor", true)
                }
                setStartColor("")
                setColorChanged(false)
            } else if (mode === "textChanging" && startColor !== newColor) {
                if (!colorChanged) {
                    setStartColor(newColor)
                    setColorChanged(true)
                }
                tables[selectedTableId].color = newColor;
                updateTables(tables, "updateTableColor", false)
            } else if (mode === "textComplete" && startColor !== newColor) {
                tables[selectedTableId].color = newColor;
                updateTables(tables, "updateTableColor", true)
                setStartColor("")
                setColorChanged(false)
            }
        }
    }

    const updateAreaColor = (newColor, mode, oldColor = null) => {
        if (selectedAreaId) {
            if (mode === "changingColor") {
                if (!colorChanged) {
                    setStartColor(oldColor)
                    setColorChanged(true)
                }
                areas[selectedAreaId].color = newColor;
                updateAreas(areas, "updateAreaColor", false)
            } else if (mode === "completeChanging") {
                if (colorChanged && startColor !== newColor) {
                    areas[selectedAreaId].color = newColor;
                    updateAreas(areas, "updateAreaColor", true)
                }
                setStartColor("")
                setColorChanged(false)
            } else if (mode === "textChanging" && startColor !== newColor) {
                if (!colorChanged) {
                    setStartColor(newColor)
                    setColorChanged(true)
                }
                areas[selectedAreaId].color = newColor;
                updateAreas(areas, "updateAreaColor", false)
            } else if (mode === "textComplete" && startColor !== newColor) {
                areas[selectedAreaId].color = newColor;
                updateAreas(areas, "updateAreaColor", true)
                setStartColor("")
                setColorChanged(false)
            }
        }
    }
    
    const selectSavedColor = (objects, selectedObjectId, updateMethod, color) => {
        if (objects[selectedObjectId].color !== color) {
            objects[selectedObjectId].color = color;
            updateMethod(objects, "selectSavedColor")
        }
    }
    
    const switchWorkBench = () => {
        hideTableMenu()
        setFieldMenu({})
        setAreaMenu({})
        setIsOpenedWorkBenchMenu(isOpenedWorkBenchMenu => !isOpenedWorkBenchMenu);
    }
    
    const hideTableMenu = () => {
        if (startName !== (tables[tableMenu.tableId]?.comment ?? "") && tables[selectedTableId]) {
            updateTables(tables, "updateTableComment")
        }
        setStartName("")
        setTableMenu({})
    }
    
    const selectObject = (id, mode) => {
        if (mode === "table") {
            selectTable(null, id)
        } else if (mode === "area") {
            selectArea(null, id)
        }
    }

    const copyTable = (e, tableId) => {
        e.stopPropagation()
        createObject({...tables[tableId], name: tables[tableId].name + "_copy"});
    }

    const switchTable = (tableId) => {
        if (openedTableIds.has(tableId)) {
            openedTableIds.delete(tableId)
        } else {
            openedTableIds.add(tableId)
        }
        setOpenedTableIds(new Set([...openedTableIds]))
        setFieldMenu({})
    }

    const openTable = (tableId) => {
        if (!openedTableIds.has(tableId)) {
            openedTableIds.add(tableId)
        }
    }
    
    const switchTableMenu = (e, tableId) => {
        if (tableMenu.tableId && tableMenu.tableId === tableId) {
            hideTableMenu()
        } else  {
            setTableMenu({
                top: e.target.getBoundingClientRect().y - 10,
                left: e.target.getBoundingClientRect().right + 15,
                tableId: tableId
            })
            setFieldMenu({})
        }
    }

    const switchAreaMenu = (e, areaId) => {
        if (areaMenu.areaId && areaMenu.areaId === areaId) {
            setAreaMenu({})
        } else  {
            setAreaMenu({
                top: e.target.getBoundingClientRect().y,
                left: e.target.getBoundingClientRect().right + 15,
                areaId: areaId,
                isChangingColor: true
            })
        }
    }

    const switchFieldMenu = (e, tableId, fieldId) => {
        if (fieldMenu.tableId === tableId && fieldMenu.fieldId === fieldId) {
            setFieldMenu({})
        } else  {
            setFieldMenu({
                top: e.target.getBoundingClientRect().y - 4,
                left: e.target.getBoundingClientRect().right + 15,
                tableId: tableId,
                fieldId: fieldId
            })
            selectObject(tableId, "table")
            hideTableMenu()
        }
    }
    
    const createField = (tableId) => {
        tables[tableId].fields[uuidv4()] = {
            name: "",
            type: "",
            relations: [],
            constraints: [],
            fkRelations: []
        }
        updateTables(tables, "createField")
        openTable(tableId)
    }


    const focusName = (value) => {
        setStartName(value)
    }

    const changeTableName = (event, tableId, value) => {
        tables[tableId].name = value
        setTables({...tables})
    }
    const changeTableComment = (event, tableId, value) => {
        tables[tableId].comment = value
        setTables({...tables})
    }

    const blurTableComment = (event, tableId, value) => {
        if (tables[tableId].comment !== startName) {
            tables[tableId].comment = value
            updateTables(copyObject(tables), "blurTableComment") // копируем данные, чтобы предотвратить равенство по ссылке между редаксом и tables
            setStartName("");
        }
    }
    const changeAreaName = (event, areaId, value) => {
        areas[areaId].name = value
        setAreas({...areas})
    }
    const blurTableName = (event, tableId, value) => {
        if (tables[tableId].name !== startName) {
            tables[tableId].name = value
            updateTables(copyObject(tables), "blurTableName") // копируем данные, чтобы предотвратить равенство по ссылке между редаксом и tables
            setStartName("");
        }
    }
    
    const changeFieldName = (event, tableId, fieldId, value) => {
        tables[tableId].fields[fieldId].name = value
        setTables({...tables})
    }

    const changeCustomType = (event, tableId, fieldId, value) => {
        tables[tableId].fields[fieldId].type = value
        setTables({...tables})
    }
    
    const blurFieldName = (event, tableId, fieldId, value) => {
        if (tables[tableId].fields[fieldId].name !== startName) {
            tables[tableId].fields[fieldId].name = value
            updateTables(copyObject(tables), "blurFieldName") // копируем данные, чтобы предотвратить равенство по ссылке между редаксом и tables
            setStartName("");
        }
    }

    const blurAreaName = (event, areaId, value) => {
        if (areas[areaId].name !== startName) {
            areas[areaId].name = value
            updateAreas(copyObject(areas), "blurAreaName") // копируем данные, чтобы предотвратить равенство по ссылке между редаксом и tables
            setStartName("");
        }
    }

    const blurCustomType = (event, tableId, fieldId, value) => {
        if (tables[tableId].fields[fieldId].type !== startName) {
            updateFieldType(tableId, fieldId, value)
        }
    }

    const blurCustomTypeSelect = (event, tableId, fieldId, value) => {
        console.log(tables[tableId].fields[fieldId].type)
        if (tables[tableId].fields[fieldId].type !== value) {
            updateFieldType(tableId, fieldId, value)
        }
    }

    const switchColorPalette = () => {
        setTableMenu({...tableMenu, isChangingColor: !tableMenu.isChangingColor})
    }
    
    const saveColor = (objects, selectedObjectId) => {
        colors.reverse().push(objects[selectedObjectId].color)
        localStorage.setItem("savedColors" + projectId, JSON.stringify(colors));
        setColors([...colors].reverse().slice(0, 8))
    }
    
    useEffect(() => {
        const savedColors = localStorage.getItem('savedColors' + projectId);
        if (JSON.parse(savedColors)) {
            setColors([...JSON.parse(savedColors)].reverse())
        }
    }, [])
    
    // выключение меню при смене выбранной таблицы
    useEffect(() => {
        if (selectedTableId === null) {
            hideTableMenu()
            setFieldMenu({})
        } else if (selectedTableId !== tableMenu.tableId) {
            hideTableMenu()
        }
        // срабатывает, когда нажали на ключ, просетили fieldMenu.tableId
        // а потом происходит обновление selectedTableId 
        if (selectedTableId !== fieldMenu.tableId) { 
            setFieldMenu({})
        }
    },[selectedTableId])

    useEffect(() => {
        if (selectedAreaId !== areaMenu.areaId) {
            setAreaMenu({})
        }
    },[selectedAreaId])

    const onDragEnd = (result) => {
        const { destination, source, draggableId, type } = result;
        if (!destination) {
            return;
        }
        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            return;
        }
        let tableId = draggableId.slice(6);
        if (type === "FIELD") {
            tableId = tableId.slice(37);
        }
        if (type === 'AREA') {
            // Получаем массив areaIds в текущем порядке
            const areaIds = Object.keys(areas);
            // Перемещаем элемент в новый индекс
            const [movedAreaId] = areaIds.splice(source.index, 1);
            areaIds.splice(destination.index, 0, movedAreaId);
            // Создаем новый объект tables с обновленным порядком
            const updatedAreas = {};
            areaIds.forEach((areaId) => {
                updatedAreas[areaId] = areas[areaId];
            });
            setAreas(updatedAreas);
            updateAreas(updatedAreas, "onDragAreaEnd")
        } else if (type === 'TABLE') {
            // Получаем массив tableIds в текущем порядке
            const tableIds = Object.keys(tables);
            // Перемещаем элемент в новый индекс
            const [movedTableId] = tableIds.splice(source.index, 1);
            tableIds.splice(destination.index, 0, movedTableId);
            // Создаем новый объект tables с обновленным порядком
            const updatedTables = {};
            tableIds.forEach((tableId) => {
                updatedTables[tableId] = tables[tableId];
            });
            // Обновляем состояние таблиц
            setTables(updatedTables);
            updateTables(updatedTables, "onDragTableEnd")
        } else if (type === 'FIELD') {
            // Получаем массив fieldIds в текущем порядке
            const fieldIds = Object.keys(tables[tableId].fields);
            // Перемещаем элемент в новый индекс
            const [movedFieldId] = fieldIds.splice(source.index, 1);
            fieldIds.splice(destination.index, 0, movedFieldId);
            // Создаем новый объект fields с обновленным порядком
            const updatedFields = {};
            fieldIds.forEach((fieldId) => {
                updatedFields[fieldId] = tables[tableId].fields[fieldId];
            });
            const updatedTables = {
                ...tables,
                [tableId]: {
                    ...tables[tableId],
                    fields: updatedFields,
                },
            };
            setTables(updatedTables)
            updateTables(updatedTables, "onDragFieldEnd")
        }
    };
    
    const onDragStart = () => {
        hideTableMenu()
        setFieldMenu({})
    }

    const hasConstraint = (tableId, fieldId, constraint, checkTables = tables) => {
        return checkTables[tableId].fields[fieldId]?.constraints?.includes(constraint);
    }


    return (
        <div className={cn(st.workBenchBlock, {[st.workBenchBlockSwitched]: !isOpenedWorkBenchMenu})}
             onContextMenu={(e) => e.preventDefault()}>
            <div className={cn(st.workBenchCloseButton, {[st.workBenchCloseButtonSwitched]: !isOpenedWorkBenchMenu})}
                 onClick={switchWorkBench}><ArrowMenu isOpenedWorkBenchMenu={isOpenedWorkBenchMenu}/></div>

            <div className={st.workBenchHeaderBlock}>
                <div className={st.workBenchTitleBlock}>
                    <div className={st.workBenchTitle}>
                        <div className={st.workBenchTitleLeftArrow} onClick={() => switchWorkBenchMode()}>
                            <TableArrow width={30} height={30}/>
                        </div>
                        <span className={st.nonSelectable}>{t?.[workBenchMode]}</span>
                        <div className={st.workBenchTitleRightArrow} onClick={() => switchWorkBenchMode()}>
                            <TableArrow/>
                        </div>
                    </div>
                </div>
                <div
                    className={cn(st.workBenchButtons, {[st.workBenchButtonsNone]: ((workBenchMode === WorkBenchMode.TABLES && Object.keys(tables).length === 0) || (workBenchMode === WorkBenchMode.AREAS && Object.keys(areas).length === 0))})}>
                    <div className={st.workBenchButtonLeftBlock}>
                        {workBenchMode === WorkBenchMode.TABLES &&
                            <div className={st.openCloseAllTabledButtons}>
                                <div className={st.doubleArrow} onClick={openAllTables}>
                                    <TableDoubleArrow width={18} height={18}/>
                                </div>
                                <div className={st.doubleArrow} onClick={closeAllTables}>
                                    <TableDoubleArrow width={18} height={18} rotate={180}/>
                                </div>
                            </div>
                        }
                    </div>

                    <div className={st.workBenchCreateButton} onClick={() => createObject()}>
                        <span className={st.nonSelectable}>+ {t?.newTable}</span>
                    </div>
                </div>
            </div>
            {workBenchMode === WorkBenchMode.AREAS &&
                <DragDropContext onDragEnd={(e) => onDragEnd(e)} onBeforeDragStart={onDragStart}>
                    <Droppable droppableId="areas" type="AREA">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={st.workBenchBodyBlock}
                            >
                                {Object.keys(areas).map((areaId, index) => (
                                    <Draggable key={areaId} draggableId={`area-${areaId}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={st.tableBlock} key={areaId}
                                            >
                                                <div
                                                    className={cn(st.tableHeader, {[st.tableHeaderSelected]: areaId === selectedAreaId})}
                                                    onClick={() => selectObject(areaId, "area")}>
                                                    <div className={st.tableColorMark}
                                                         style={{backgroundColor: `${areas[areaId].color}`}}></div>
                                                    <div className={st.openButtonNNameBlock}>
                                                        <input id={areaId + "areaName"} className={st.tableNameInput}
                                                               autoComplete="off"
                                                               value={areas[areaId].name}
                                                               onFocus={(event) => focusName(event.target.value)}
                                                               onChange={(event) => changeAreaName(event, areaId, event.target.value)}
                                                               onBlur={(event) => blurAreaName(event, areaId, event.target.value)}/>
                                                    </div>
                                                    <div className={st.tableOptions}>
                                                        <div className={st.tableOptionButton}
                                                             onClick={(e) => switchAreaMenu(e, areaId)}>
                                                            <Palette
                                                                selected={areaId === selectedAreaId}/>
                                                        </div>
                                                        <div className={st.tableOptionButton}
                                                             onClick={(e) => deleteArea(e, areaId)}>
                                                            <Trash
                                                                selected={areaId === selectedAreaId}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
            {workBenchMode === WorkBenchMode.TABLES &&
                <DragDropContext onDragEnd={(e) => onDragEnd(e)} onBeforeDragStart={onDragStart}>
                    <Droppable droppableId="tables" type="TABLE">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={st.workBenchBodyBlock}
                            >
                                {Object.keys(tables).map((tableId, index) => (
                                    <Draggable key={tableId} draggableId={`table-${tableId}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={st.tableBlock} key={tableId}
                                            >

                                                <div
                                                    className={cn(st.tableHeader, {[st.tableHeaderSelected]: tableId === selectedTableId || selectedTablesId.includes(tableId)})}
                                                    onClick={() => selectObject(tableId, "table")}>
                                                    <div className={st.tableColorMark}
                                                         style={{backgroundColor: `${tables[tableId].color}`}}></div>
                                                    <div className={st.openButtonNNameBlock}>
                                                        <div className={st.tableOptionButton}
                                                             onClick={() => switchTable(tableId)}><TableArrow
                                                            selected={tableId === selectedTableId} tableId={tableId}
                                                            openedTableIds={openedTableIds}/></div>
                                                        <input id={tableId} className={st.tableNameInput}
                                                               autoComplete="off"
                                                               value={tables[tableId].name}
                                                               onFocus={(event) => focusName(event.target.value)}
                                                               onChange={(event) => changeTableName(event, tableId, event.target.value)}
                                                               onBlur={(event) => blurTableName(event, tableId, event.target.value)}/>
                                                        {fieldErrorById[tableId]?.tableError &&
                                                            <div className={st.fieldError}>
                                                        <span className={st.fieldErrorText}>!
                                                            <span
                                                                className={cn(st.tooltipText, {[st.tooltipTextLeft]: Object.keys(tables).length === 1}, {[st.tooltipTextUp]: index !== 0 && Object.keys(tables).length > 1}, {[st.tooltipTextDown]: index === 0 && Object.keys(tables).length > 1})}>{fieldErrorById[tableId]?.tableError}</span>
                                                        </span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className={st.tableOptions}>
                                                        <div className={st.tableOptionButton}
                                                             onClick={(e) => createField(tableId)}><Plus
                                                            selected={tableId === selectedTableId}/></div>
                                                        <div className={st.tableOptionButton} id={tableId + "optionButton"}
                                                             onClick={(e) => switchTableMenu(e, tableId)}><Options
                                                            selected={tableId === selectedTableId}/></div>
                                                        
                                                    </div>
                                                </div>
                                                <Droppable droppableId={`fields-${tableId}`} type="FIELD">
                                                    {(provided) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            className={cn(st.tableFieldsBlock, {[st.tableFieldsBlockOpened]: openedTableIds.has(tableId)})}
                                                            style={{overflowY: Object.keys(tables[tableId].fields).length * 40 > 500 ? "auto" : "hidden"}}
                                                        >
                                                            {
                                                                openedTableIds.has(tableId) === true &&
                                                                <>
                                                                    {Object.keys(tables[tableId].fields).map((fieldId, index) => (
                                                                        <Draggable key={fieldId}
                                                                                   draggableId={`field-${fieldId}-${tableId}`}
                                                                                   index={index}>
                                                                            {(provided) => (
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    className={cn(st.tableFieldBlock, {[st.fieldWithError]: fieldErrorById[fieldId]?.fieldError})}
                                                                                >
                                                                                    <div
                                                                                        className={st.tableFieldColorMark}
                                                                                        style={{backgroundColor: `${tables[tableId].color}`}}
                                                                                    ></div>
                                                                                    <div className={st.inputBlock}>
                                                                                        <input
                                                                                            id={fieldId}
                                                                                            autoComplete="off"
                                                                                            className={st.fieldNameInput}
                                                                                            value={tables[tableId].fields[fieldId].name}
                                                                                            onFocus={(event) => focusName(event.target.value)}
                                                                                            onChange={(event) => changeFieldName(event, tableId, fieldId, event.target.value)}
                                                                                            onBlur={(event) => blurFieldName(event, tableId, fieldId, event.target.value)}
                                                                                        />
                                                                                        <div className={st.customSelectBlock}>
                                                                                            <input
                                                                                                id={fieldId}
                                                                                                placeholder={"Your type"}
                                                                                                autoComplete="off"
                                                                                                className={st.fieldTypeInputCustomType}
                                                                                                value={tables[tableId].fields[fieldId].type}
                                                                                                onFocus={(event) => focusName(event.target.value)}
                                                                                                onChange={(event) => changeCustomType(event, tableId, fieldId, event.target.value)}
                                                                                                onBlur={(event) => blurCustomType(event, tableId, fieldId, event.target.value)}
                                                                                            />
                                                                                            <select
                                                                                                value={tables[tableId].fields[fieldId].type}
                                                                                                className={cn({[st.fieldTypeInput]: false}, {[st.fieldTypeInputCustomTypeSelect]: true})}
                                                                                                onChange={(event) => blurCustomTypeSelect(event, tableId, fieldId, event.target.value)}
                                                                                            >
                                                                                                {Object.entries(FieldTypes).map(([key, value]) => (
                                                                                                    <option
                                                                                                        className={st.typeOption}
                                                                                                        key={key}
                                                                                                        value={value}>{value === FieldTypes.YOUR_TYPE ? t?.yourType : value}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </div>
                                                                                        {fieldErrorById[fieldId]?.fieldError &&
                                                                                            <div className={st.fieldError}>
                                                                                        <span className={st.fieldErrorText}>!
                                                                                            <span
                                                                                                className={cn(st.tooltipText, {[st.tooltipTextLeft]: Object.keys(tables[tableId].fields).length === 1}, {[st.tooltipTextUp]: index !== 0 && Object.keys(tables[tableId].fields).length > 1}, {[st.tooltipTextDown]: index === 0 && Object.keys(tables[tableId].fields).length > 1})}>{fieldErrorById[fieldId]?.fieldError}</span>
                                                                                        </span>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className={st.tableOptions}>
                                                                                        <div
                                                                                            className={cn(st.tableOptionButton, {[st.primaryKey]: !hasConstraint(tableId, fieldId, Constraints.PRIMARY_KEY) && !hasConstraint(tableId, fieldId, Constraints.UNIQUE)})}
                                                                                            onClick={() => switchPrimaryKey(tableId, fieldId)}
                                                                                        >
                                                                                            {hasConstraint(tableId, fieldId, Constraints.UNIQUE) &&
                                                                                                <Unique
                                                                                                    selected={hasConstraint(tableId, fieldId, Constraints.UNIQUE)}
                                                                                                    isPrimaryKey={hasConstraint(tableId, fieldId, Constraints.NOT_NULL)}/>
                                                                                            }
                                                                                            {!hasConstraint(tableId, fieldId, Constraints.UNIQUE) &&
                                                                                                <Key
                                                                                                    selected={hasConstraint(tableId, fieldId, Constraints.PRIMARY_KEY)}/>
                                                                                            }
                                                                                        </div>
                                                                                        <div className={st.tableOptionButton}
                                                                                             onClick={() => switchConstraint(tableId, fieldId, Constraints.NOT_NULL)}>
                                                                                            <NN
                                                                                                selected={tables[tableId].fields[fieldId]?.constraints.includes(Constraints.NOT_NULL)}/>
                                                                                        </div>
                                                                                        <div className={st.tableOptionButton}
                                                                                             onClick={(e) => switchFieldMenu(e, tableId, fieldId)}>
                                                                                            <Wrench
                                                                                                selected={tableId === selectedTableId}/>
                                                                                        </div>
                                                                                        {tableId === fieldMenu.tableId && fieldId === fieldMenu.fieldId &&
                                                                                            <div className={st.editMenu} style={{
                                                                                                top: fieldMenu.top,
                                                                                                left: fieldMenu.left
                                                                                            }}>
                                                                                                {/*<div className={st.editMenuArrow}>*/}
                                                                                                {/*</div>*/}
                                                                                                <span>{t?.actions}</span>
                                                                                                <div
                                                                                                    className={st.changeConstraintBlock}>
                                                                                                    {(hasConstraint(tableId, fieldId, Constraints.PRIMARY_KEY) || hasConstraint(tableId, fieldId, Constraints.UNIQUE)) ?
                                                                                                        (<button
                                                                                                            className={st.changeConstraintButton}
                                                                                                            onClick={(e) => switchPrimaryKeyAndUnique(e, tableId, fieldId)}>
                                                                                                            {t?.changeTo} {hasConstraint(tableId, fieldId, Constraints.UNIQUE) ? 'PRIMARY KEY' : 'UNIQUE'}
                                                                                                        </button>) :
                                                                                                        (<button
                                                                                                            className={st.changeConstraintButton}
                                                                                                            onClick={(e) => setConstraint(tableId, fieldId, Constraints.UNIQUE, true)}>
                                                                                                            {t?.changeTo + ' UNIQUE'}
                                                                                                        </button>)
                                                                                                    }
                                                                                                </div>
                                                                                                <div className={st.line}></div>
                                                                                                <div className={st.changeConstraintBlock}>
                                                                                                    <button
                                                                                                        className={st.changeConstraintButton}
                                                                                                        onClick={(e) => deleteField(tableId, fieldId)}>
                                                                                                        {t?.delete}
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {/*<div className={st.tableOptionButton}*/}
                                                                                        {/*     onClick={(e) => deleteField(e, tableId, fieldId)}>*/}
                                                                                        {/*    <Trash*/}
                                                                                        {/*        selected={tableId === selectedTableId}/>*/}
                                                                                        {/*</div>*/}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                </>
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </div>
                                        )}
                                    </Draggable>
                                    
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
            {tableMenu.tableId && (selectedTableId) && workBenchMode === WorkBenchMode.TABLES &&
                <>
                    <div className={st.editMenu} style={{
                        top: tableMenu.top,
                        left: tableMenu.left
                    }}>
                        {/*<div className={st.editMenuArrow}>*/}
                        {/*</div>*/}
                        <span>{t?.actions}</span>

                        <div className={st.changeConstraintBlock}>
                            <button
                                className={st.changeConstraintButton}
                                onClick={(e) => createField(tableMenu.tableId)}>
                                {t?.addField}
                            </button>
                            <button
                                className={st.changeConstraintButton}
                                onClick={(e) => copyTable(e, tableMenu.tableId)}>
                                {t?.copyTable}
                            </button>
                            <button
                                id={selectedTableId + "changeColorButton"}
                                className={st.changeConstraintButton}
                                onClick={(e) => switchColorPalette(e, tableMenu.tableId)}>
                                {t?.changeColor}
                            </button>
                        </div>
                        <div className={st.line}></div>
                        <span>{t?.comment}</span>
                        <div
                            className={st.changeConstraintBlock}>
                            <textarea
                                id={selectedTableId + "tableCommentArea"}
                                className={st.commentTextArea}
                                maxLength={200}
                                value={tables[tableMenu.tableId].comment ?? ""}
                                autoComplete="off"
                                onFocus={(event) => focusName(event.target.value)}
                                onChange={(event) => changeTableComment(event, tableMenu.tableId, event.target.value)}
                                onBlur={(event) => blurTableComment(event, tableMenu.tableId, event.target.value)}/>
                            
                        </div>
                        <div className={st.line}></div>
                        <div
                            className={st.changeConstraintBlock}>
                            <button
                                className={st.changeConstraintButton}
                                onClick={(e) => deleteTable(e, tableMenu.tableId)}>
                                {t?.deleteTable}
                            </button>
                        </div>
                    </div>
                    {tableMenu.isChangingColor &&
                        <>
                            <div className={st.favColorsBlock} style={{
                                top: tableMenu.top + 255,
                                left: tableMenu.left + 220
                            }}>
                                <div className={st.saveColorButton}
                                     onClick={() => saveColor(tables, selectedTableId)}>
                                    <span>{t?.saveColor}</span>
                                </div>
                                <div className={st.colorPalette}>
                                    {[...Array(8)].map((colorIndex, id) => (
                                        <div key={id} className={st.color}
                                             onClick={() => selectSavedColor(tables, selectedTableId, updateTables, colors[id])}
                                             style={{backgroundColor: colors[id] ? colors[id] : ''}}></div>
                                    ))}
                                </div>
                            </div>
                            <div
                                onMouseUp={() => updateTableColor(tables[selectedTableId]?.color, "completeChanging")}
                                className={st.paletteBlock} style={{
                                top: (tableMenu.top ? tableMenu.top : 0),
                                left: `${(tableMenu.left ? tableMenu.left : 0) + 220}px`,
                                position: "fixed"
                            }}>
                                <Chrome color={tables[selectedTableId]?.color}
                                        onChange={(color) => updateTableColor(color.hex, "changingColor", tables[selectedTableId]?.color)}
                                        style={{
                                            background: "rgba(20, 53, 128, 0.65)", // Темный фон
                                            borderRadius: "6px",
                                            border: "none",
                                            backdropFilter: "blur(6px)",
                                            color: "white"
                                        }}
                                        className="paletteTextColor"
                                        showAlpha={false}
                                        onFocus={(e) => updateTableColor(tables[selectedTableId]?.color, "textChanging")}
                                        onBlur={(e) => updateTableColor(tables[selectedTableId]?.color, "textComplete")}
                                />
                            </div>
                        </>
                    }
                </>
            }
            {areaMenu.areaId && selectedAreaId && workBenchMode === WorkBenchMode.AREAS &&
                <>
                    {areaMenu.isChangingColor &&
                        <>
                            <div className={st.favColorsBlock} style={{
                                top: areaMenu.top + 255,
                                left: areaMenu.left + 50
                            }}>
                                <div className={st.saveColorButton}
                                     onClick={() => saveColor(areas, selectedAreaId)}>
                                    <span>{t?.saveColor}</span>
                                </div>
                                <div className={st.colorPalette}>
                                    {[...Array(8)].map((colorIndex, id) => (
                                        <div key={id} className={st.color}
                                             onClick={() => selectSavedColor(areas, selectedAreaId, updateAreas, colors[id])}
                                             style={{backgroundColor: colors[id] ? colors[id] : ''}}></div>
                                    ))}
                                </div>
                            </div>
                            <div
                                onMouseUp={() => updateAreaColor(areas[selectedAreaId]?.color, "completeChanging")}
                                className={st.paletteBlock} style={{
                                top: (areaMenu.top ? areaMenu.top : 0),
                                left: `${(areaMenu.left ? areaMenu.left : 0) + 50}px`,
                                position: "fixed"
                            }}>
                                <Chrome color={areas[selectedAreaId]?.color}
                                        onChange={(color) => updateAreaColor(color.hex, "changingColor", areas[selectedAreaId]?.color)}
                                        style={{
                                            background: "rgba(20, 53, 128, 0.65)", // Темный фон
                                            borderRadius: "6px",
                                            border: "none",
                                            backdropFilter: "blur(6px)",
                                            color: "white"
                                        }}
                                        className="paletteTextColor"
                                        showAlpha={false}
                                        onFocus={(e) => updateAreaColor(areas[selectedAreaId]?.color, "textChanging")}
                                        onBlur={(e) => updateAreaColor(areas[selectedAreaId]?.color, "textComplete")}
                                />
                            </div>
                        </>
                    }
                </>
            }
        </div>
    )
}

export default WorkBench;

