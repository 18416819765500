import {createNotification, NotificationStatus} from "../../../Notification";
import {v4 as uuidv4} from "uuid";
import {formatString, getRandomColor} from "../../utils";
import {RelationTypes} from "../../../enum/RelationTypes";
import {Constraints} from "../../../enum/Constraints";
import {MAX_AMOUNT_OF_TABLES} from "../../../consts";

let tables = {};
let fields = {};
let field = {};
let newFieldId = null;
let relations = {};
let table = {};
let index = 0;

let tableIdByName = {}

const power = 5
const processSQLByLine = (line) => {
    let yIndex = Math.floor(index / power);
    console.log(`Обрабатываем строку: "${line}"`);
    const splitLine = line.split(/\s+/);
    if (splitLine[0] === 'create' && splitLine[1] === 'table' && !isProcessTable) {
        console.log("Начинаем генерировать новую таблицу")
        const newTableId = uuidv4();
        tableIdByName[splitLine[2]] = newTableId;
        fields = {};
        table = {
            x: (index % power) * 300,
            y: yIndex * 200,
            name: splitLine[2]
        };
        isProcessTable = true;
    } else if (splitLine.length >= 2 && isProcessTable) {
        const newFieldId = uuidv4();
        let field = {
            name: splitLine[0], 
            type: splitLine[1].replaceAll(",", ""),
            constraints: new Set(),
            fkRelations: [],
            relations: []
        }
        for (let i = 2; i < splitLine.length;) {
            if (splitLine[i] !== "references") {
                field.constraints.add(splitLine[i].replaceAll(",", "").replaceAll("primary_key", "primary key").replaceAll("not_null", "not null"));
                i++;
            } else {
                const reference = splitLine[i + 1].replaceAll(" ", "")
                const referenceTableName = reference.split("(")[0];
                const referenceTableFieldName = reference.match(/\((.*?)\)/)[1];
                const {refTableId, refFieldId} = getRefTableIdAndFieldId(referenceTableName, referenceTableFieldName);
                field.fkRelations.push(refFieldId);
                i += 2
                const newRelationId = uuidv4();
                relations[`${newRelationId}`] = {
                    table1: tableIdByName[table.name],
                    table1Field: newFieldId,
                    table2: refTableId,
                    table2Field: refFieldId,
                    relationType: RelationTypes.ONE_TO_ONE,
                }
                field.relations.push(newRelationId);
                tables[refTableId].fields[refFieldId].relations.push(newRelationId);
            }
        }
        field.constraints = Array.from(field.constraints);
        fields[`${newFieldId}`] = field;
    } else if (splitLine.length === 1 && splitLine[0] === ");" && isProcessTable) {
        tables[`${tableIdByName[table.name]}`] = {
            ...table,
            fields: fields
        };
        isProcessTable = false;
        index++;
    }
    else {
        createNotification(NotificationStatus.ERROR, "", "Неизвестный синтаксис: " + line)
    }
    // Здесь можно добавить любую логику обработки слова
};

const getRefTableIdAndFieldId = (referenceTableName, referenceTableFieldName) => {
    const refTableId = Object.keys(tables).find(key => tables[key].name === referenceTableName);
    const refFieldId = Object.keys(tables[refTableId].fields).find(key => tables[refTableId].fields[key].name === referenceTableFieldName);
    return {refTableId, refFieldId}
}

const findPKFieldByTableName = (tableName) => {
    const refTableId = Object.keys(tables).find(key => tables[key].name === tableName);
    const refFieldId = Object.keys(tables[refTableId].fields).find(key => tables[refTableId].fields[key].constraints.includes(Constraints.PRIMARY_KEY));
    return tables[refTableId].fields[refFieldId].name;
}

const processSQLByWords = (words) => {
    for (let i = 0; i < words.length;) {
        let word = words[i].toLowerCase();
        // console.log(word)
        if (word === 'create' && words[i + 1] === 'table' && !isProcessTable) {
            let yIndex = Math.floor(index / power);
            const newTableId = uuidv4();
            let newTableName = '';
            if (words[i + 2] === 'if' && words[i + 3] === 'not' && words[i + 4] === 'exists') {
                newTableName = words[i + 5];
                i += 6
            } else {
                newTableName = words[i + 2];
                i += 3
            }
            if (newTableName.split(".").length > 1) {
                newTableName = newTableName.split(".")[1];
            }
            // console.log("Начинаем генерировать новую таблицу " + newTableName);
            tableIdByName[newTableName] = newTableId;
            fields = {};
            table = {
                x: (index % power) * 300,
                y: yIndex * 200,
                name: newTableName
            };
            isProcessTable = true;
        } else if (isProcessTable && word !== "(") {
            if (!isCreatingField) { // если начали обрабатывать новое поле
                isCreatingField = true;
                word = word.replaceAll(",", "") // если предыдущее поле было заверешено через ,name string
                newFieldId = uuidv4();
                field = {
                    name: word, 
                    type: words[i + 1].replaceAll(",", ""),
                    constraints: new Set(),
                    fkRelations: [],
                    relations: []
                }
                // console.log(words[i + 1])
                if (words[i + 1].endsWith(',')) { // информация о поле закончилась
                    isCreatingField = false;
                    field.constraints = Array.from(field.constraints);
                    fields[`${newFieldId}`] = field;
                }
                i += 2;
            } else if (isCreatingField) {
                if (word === "," || word.startsWith(",")) {
                    isCreatingField = false;
                    field.constraints = Array.from(field.constraints);
                    fields[`${newFieldId}`] = field;
                    if (word === ",") {
                        i += 1
                    }
                } else if (word === ');' && isProcessTable) {
                    field.constraints = Array.from(field.constraints);
                    fields[`${newFieldId}`] = field;
                    tables[`${tableIdByName[table.name]}`] = {
                        ...table,
                        fields: fields
                    }
                    isCreatingField = false;
                    isProcessTable = false;
                    index++;
                    i += 1;
                } else if (word !== 'references') {
                    field.constraints.add(word.replaceAll("primary_key", "primary key").replaceAll("not_null", "not null").replaceAll(",", ""));
                    if (word.endsWith(',')) {
                        isCreatingField = false;
                        field.constraints = Array.from(field.constraints);
                        fields[`${newFieldId}`] = field;
                    }
                    i += 1;
                } else {
                    let reference = words[i + 1].replaceAll(" ", "");
                    if (reference.split(".").length > 1) {
                        reference = reference.split(".")[1];
                    }
                    const referenceTableName = reference.replaceAll(",", "").split("(")[0];
                    
                    let referenceTableFieldName;
                    if (reference.split("(").length > 1) {
                        referenceTableFieldName = reference.match(/\((.*?)\)/)[1];
                    } else {
                        if (referenceTableName !== table.name) { // если ссылка не на свою же таблицу
                            referenceTableFieldName = findPKFieldByTableName(referenceTableName);
                        } 
                        else {
                            i += 1
                            continue // потом доработать алгоритм для связи со своей же таблицей (PK может быть еще не создан)
                            // referenceTableFieldName = Object.keys(table.fields).find(key => table.fields[key].constraints.has(Constraints.PRIMARY_KEY));
                        }
                    }
                    // разобраться с этим + обрабатывать constraints
                    const {refTableId, refFieldId} = getRefTableIdAndFieldId(referenceTableName, referenceTableFieldName);
                    field.fkRelations.push(refFieldId);

                    const newRelationId = uuidv4();
                    relations[`${newRelationId}`] = {
                        table1: tableIdByName[table.name],
                        table1Field: newFieldId,
                        table2: refTableId,
                        table2Field: refFieldId,
                        relationType: RelationTypes.ONE_TO_ONE,
                    }
                    field.relations.push(newRelationId);
                    tables[refTableId].fields[refFieldId].relations.push(newRelationId);

                    if (word.endsWith(',')) {
                        const newFieldId = uuidv4();
                        isCreatingField = false;
                        field.constraints = Array.from(field.constraints);
                        fields[`${newFieldId}`] = field;
                    }
                    i += 2
                } 
            }
        } else {
            i += 1
        }
        
    }
}

export const importSQLFile = (t, file, callback) => {
    switchToDefaultSettings();
    const reader = new FileReader();
    reader.onload = function(e) {
        try {
            const text = e.target.result.toLowerCase().replaceAll("primary key", "primary_key").replaceAll("not null", "not_null");
            // Разбиваем текст на строки
            const lines = text.split('\n');
            let words = [];
            // Проходим по каждой строке
            lines.forEach((line) => {
                // Убираем пробелы в начале и конце строки
                const trimmedLine = line.trim();

                // Проверяем, что строка не начинается с --
                if (!trimmedLine.startsWith('--')) {
                    // Разбиваем строку на слова через пробел
                    if (trimmedLine) {
                        words.push(...trimmedLine.toLowerCase().split(/\s+/));
                        // // Разбиваем строку на слова через пробел
                        // const words = trimmedLine.split(/\s+/);
                        //
                        // // Обрабатываем каждое слово
                        // words.forEach((word) => {
                        // });
                    }
                } else {
                    // console.log(`Игнорируем комментарий: ${trimmedLine}`);
                }
            });
            processSQLByWords(words);    
            
            for (let i = 0; i < Object.keys(tables).length; i++) {
                tables[Object.keys(tables)[i]].color = getRandomColor(i);
            }
            if (Object.keys(tables).length > MAX_AMOUNT_OF_TABLES) {
                createNotification(NotificationStatus.WARNING, formatString(t?.errors.tableLimitExceeded, MAX_AMOUNT_OF_TABLES), t?.errors.importSqlStopped);
                return;
            }
            
            callback({tables, relations: relations, areas: {}, name: file.name})
            


        } catch (e) {
            console.error(e)
            createNotification(NotificationStatus.ERROR, "", t?.errors.import)
        }
    };
    if (file) {
        reader.readAsText(file);
    }
};

let isProcessTable = false;
let isCreatingField = false;

const switchToDefaultSettings = () => {
    isProcessTable = false;
    isCreatingField = false;
    tables = {};
    relations = {};
    tableIdByName = {};
    fields = {};
    field = {};
    newFieldId = null;
    index = 0;
}