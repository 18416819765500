import React from 'react';

const TableDoubleArrow = ({width = 28, height = 28, rotate = 0}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" style={{transform: `rotate(${rotate}deg)`}} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path style={{transform: "translateY(-3px)"}} d="M14.8236 17.6234C14.3681 18.1255 13.6283 18.1255 13.1727 17.6234L7.34166 11.1963C6.88611 10.6942 6.88611 9.87871 7.34166 9.37659C7.79722 8.87447 8.53703 8.87447 8.99258 9.37659L14 14.8959L19.0074 9.38061C19.463 8.87849 20.2028 8.87849 20.6583 9.38061C21.1139 9.88273 21.1139 10.6982 20.6583 11.2003L14.8273 17.6274L14.8236 17.6234Z" fill={"var(--icon-color)"}/>
            <path style={{transform: "translateY(5px)"}} d="M14.8236 17.6234C14.3681 18.1255 13.6283 18.1255 13.1727 17.6234L7.34166 11.1963C6.88611 10.6942 6.88611 9.87871 7.34166 9.37659C7.79722 8.87447 8.53703 8.87447 8.99258 9.37659L14 14.8959L19.0074 9.38061C19.463 8.87849 20.2028 8.87849 20.6583 9.38061C21.1139 9.88273 21.1139 10.6982 20.6583 11.2003L14.8273 17.6274L14.8236 17.6234Z" fill={"var(--icon-color)"}/>
        </svg>

    );
};

export default TableDoubleArrow;