export const Ru = {
    save: "Сохранить",
    cancel: "Отменить",
    
    // workbench
    tables: "Таблицы",
    areas: "Области",
    newTable: "Создать",
    addField: "Добавить поле",
    copyTable: "Дубрировать",
    changeColor: "Изменить цвет",
    deleteTable: "Удалить таблицу",
    actions: "Действия",
    comment: "Комментарий",
    changeTo: "Поставить",
    make: "Сделать",
    delete: "Удалить",
    saveColor: "Сохранить цвет",
    yourType: "Свой тип",
    
    // settingsModal
    settings: "Настройки",
    general: "Общие",
    language: "Язык",
    en: "Английский",
    ru: "Русский",
    theme: "Тема",
    dark: "Темная",
    light: "Светлая",
    autosaveTimeout: "Автосохранение",
    forDevelopers: "Для разработчиков",
    
    // header left
    changeName: "Изменить",
    deleteProject: "Удалить",
    switch: "Смена",
    
    // header right
    generate: "Создать",
    share: "Доступ",
    
    // import modal
    importTitle: "Импорт диаграммы БД",
    chooseImportType: "Выберите тип файла",
    yourFile: "Ваш файл",
    moveYourFileOrClickHere: "Перетащите файл или кликните здесь",
    dropYourFile: "Отпустите файл",
    import: "Импортировать",
    
    // export modal
    exportTitle: "Экспорт диаграммы БД",
    exportTypes: "Типы экспорта",
    
    errors: {
        maxAmountOfTables: "Максимально разрешенное кол-во таблиц уже создано!",
        relationTypeError: "Ошибка типа связи",
        impossibleRelation: "Отношение «{0}» между «{1}» и «{2}» невозможно",
        import: "Ошибка импорта данных",
        attributeNotFound: "Не найден атрибут «{0}»",
        importSqlStopped: "Импорт SQL прерван",
        tableLimitExceeded: "Превышен лимит количества таблиц: {0}",

        tableNameUnique: "Имя таблицы должно быть уникальным",
        noFieldName: "Отсутствует наименование поля",
        noFieldType: "Отсутствует тип поля",
        maxFieldNameLengthExceeded: "Превышена максимальная длина поля",
        forbiddenCharacters: "Использованы запрещенные символы",
        numberAtTheBeginning: "В начале имени не может быть цифра",
        reservedName: "Имя является зарезервированным",
    },
    success: {
        import: "Данные успешно импортированы",
        export: "Данные успешно экспортированы",
        sqlExport: "SQL успешно сгенерирован",
    },

    // projects
    myProjects: "Мои проекты",
    sharedMe: "Доступные мне",
};