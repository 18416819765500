import React from 'react';
import {TABLE_WIDTH} from "../../service/consts";

const TableTopBorderSelect = ({isGroupSelection}) => {
    return (
        <svg x={4} width={TABLE_WIDTH + 2} height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d={'M0.5 7V6.5C0.5 3.18629 3.18629 0.5 6.5 0.5H' + (TABLE_WIDTH - 4.5) + 'C' + (TABLE_WIDTH - 2) + '.814 0.5 ' + (TABLE_WIDTH + 1.5) + ' 3.18629 ' + (TABLE_WIDTH + 1.5) + ' 6.5V7'} stroke={isGroupSelection ? '#8741ff' : "var(--selection-color)"}/>
        </svg>
    );
};

export default TableTopBorderSelect;