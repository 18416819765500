import {RelationTypes} from "../../../service/enum/RelationTypes";
import cn from "classnames";
import st from "../Editor.module.css";
import {Positions} from "../../../service/enum/Positions";
import {useEffect, useRef, useState} from "react";
import {TABLE_WIDTH} from "../../../service/consts";

export const Arrow = ({ relationId, relation, tables, selectedRelationId, selectRelation, isInitialUpdate }) => {

    const pathRef = useRef(null);
    const [arrowAnimated, setArrowAnimated] = useState(false)

    useEffect(() => {
        updateAnimation();
    }, [relation, tables, selectedRelationId]); // Зависимость от изменений в data path

    const updateAnimation = () => {
        if (pathRef.current) {
            const path = pathRef.current;
            const length = path.getTotalLength();

            // Устанавливаем стили для анимации
            path.style.strokeDasharray = length;
            path.style.opacity = 1; // фикс при возвращении таблицы после удаления - на долю секунды появлялись полность стрелки, а потом шла анимация появления
            
            if (!arrowAnimated) {
                path.style.strokeDashoffset = length;
                setTimeout(() => {
                    // Запуск анимации
                    requestAnimationFrame(() => {
                        path.style.transition = `stroke-dashoffset ${isInitialUpdate ? "2.5" : "1"}s ease-in-out`;
                        path.style.strokeDashoffset = 0;
                    });
                    setArrowAnimated(true)
                }, isInitialUpdate ? 800 : 1)
            }

            
        }
    };
    
    let middlePoints = [] // массив для промежуточных точек стрелок
    let points; // финальная строка с точками
    const tableWidthWithPoints = TABLE_WIDTH + 10;

    const fieldStep = 20; // шаг между полями (y расстояние)
    // позиция поля (коэффициент для расчета смещения начала стрелки вниз)
    let startFieldIndex = Object.keys(tables[relation.table1]?.fields).findIndex((fieldId) => fieldId === relation.table1Field);
    const startTableFieldDeltaY = 54 + fieldStep * startFieldIndex; // координаты смещения стрелки относительно поля

    let endFieldIndex = Object.keys(tables[relation.table2]?.fields).findIndex((fieldId) => fieldId === relation.table2Field);
    const endTableFieldDeltaY = 54 + fieldStep * endFieldIndex;

    const step = 10; // шаг для перемещения всего по сетке
    
    // координаты левой стороны полей
    const startTableFieldCoords = { x: step * Math.round(tables[relation.table1].x / step) + step / 2, y: step * Math.round(tables[relation.table1].y / step) + step / 2 + startTableFieldDeltaY}
    const endTableFieldCoords = { x: step * Math.round(tables[relation.table2].x / step) + step / 2, y: step * Math.round(tables[relation.table2].y / step) + step / 2 + endTableFieldDeltaY}
    const startTableHeight = 54 + fieldStep * Object.keys(tables[relation.table1]?.fields).length - 5;
    const endTableHeight = 54 + fieldStep * Object.keys(tables[relation.table2]?.fields).length - 5;
    
    
    // координаты начала и конца стрелок между полями относительно позиции таблиц
    const startTableArrowCoords = {x: startTableFieldCoords.x, y: startTableFieldCoords.y}
    const endTableArrowCoords = {x: endTableFieldCoords.x, y: endTableFieldCoords.y}

    let arrowVisManyToOneRelRatio = 1;
    let arrowVisOneToManyRelRatio = 1;

    // перекидываем начало стрелки на правый бок таблицы, если необходимо
    // если правый бок стартовой таблицы не правее середины конечной и (нижняя граница стартовой выше верхней границы конечной или нижняя граница конечной выше верхней границы стартовой)
    if (startTableArrowCoords.x + TABLE_WIDTH + 5 < endTableArrowCoords.x || (startTableArrowCoords.x + TABLE_WIDTH + 5 < endTableArrowCoords.x + tableWidthWithPoints / 2 && (startTableFieldCoords.y - startTableFieldDeltaY + startTableHeight + 5 < endTableFieldCoords.y - endTableFieldDeltaY || endTableFieldCoords.y - endTableFieldDeltaY + endTableHeight + 5 < startTableFieldCoords.y - startTableFieldDeltaY)) ) {
        arrowVisManyToOneRelRatio = -1
        arrowVisOneToManyRelRatio = -1
        startTableArrowCoords.x += TABLE_WIDTH
    }  else if (endTableArrowCoords.x + TABLE_WIDTH < startTableArrowCoords.x) { // если конечная таблица слева от начальной
        endTableArrowCoords.x += TABLE_WIDTH
    } else // если левый бок стартовой таблицы правее середины конечной и (нижняя граница стартовой выше верхней границы конечной или нижняя граница конечной выше верхней границы стартовой)
    if (startTableArrowCoords.x > endTableArrowCoords.x + TABLE_WIDTH / 2 && (startTableFieldCoords.y - startTableFieldDeltaY + startTableHeight + 5 < endTableFieldCoords.y - endTableFieldDeltaY || endTableFieldCoords.y - endTableFieldDeltaY + endTableHeight + 5 < startTableFieldCoords.y - startTableFieldDeltaY) ) {
        endTableArrowCoords.x += TABLE_WIDTH
    }
        
    // Добавление точек стартовой таблице, если manyToOne
    const addManyToOneRelationPoints = () => {
        middlePoints.push({x: startTableArrowCoords.x - 10 * arrowVisManyToOneRelRatio, y: startTableArrowCoords.y})
        middlePoints.push({x: startTableArrowCoords.x, y: startTableArrowCoords.y - 5})
        middlePoints.push({x: startTableArrowCoords.x - 10 * arrowVisManyToOneRelRatio, y: startTableArrowCoords.y})
        middlePoints.push({x: startTableArrowCoords.x, y: startTableArrowCoords.y + 5})
        middlePoints.push({x: startTableArrowCoords.x - 10 * arrowVisManyToOneRelRatio, y: startTableArrowCoords.y})
    }

    const addOneToManyRelationPoints = () => {
        middlePoints.push({x: endTableArrowCoords.x + 10 * arrowVisOneToManyRelRatio, y: endTableArrowCoords.y})
        middlePoints.push({x: endTableArrowCoords.x, y: endTableArrowCoords.y - 5})
        middlePoints.push({x: endTableArrowCoords.x + 10 * arrowVisOneToManyRelRatio, y: endTableArrowCoords.y})
        middlePoints.push({x: endTableArrowCoords.x, y: endTableArrowCoords.y + 5})
        middlePoints.push({x: endTableArrowCoords.x + 10 * arrowVisOneToManyRelRatio, y: endTableArrowCoords.y})
    }

    // поведение стрелок, если одна таблица над другой
    
    // (наполовину)
    if (startTableFieldCoords.x + tableWidthWithPoints >= endTableFieldCoords.x && endTableFieldCoords.x + tableWidthWithPoints / 2 >= startTableFieldCoords.x + tableWidthWithPoints && startTableFieldCoords.y - startTableFieldDeltaY + startTableHeight + 5 < endTableFieldCoords.y - endTableFieldDeltaY) { // зигзаг, старт слева сверху
        if (relation.relationType === RelationTypes.MANY_TO_ONE) addManyToOneRelationPoints()
        middlePoints.push({x: startTableFieldCoords.x + TABLE_WIDTH + 20, y: startTableArrowCoords.y, isAngle: true})
        // startTableFieldCoords учитывает по y смещение поля от начала таблицы, поэтому его надо вычесть
        middlePoints.push({x: startTableFieldCoords.x + TABLE_WIDTH + 20, y: startTableFieldCoords.y - startTableFieldDeltaY + startTableHeight + (endTableFieldCoords.y - endTableFieldDeltaY - (startTableFieldCoords.y + startTableHeight - startTableFieldDeltaY)) / 2, isAngle: true})
        middlePoints.push({x: endTableFieldCoords.x - 20, y: startTableFieldCoords.y - startTableFieldDeltaY + startTableHeight + (endTableFieldCoords.y - endTableFieldDeltaY - (startTableFieldCoords.y + startTableHeight - startTableFieldDeltaY)) / 2, isAngle: true})
    
        middlePoints.push({x: endTableFieldCoords.x - 20, y: endTableFieldCoords.y, isAngle: true})
        if (relation.relationType === RelationTypes.ONE_TO_MANY) addOneToManyRelationPoints()
    }
    else if (startTableFieldCoords.x + tableWidthWithPoints >= endTableFieldCoords.x && endTableFieldCoords.x + tableWidthWithPoints / 2 >= startTableFieldCoords.x + tableWidthWithPoints && endTableFieldCoords.y - endTableFieldDeltaY + endTableHeight + 5 < startTableFieldCoords.y - startTableFieldDeltaY) { // зигзаг, старт слева снизу
        if (relation.relationType === RelationTypes.MANY_TO_ONE) addManyToOneRelationPoints()
        middlePoints.push({x: startTableFieldCoords.x + TABLE_WIDTH + 20, y: startTableArrowCoords.y, isAngle: true})
        // startTableFieldCoords учитывает по y смещение поля от начала таблицы, поэтому его надо вычесть
        middlePoints.push({x: startTableFieldCoords.x + TABLE_WIDTH + 20, y: endTableFieldCoords.y - endTableFieldDeltaY + endTableHeight + (startTableFieldCoords.y - startTableFieldDeltaY - (endTableFieldCoords.y + endTableHeight - endTableFieldDeltaY)) / 2, isAngle: true})
        middlePoints.push({x: endTableFieldCoords.x - 20, y: endTableFieldCoords.y - endTableFieldDeltaY + endTableHeight + (startTableFieldCoords.y - startTableFieldDeltaY - (endTableFieldCoords.y + endTableHeight - endTableFieldDeltaY)) / 2, isAngle: true})

        middlePoints.push({x: endTableFieldCoords.x - 20, y: endTableFieldCoords.y, isAngle: true})
        if (relation.relationType === RelationTypes.ONE_TO_MANY) addOneToManyRelationPoints()
    }
    else if (startTableFieldCoords.x >= endTableFieldCoords.x + tableWidthWithPoints / 2 && endTableFieldCoords.x + tableWidthWithPoints >= startTableFieldCoords.x && startTableFieldCoords.y - startTableFieldDeltaY + startTableHeight + 5 < endTableFieldCoords.y - endTableFieldDeltaY) { // зигзаг, старт справа сверху
        if (relation.relationType === RelationTypes.MANY_TO_ONE) addManyToOneRelationPoints()
        middlePoints.push({x: startTableFieldCoords.x - 20, y: startTableArrowCoords.y, isAngle: true})
        // startTableFieldCoords учитывает по y смещение поля от начала таблицы, поэтому его надо вычесть
        middlePoints.push({x: startTableFieldCoords.x - 20, y: startTableFieldCoords.y - startTableFieldDeltaY + startTableHeight + (endTableFieldCoords.y - endTableFieldDeltaY - (startTableFieldCoords.y + startTableHeight - startTableFieldDeltaY)) / 2, isAngle: true})
        middlePoints.push({x: endTableFieldCoords.x + TABLE_WIDTH + 20, y: startTableFieldCoords.y - startTableFieldDeltaY + startTableHeight + (endTableFieldCoords.y - endTableFieldDeltaY - (startTableFieldCoords.y + startTableHeight - startTableFieldDeltaY)) / 2, isAngle: true})

        middlePoints.push({x: endTableFieldCoords.x + TABLE_WIDTH + 20, y: endTableFieldCoords.y, isAngle: true})
        if (relation.relationType === RelationTypes.ONE_TO_MANY) addOneToManyRelationPoints()
    }
    else if (startTableFieldCoords.x >= endTableFieldCoords.x + tableWidthWithPoints / 2 && endTableFieldCoords.x + tableWidthWithPoints >= startTableFieldCoords.x && endTableFieldCoords.y - endTableFieldDeltaY + endTableHeight + 5 < startTableFieldCoords.y - startTableFieldDeltaY) { // зигзаг, старт справа снизу
        if (relation.relationType === RelationTypes.MANY_TO_ONE) addManyToOneRelationPoints()
        middlePoints.push({x: startTableFieldCoords.x - 20, y: startTableArrowCoords.y, isAngle: true})
        // startTableFieldCoords учитывает по y смещение поля от начала таблицы, поэтому его надо вычесть
        middlePoints.push({x: startTableFieldCoords.x - 20, y: endTableFieldCoords.y - endTableFieldDeltaY + endTableHeight + (startTableFieldCoords.y - startTableFieldDeltaY - (endTableFieldCoords.y + endTableHeight - endTableFieldDeltaY)) / 2, isAngle: true})
        middlePoints.push({x: endTableFieldCoords.x + TABLE_WIDTH + 20, y: endTableFieldCoords.y - endTableFieldDeltaY + endTableHeight + (startTableFieldCoords.y - startTableFieldDeltaY - (endTableFieldCoords.y + endTableHeight - endTableFieldDeltaY)) / 2, isAngle: true})

        middlePoints.push({x: endTableFieldCoords.x + TABLE_WIDTH + 20, y: endTableFieldCoords.y, isAngle: true})
        if (relation.relationType === RelationTypes.ONE_TO_MANY) addOneToManyRelationPoints()
    }
    else if (startTableFieldCoords.x + tableWidthWithPoints >= endTableFieldCoords.x && endTableFieldCoords.x + tableWidthWithPoints >= startTableFieldCoords.x ) { // старторая таблица снизу слева от конечной
        arrowVisOneToManyRelRatio = -1
        if (relation.relationType === RelationTypes.MANY_TO_ONE) addManyToOneRelationPoints()
        if (startTableFieldCoords.x >= endTableFieldCoords.x) {
            middlePoints.push({x: endTableFieldCoords.x - 20, y: startTableArrowCoords.y, isAngle: true})
            middlePoints.push({x: endTableFieldCoords.x - 20, y: endTableArrowCoords.y, isAngle: true})
        } else {
            middlePoints.push({x: startTableFieldCoords.x - 20, y: startTableArrowCoords.y, isAngle: true})
            middlePoints.push({x: startTableFieldCoords.x - 20, y: endTableArrowCoords.y, isAngle: true})
        }
        if (relation.relationType === RelationTypes.ONE_TO_MANY) addOneToManyRelationPoints()

    } else { // обычное поведение
        if (relation.relationType === RelationTypes.MANY_TO_ONE) addManyToOneRelationPoints()

        middlePoints.push({x: startTableArrowCoords.x + (endTableArrowCoords.x - startTableArrowCoords.x) / 2, y: startTableArrowCoords.y, isAngle: true})

        middlePoints.push({x: startTableArrowCoords.x + (endTableArrowCoords.x - startTableArrowCoords.x) / 2, y: endTableArrowCoords.y, isAngle: true})

        if (relation.relationType === RelationTypes.ONE_TO_MANY) addOneToManyRelationPoints()
    }

    if (middlePoints.length) {
        points = [startTableArrowCoords, ...middlePoints, endTableArrowCoords]
    } else {
        points = [startTableArrowCoords, endTableArrowCoords]
    }
    
    const createRotation = (startPoint, angleRotation, endPoint) => {
        return `${startPoint} A 10 10 0 0 ${angleRotation} ${endPoint}`
    }

    points = points.map((point, iPoint) => {
        let y = 10;
        if (iPoint === 0) {
            return `M ${point.x},${point.y}`
        } else if (iPoint === points.length - 1) {
            return  `L ${point.x},${point.y}`
        } else if (point?.isAngle) {
            if (point.y > points[iPoint + 1].y) { // движемся вверх
                if (points[iPoint - 1].x < points[iPoint + 1].x) { // пришли слева
                    if (Math.abs(point.y - points[iPoint + 1].y) === 10) {
                        y = 5
                    }
                    return createRotation(`L ${point.x - 10},${point.y}`, 0, `${point.x} ${point.y - y}`)
                } else if (points[iPoint - 1].x > points[iPoint + 1].x) { // пришли справа
                    if (Math.abs(point.y - points[iPoint + 1].y) === 10) {
                        y = 5
                    }
                    return createRotation(`L ${point.x + 10},${point.y}`, 1, `${point.x} ${point.y - y}`)
                }
            } else if (point.y < points[iPoint + 1].y) { // движемся вниз
                if (points[iPoint - 1].x < points[iPoint + 1].x) { // пришли слева
                    if (Math.abs(point.y - points[iPoint + 1].y) === 10) {
                        y = 5
                    }
                    return createRotation(`L ${point.x - 10},${point.y}`, 1, `${point.x} ${point.y + y}`)
                } else if (points[iPoint - 1].x > points[iPoint + 1].x) { // пришли справа
                    if (Math.abs(point.y - points[iPoint + 1].y) === 10) {
                        y = 5
                    }
                    return createRotation(`L ${point.x + 10},${point.y}`, 0, `${point.x} ${point.y + y}`)
                }
            } else if (point.x < points[iPoint + 1].x) { // движемся вправо
                if (points[iPoint - 1].y > points[iPoint + 1].y) { // пришли снизу
                    if (Math.abs(point.y - points[iPoint - 1].y) === 10) {
                        y = 5
                    }
                    return createRotation(`L ${point.x},${point.y + y}`, 1, `${point.x + 10} ${point.y}`)
                } else if (points[iPoint - 1].y < points[iPoint + 1].y) { // пришли сверху
                    if (Math.abs(point.y - points[iPoint - 1].y) === 10) {
                        y = 5
                    }
                    return createRotation(`L ${point.x},${point.y - y}`, 0, `${point.x + 10} ${point.y}`)
                }
            } else if (point.x > points[iPoint + 1].x) { // движемся влево
                if (points[iPoint - 1].y > points[iPoint + 1].y) { // пришли снизу
                    if (Math.abs(point.y - points[iPoint - 1].y) === 10) {
                        y = 5
                    }
                    return createRotation(`L ${point.x},${point.y + y}`, 0, `${point.x - 10} ${point.y}`)
                } else if (points[iPoint - 1].y < points[iPoint + 1].y) { // пришли сверху
                    if (Math.abs(point.y - points[iPoint - 1].y) === 10) {
                        y = 5
                    }
                    return createRotation(`L ${point.x},${point.y - y}`, 1, `${point.x - 10} ${point.y}`)
                }
            }
        }
        return  `L ${point.x},${point.y}`
    })
        .reduce((a, b) => a + " " + b);

    return (
        <>
            <g>
                <path
                    className={cn({[st.animatedArrow]: isInitialUpdate})}
                    ref={pathRef}
                    d={points} //"M100,200 Q200,100 300,200 Q200,300 100,200"
                    stroke={relation.relationErrors?.length ? "#d90505" : "#979696"}
                    strokeWidth="2"
                    opacity={0}
                    fill="none" // Задаём fill="none", чтобы ломаная линия не была закрашена
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    onClick={(e) => selectRelation(e, relationId)}
                    className={cn(st.arrow, {[st.arrowSelected]: relationId === selectedRelationId})}
                    d={points}
                    stroke="#69A2FF00"
                    strokeWidth="7"
                    fill="none" // Задаём fill="none", чтобы ломаная линия не была закрашена
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </>
    );
};


export const CreationArrow = ({ selectedTableId, tables, mouseCoords, selectedField }) => {
    const step = 10;
    const fieldStep = 20;
    let fieldIndex = Object.keys(tables[selectedTableId].fields).findIndex((fieldId) => fieldId === selectedField.fieldId);
    const startDeltaY = 54 + fieldStep * fieldIndex;

    const startDeltaX = selectedField.position === Positions.RIGHT ? TABLE_WIDTH : 0;

    const startCoords = { x: step * Math.round(tables[selectedTableId].x / step) + step / 2 + startDeltaX, y: step * Math.round(tables[selectedTableId].y / step) + step / 2 + startDeltaY}
    const endCoords = { x: step * Math.round(mouseCoords.x / step) + step / 2, y: step * Math.round(mouseCoords.y / step) + step / 2 }

    const pointsString = [startCoords, endCoords].map(point => `${point.x},${point.y}`).join(' ');

    return (
        <polyline
            className={st.creatingArrow}
            points={pointsString}
            stroke="#69A2FF7F"
            strokeWidth="3"
            fill="none" // Задаём fill="none", чтобы ломаная линия не была закрашена
            strokeLinecap="round"
        />
    );
};