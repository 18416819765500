import st from "./HeaderLeft.module.css";
import {useDispatch, useSelector} from "react-redux";
import {updateRelationsRedux, updateTablesRedux} from "../../redux/actions/appActions";
import Options from "../../resources/Options";
import {useState} from "react";
import SwitchIcon from "../../resources/SwitchIcon";
import cn from "classnames";
import {Translations} from "../../translations/Translations";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {useSettings} from "../../service/utils/hooks/useSettings";

const HeaderLeft = ({projectInfo, setProjectInfo, updateProjectName}) => {
    const [isMenuOpened, setIsMenuOpened] = useState(false)
    const [isProjectNameEditing, setIsProjectNameEditing] = useState(false)
    const dispatch = useDispatch();
    const {t} = useSettings();
    
    const saveProjectName = () => {
        setIsProjectNameEditing(false)
        updateProjectName(projectInfo.name, "saveProjectName")
    }
    
    const focusProjectNameInput = () => {
        setIsProjectNameEditing(true)
        const inputElement = document.getElementById("projectNameInput");
        inputElement.style.display = "block";
        inputElement.focus();
    }

    const reactHistory = useHistory();
    
    return (
        <div className={st.headerBlock}>
            <div className={st.homeButton} onClick={() => reactHistory.push('/projects')}>
                <span>DBDS</span>
            </div>
            <div className={st.divider}></div>
            <div className={st.projectInfoBlock}>
                <div className={st.projectNameBlock}>
                    <span className={st.projectName} style={{display: isProjectNameEditing ? "none" : "block"}}>{projectInfo.name}</span>
                    <div className={st.projectNameShadow} style={{display: isProjectNameEditing ? "none" : "block"}}></div>
                    <input
                        style={{display: isProjectNameEditing ? "block" : "none"}}
                        className={st.projectNameInput}
                        id={"projectNameInput"}
                        value={projectInfo?.name ?? ""}
                        onBlur={saveProjectName}
                        onChange={(e) => setProjectInfo({...projectInfo, name: e.target.value})}
                    />
                </div>
                <div className={cn(st.options, {[st.optionsOn]: isMenuOpened})} onClick={() => setIsMenuOpened(!isMenuOpened)}>
                    <Options width={22} height={22}/>
                    {isMenuOpened &&
                        <div className={st.menuBlock}>
                            <div className={st.menuButton} onClick={focusProjectNameInput}>{t?.changeName}</div>
                            <div className={st.dividerHorizontal}></div>
                            <div className={st.deleteButton}>{t?.deleteProject}</div>
                        </div>
                    }
                </div>
            </div>
            <div className={st.divider}></div>
            <div className={st.switchButton}>
                <span>{t?.switch}</span>
                <SwitchIcon/>
            </div>
        </div>
    )
}

export default HeaderLeft;
