export const En = {
    save: "Save",
    cancel: "Cancel",
    
    // workbench
    tables: "Tables",
    areas: "Areas",
    newTable: "Create",
    addField: "Add field",
    copyTable: "Copy table",
    changeColor: "Change color",
    deleteTable: "Delete table",
    actions: "Actions",
    comment: "Comment",
    changeTo: "Change to",
    make: "Make",
    delete: "Delete",
    saveColor: "Save color",
    yourType: "Your type",

    // settingsModal
    settings: "Settings",
    general: "General",
    language: "Language",
    en: "English",
    ru: "Russian",
    theme: "Theme",
    dark: "Dark",
    light: "Light",
    autosaveTimeout: "Autosave timeout",
    forDevelopers: "For developers",

    // header left
    changeName: "Change name",
    deleteProject: "Delete project",
    switch: "switch",
    
    // header right
    generate: "Generate",
    share: "Share",

    // import modal
    importTitle: "Import DB diagram",
    chooseImportType: "Choose import type",
    yourFile: "Your file",
    moveYourFileOrClickHere: "Move your file or click here",
    dropYourFile: "Drop your file",
    import: "Import",

    // export modal
    exportTitle: "Export DB diagram",
    exportTypes: "Export types",

    errors: {
        maxAmountOfTables: "The maximum allowed number of tables has already been created!",
        relationTypeError: "Relation type error",
        impossibleRelation: "Relation «{0}» between «{1}» and «{2}» is impossible",
        import: "Data import error",
        attributeNotFound: "Attribute not found «{0}»",
        importSqlStopped: "SQL import stopped",
        tableLimitExceeded: "The limit on the number of tables has been exceeded: {0}",
        
        tableNameUnique: "Table name must be unique",
        noFieldName: "The field name is missing",
        noFieldType: "The field type is missing",
        maxFieldNameLengthExceeded: "The maximum field length has been exceeded",
        forbiddenCharacters: "Forbidden characters are used",
        numberAtTheBeginning: "Can't be a number at the name beginning",
        reservedName: "The name is reserved",
    },
    success: {
        import: "The data has been successfully imported",
        export: "The data has been successfully exported",
        sqlExport: "SQL generated successfully",
    },
    
    // projects
    myProjects: "My projects",
    sharedMe: "Shared me",
};