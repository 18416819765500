import {createNotification, NotificationStatus} from "../../../Notification";

export const generateJSONFile = (projectId, t) => {
    const projectToExport = localStorage.getItem("project_" + projectId)
    const blob = new Blob([projectToExport], {type: "application/json"});
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `project_${projectId}.json`; // Название файла
    link.click(); // Инициирует скачивание
    URL.revokeObjectURL(url); // Очистка ресурса после загрузки
    createNotification(NotificationStatus.SUCCESS, t?.success.export)
}