import React from 'react';

const Trash = ({selected}) => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2885_4)">
                <path d="M6 8H7.77778L22 8" stroke={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21 7.8V21.1C21 21.6039 20.7893 22.0872 20.4142 22.4435C20.0391 22.7998 19.5304 23 19 23H9C8.46957 23 7.96086 22.7998 7.58579 22.4435C7.21071 22.0872 7 21.6039 7 21.1V7.8M10 7.8V5.9C10 5.39609 10.2107 4.91282 10.5858 4.5565C10.9609 4.20018 11.4696 4 12 4H16C16.5304 4 17.0391 4.20018 17.4142 4.5565C17.7893 4.91282 18 5.39609 18 5.9V7.8" stroke={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}  strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.6665 12.8333V19.8333" stroke={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}  strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M16.3335 12.8333V19.8333" stroke={selected ? "var(--workbench-blue-icon-color)" : "var(--icon-color)"}  strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2885_4">
                    <rect width="28" height="28" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default Trash;