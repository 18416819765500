import React from 'react';
import cn from "classnames";
import st from "./CreateProjectModal.module.css";
import Modal from "../Modal";

const CreateProjectModal = ({isOpenCreateModal, setIsOpenCreateModal}) => {


    const checkExportAvailable = () => {
        return true
    }
    return (
        <Modal isOpened={isOpenCreateModal} close={setIsOpenCreateModal}>
            <div className={st.createTitleBlock}>
                <span>Create your new project</span>
            </div>
            <div className={st.modalBody}>
                
            </div>
            <div className={st.modalFooter}>
                <div className={st.button} onClick={() => setIsOpenCreateModal(false)}>
                    <span>Close</span>
                </div>
                <div className={cn(st.button)} onClick={() => setIsOpenCreateModal(false)}>
                    <span>Create</span>
                </div>
            </div>
        </Modal>


    );
};

export default CreateProjectModal;