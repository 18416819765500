import st from "./HeaderRight.module.css";
import {useDispatch, useSelector} from "react-redux";
import {updateRelationsRedux, updateTablesRedux} from "../../redux/actions/appActions";
import LeftArrow from "../../resources/LeftArrow";
import RightArrow from "../../resources/RightArrow";
import cn from "classnames";
import HistoryBook from "../../resources/HistoryBook";
import {MAX_HISTORY} from "../../service/consts";
import React, {useState} from "react";
import Save from "../../resources/Save";
import Download from "../../resources/Download";
import Settings from "../../resources/Settings";
import Generate from "../../resources/Generate";
import Burger from "../../resources/Burger";
import Share from "../../resources/Share";
import ExportModal from "../Modals/ExportModal/ExportModal";
import ImportModal from "../Modals/ImportModal/ImportModal";
import SettingsModal from "../Modals/SettingsModal/SettingsModal";
import {useSettings} from "../../service/utils/hooks/useSettings";

const HeaderRight = ({manageHistoryByMode, historyIndex, updateHistory, history, projectId, fieldErrorById, resizeCanvas, updateAll}) => {
    
    const tables = useSelector((state) => state.tablesRedux);
    const relations = useSelector((state) => state.relationsRedux);
    const {t} = useSettings();

    const [historyBarIsOpen, setHistoryBarIsOpen] = useState(true);
    const [generateModalIsOpened, setGenerateModalIsOpened] = useState(false);
    const [importModalIsOpened, setImportModalIsOpened] = useState(false);
    const [settingsModalIsOpened, setSettingsModalIsOpened] = useState(false);

    const checkAndUpdateHistory = (historyIndex) => {
        if (historyIndex < history.length) {
            updateHistory(historyIndex, projectId)
        }
    }

    const switchHistoryBar = () => {
        setHistoryBarIsOpen(!historyBarIsOpen);
    }
    
    return (
        <>
            <div className={cn(st.headerBlock, {[st.headerBlockHistoryIsOpen]: historyBarIsOpen})}>
                <div className={st.versionButtonsBlock}>
                    <div onClick={() => manageHistoryByMode("left", "button", projectId)} className={st.historyButton}>
                        <LeftArrow/>
                    </div>
                    <div onClick={switchHistoryBar} className={cn(st.historyButton, {
                        [st.versionBarIsOpen]: historyBarIsOpen
                    })}>
                        <HistoryBook/>
                    </div>
                    <div onClick={() => manageHistoryByMode("right", "button", projectId)}
                         className={st.historyButton}>
                        <RightArrow/>
                    </div>
                </div>
                <div className={st.divider}></div>
                <div className={st.buttonsBlock}>
                    <div className={st.saveButton} onClick={() => setImportModalIsOpened(true)}>
                        <Download/>
                    </div>
                    <div className={st.saveButton}>
                        <Save/>
                    </div>
                </div>
                <div className={st.divider}></div>
                <div className={st.buttonsBlock}>
                    <div className={st.button} onClick={() => setGenerateModalIsOpened(true)}>
                        <span>{t?.generate}</span>
                        <Generate/>
                    </div>
                </div>
                <div className={st.divider}></div>
                <div className={st.buttonsBlock}>
                    <div className={st.saveButton} onClick={() => setSettingsModalIsOpened(true)}>
                        <Settings/>
                    </div>
                    <div className={st.button}>
                        <span>{t?.share}</span>
                        <Share/>
                    </div>
                </div>
                <div className={st.divider}></div>
                <div className={st.buttonsBlock}>
                    <img className={st.userPhoto} src={require("../../resources/image/lise.jpg")} alt={"Avatar"}/>
                    <div className={st.saveButton}>
                        <Burger/>
                    </div>
                </div>

            </div>
            <div className={cn(st.versionBarBlock, {[st.versionBarBlockIsOpen]: historyBarIsOpen})}>
                {[...Array(MAX_HISTORY).keys()].map((index, id) => (
                    <div key={id} className={st.versionBar}>
                        <div
                            className={cn(st.versionBarPosition, {[st.versionBarPositionCurrent]: id === historyIndex})}
                            onClick={() => checkAndUpdateHistory(id)}>
                            <div className={cn(st.versionBarPositionIndicator, {
                                [st.versionBarPositionIndicatorGreen]: id <= historyIndex,
                                [st.versionBarPositionIndicatorBlue]: id > historyIndex && history.length > id
                            })}></div>
                        </div>
                        <div className={st.versionBarPositionLine}></div>
                    </div>

                ))}
            </div>
            <ExportModal tables={tables} relations={relations} projectId={projectId} generateModalIsOpened={generateModalIsOpened} setGenerateModalIsOpened={setGenerateModalIsOpened} fieldErrorById={fieldErrorById}/>
            <ImportModal updateAll={updateAll} resizeCanvas={resizeCanvas} importModalIsOpened={importModalIsOpened} setImportModalIsOpened={setImportModalIsOpened}/>
            <SettingsModal settingsModalIsOpened={settingsModalIsOpened} setSettingsModalIsOpened={setSettingsModalIsOpened}/>
        </>
    )
}

export default HeaderRight;
