import React from 'react';

const Download = ({color, width = 22, height = 22}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.9514 12.7508V16.7508C18.9514 17.2812 18.7407 17.7899 18.3656 18.165C17.9906 18.5401 17.4818 18.7508 16.9514 18.7508H2.95142C2.42098 18.7508 1.91228 18.5401 1.5372 18.165C1.16213 17.7899 0.951416 17.2812 0.951416 16.7508V12.7508"
                stroke={color ? color : 'var(--icon-blue-color)'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M4.95142 7.75079L9.95142 12.7508L14.9514 7.75079" stroke={color ? color : 'var(--icon-blue-color)'} strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9.95142 12.7508V0.750793" stroke={color ? color : 'var(--icon-blue-color)'} strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>

    );
};

export default Download;