import st from "./VersionText.module.css";
import {version} from "../../service/consts";

const VersionText = () => {
    return (
        <div className={st.versionTextBlock}>
            <span>v{version}</span>
        </div>
    )
}

export default VersionText;
